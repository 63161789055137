@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  .mapWrapper {
    width: 100%;
    text-align: center;
  }
  .header {
    display: flex;
    margin-bottom: 30px;
    .buttonWrapper {
      margin-right: 8px;
      text-align: left;
      .back {
        background: linear-gradient(94.12deg, #7c44cd 46.64%, #fe00dc 154.68%);
        border: 1px solid $white-0;
        border-radius: 8px;
        padding: 5px 8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  .content {
    margin-bottom: 20px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    .proceedButton {
      margin-top: 20px;
      background: linear-gradient(115deg, #8053ea 1.53%, #3835a0 138.07%);
      span {
        color: $white-0;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 20px;
      }
    }
  }
}
