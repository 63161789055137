@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.headerWrapper {
  display: grid;
  grid-template-columns: 4fr 2fr;
  .mainSection {
    .dropdownWrapper {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      border-bottom: 1px solid $gray-9;
      margin-bottom: 17px;
      .lampFoundText {
        margin-bottom: 0;
        color: $white-0;
      }
      .lampFound {
        color: $white-0;
        display: flex;
        gap: 15px;
        justify-content: space-between;
        align-items: center;

        .lamps {
          display: flex;
        }
      }
      .content {
        color: $white-0;
        text-align: left;
        .statesList {
          span {
            font-family: "Inter";
            font-style: italic;
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
      // .dropdown {
      //   .dropdownContent {
      //     width: fit-content;
      //   }
      // }
    }

    .headerContent {
      display: flex;
      justify-content: space-between;
      .seasons {
        color: $white-0;
        display: flex;
        gap: 22px;
        justify-content: space-between;
        align-items: center;

        .season {
          display: flex;
          gap: 8px;
          .active {
            background-color: $purple-1 !important;
          }
          .found {
            background-color: $gray-12 !important;
          }
          .found.active {
            background-color: $gray-11 !important;
          }
          .badge {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(128, 83, 234, 0.4);
            border-radius: 8px;
            width: 52px;
            height: 43px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            /* identical to box height */

            text-align: center;
          }
        }
      }
    }
  }

  .imageWrapper {
    img {
      max-width: 333px;
    }
  }
}
