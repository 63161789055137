@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.navWrapper {
  height: 100px;
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 0 16px;

  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    max-width: 88vw;
    margin: 0 auto;
    .btnWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      @include responsive("smDown") {
        display: none;
      }
      .divider {
        height: 40px;
        border-right: 1px solid $white-0;
      }
      .homeButton {
        span {
          font-family: Inter;
          color: $white-0 !important;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
        }
      }
      .signUpButton {
        span {
          font-family: Inter;
          color: $white-0 !important;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}
