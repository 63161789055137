@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.section {
  position: relative;
  margin-bottom: 60px;
  margin-left: 30px;
  margin-right: 30px;
  .newsHeader {
    text-align: left;
    margin-left: 20px;
  }
  .leftIcon {
    position: absolute;
    top: calc(50%);
    left: -30px;
    z-index: 10;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    &.disabled {
      img {
        opacity: 0.2;
      }
      pointer-events: none;
    }
  }
  .RightIcon {
    position: absolute;
    cursor: pointer;
    top: calc(50%);
    right: -30px;
    z-index: 10;
    padding-left: 10px;
    padding-right: 10px;
    &.disabled {
      img {
        opacity: 0.2;
      }
      pointer-events: none;
    }
  }
  .slider {
    .mediaBlockWrapper {
      border: 0;
      outline: none !important;
      padding: 20px;
      .mediaBlock {
        border-radius: 8px;
        background: $black-10;
        .media {
          max-height: 350px;
          img {
            height: 100%;
            border-color: $gray-15;
            width: 100%;
          }
          video {
            border-color: $gray-15;
          }
          .video {
            max-height: 350px !important;
          }
          :global {
            .image-placeholder {
              height: 100%;
            }
          }
        }
        .infoWrapper {
          color: $white-0;
          > div:nth-child(1) {
            flex-wrap: wrap;
          }
          > div:nth-child(2) {
            min-height: 61px;
          }
        }
      }
    }
  }
}
