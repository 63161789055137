@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.itemWrapper {
  padding: 5px 0;
  position: relative;
  &:first-child {
    &::before {
      display: none;
    }
  }
  &.allLampFound {
    &.complete {
      circle {
        fill: $gray-10;
        r: 14px;
      }
      path {
        fill: $gray-10;
        stroke: $white-0;
      }
    }
    circle {
      stroke: $gray-10;
      fill: $blue-4;
      r: 14px;
    }
    ellipse {
      stroke: $gray-10;
      fill: $blue-4;
      r: 14px;
    }
    rect {
      stroke: $gray-10;
      fill: $blue-4;
      r: 14px;
    }
    path {
      stroke: $gray-10;
      fill: $blue-4;
      r: 14px;
    }
  }

  &::before {
    content: " ";
    border-right: 2px solid $white-0;
    width: 0;
    height: 100%;
    position: absolute;
    top: -20px;
    left: 14px;
  }
  // &.invalid {
  //   &::before {
  //     content: " ";
  //     border-right: 3px solid #fff;
  //     width: 0;
  //     border-right-style: dotted;
  //     height: 100%;
  //     position: absolute;
  //     top: -20px;
  //     left: 14px;
  //   }
  // }
  &.pending {
    &::before {
      content: " ";
      border-right: 3px solid $white-0;
      width: 0;
      border-right-style: dotted;
      height: 100%;
      position: absolute;
      top: -20px;
      left: 14px;
    }
  }
  .itemFlex {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    .itemProcess {
      margin-right: 11px;
      .itemLine {
        position: relative;
        z-index: 2;
      }
    }
    .itemInfo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background: $white-11;
      border: 1px solid $purple-3;
      border-radius: 8px;
      cursor: pointer;
      padding: 4px 8px;

      &.complete {
        padding: 4px 8px;
      }
      &.skip {
        padding: 4px 8px;
      }
      &.active.allLampFound {
        border: 3px solid $gray-10 !important;
      }
      &.active {
        background-color: $blue-5;
        border: 3px solid $purple-3;
        padding: 4px 8px;
        .itemIcon {
          circle {
            stroke: $white-0;
          }
          ellipse {
            stroke: $white-0;
          }
          rect {
            stroke: $white-0;
          }
          path {
            stroke: $white-0;
          }
        }
      }
      &.upcoming {
        padding: 4px 8px;
      }
      &.pending {
        pointer-events: none;
        cursor: not-allowed !important;
        padding: 4px 8px;
        .itemIcon {
          path {
            stroke: $gray-10;
          }
          circle {
            stroke: $gray-10;
          }
        }
      }
      .itemInfoText {
        display: flex;
        p {
          color: $blue-4;
          &.complete {
            font-weight: 500;
          }
          &.skip {
            font-weight: 500;
          }
          &.active {
            font-weight: 700;
            color: $white-0;
          }
          &.upcoming {
            font-weight: 500;
          }
          // &.invalid {
          //   font-weight: 400;
          //   color: $gray-10;
          // }
        }
        .newBadge {
          width: 43px;
          height: 18px;
          border-radius: 10px;
          background: $purple-1;
          color: $white-0;
          font-family: "Inter";
          font-size: 12px;
          font-style: italic;
          font-weight: 500;
          margin-left: 4px;
        }
      }
      .itemIcon {
        padding: 0;
        &.allLampFoundIcon {
          circle {
            stroke: $gray-10;
            fill: transparent;
          }
          ellipse {
            stroke: $gray-10;
            fill: transparent;
          }
          rect {
            stroke: $gray-10;
            fill: transparent;
          }
          path {
            stroke: $gray-10;
            fill: transparent;
          }
        }
      }
    }
    &::before {
      content: " ";
      border-top: 2px solid $white-0;
      width: 12px;
      position: absolute;
      top: 16px;
      left: 30px;
    }
  }
}
