@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  &.modalQuestionWrapper {
    text-align: center;
    padding: 20px 0px;
    .urlWrapper {
      background: #fff;
      padding: 21px 37px;
    }
  }
  .urlWrapper {
    text-align: left;
    border-radius: 8px;

    a {
      color: $purple-10;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
}
