@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.lampImage {
  width: 105px;
  height: auto;
  &:focus {
    outline: none;
  }
}
