@import "src/assets/scss/colors";

.details {
  padding: 24px 26px;
  display: flex;
  gap: 16px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid rgba(28, 28, 28, 0.60);
  background: #323771;
  > img {
    max-width: 130px;
    max-height: 71px;
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span {
      font-size: 14px;
      font-weight: 400;
      color: #BDBDBD;
      &:nth-child(1) {
        font-weight: 600;
        color: $white-0;
      }
    }
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
  :global {
    .custom-checkbox {
      > input {
        position: relative;
        top: 4px;
      }
    }
  }
}