@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.layoutWrapper {
  display: flex;
  min-height: 100vh;
  .wrapper {
    width: 100%;
    height: 100%;
    .watchContainer {
      display: flex;
      .container {
        width: calc(100% - 205px);
        padding: 16px 24px;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        &.collapsed {
          width: calc(100% - 75px);
        }
      }
    }
  }
}
