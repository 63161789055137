@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  &.modalQuestionWrapper {
    text-align: center;
    padding: 30px 16px;
    background-color: $white-0;
    border-radius: 8px;
  }
  .extraText {
    text-align: left;
    font-size: 24px;
    color: $blue-4;
  }
}
