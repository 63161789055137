@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modalBody {
  :global {
    .modal-body {
      padding-bottom: 8px !important;
      > p {
        margin-bottom: 24px;
      }
      > div {
        display: flex;
        align-items: center;
        gap: 22px;
        input {
          height: 40px;
          background: transparent;
        }
        button {
          height: 30px;
          min-width: 92px;
          max-width: 92px;
          padding-top: 5px;
        }
      }
    }
  }
}
.modalWrapper {
  &.isFree {
    :global {
      .modal-content {
        background: $black-10;
        .modal-body {
          > div {
            > button {
              background: rgba(224, 224, 224, 0.4) !important;
            }
          }
        }
      }
    }
  }
}