$tic_toe_width: 382px;
$tic_toe_height: 382px;
$tic_toe_row_height: 110px;
$tic_toe_row_mid_height: 130px;
$tic_toe_row_line_height: 90px;
$tic_toe_row_width: 116px;
$tic_toe_row_mid_width: 120px;

.wrapper {
  background: radial-gradient(136.46% 60.01% at 48.36% 46.63%, #8D7EEB 34.38%, #6034C9 100%);
  position: relative;
}
.gameWrapper {
  border-radius: 8px;
  background: #8b89e7;
  width: $tic_toe_width;
  height: $tic_toe_height;
  padding: 15px;
  margin: 19px auto;
  position: relative;
  :global {
    .row {
      height: $tic_toe_row_height;
      width: 100%;
      margin: 0;
    }
    
    .row-02 {
      height: $tic_toe_row_mid_height;
      border-top: 10px solid #8053ea;
      border-bottom: 10px solid #8053ea;
      .square {
        height: $tic_toe_row_height;
      }
    }
    
    .square {
      width: $tic_toe_row_width;
      height: $tic_toe_row_height;
      float: left;
      line-height: $tic_toe_row_line_height;
      text-align: center;
      font-size: 100px;
      font-weight: bold;
      cursor: pointer;
    }
    
    .square-01,
    .square-04,
    .square-07 {
      width: $tic_toe_row_mid_width;
      border-left: 10px solid #8053ea;
      border-right: 10px solid #8053ea;
    }
  }
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }
  
  h2 {
    text-align: center;
    margin-top: 10px;
  }
}
.wrapper {
  :global {
    .success_popup {
      top: 0;
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      color: white;
    
      background: radial-gradient(
        50% 50% at 50% 50%,
        #8053ea 0%,
        rgba(128, 83, 234, 0) 100%
      );
    }
    
    .success_popup h2 {
      color: white;
      display: block;
      font-family: "Kavoon";
      font-size: 64px;
      margin-bottom: 21px;
    }
    
    #bt_start_game {
      border-radius: 8px;
      color: white;
      background: #77c54d;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      padding: 10px 18px;
      outline: none;
      box-shadow: none;
      border: none;
      cursor: pointer;
    }
    
    .hide {
      display: none;
    }
    
    #fail_game {
      font-size: 24px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #121429 0%,
        rgba(128, 83, 234, 0) 100%
      );
    }
    
    #bt_restart_game {
      border-radius: 8px;
      background: #8053ea;
      padding: 10px 18px;
      color: white;
      margin-top: 21px;
      outline: none;
      box-shadow: none;
      border: none;
      cursor: pointer;
      font-size: 24px;
      font-weight: 600;
    }
    
  }
}