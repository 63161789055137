@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  .titleImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 50px;
    @include responsive("smDown") {
      margin-bottom: 24px;
    }
  }
  .logoWrapper {
    margin-bottom: 40px;
    @include responsive("smDown") {
      padding: 0px 20px;
    }
    .icon {
      height: 200px;
      width: 372px;
      margin-right: 40px;
      @include responsive("smDown") {
        height: auto;
        margin-right: 0px;
        max-width: 100%;
      }
    }
    .profileIcon {
      height: 60px;
      max-width: 614px;
      width: 100%;
    }
  }
  .back {
    @include responsive("smDown") {
      // display: none;
      cursor: pointer;
      position: absolute;
      left: 24px;
      color: $white-0;
      top: 5px;
      z-index: 10;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .progressWrapper {
    position: relative;
    .back {
      cursor: pointer;
      position: absolute;
      left: 15%;
      color: $white-0;
    }
    .progress {
      margin: 0px 0px 45px 0px;
      display: flex;
      gap: 10px;
      justify-content: center;
      @include responsive("smDown") {
        margin: 16px 0px 16px 0px;
      }
      .progressBar {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: rgba(128, 83, 234, 0.4);
        @include responsive("smDown") {
          width: 12px;
          height: 12px;
        }
      }
      .activeStep {
        width: 63px;
        height: 18px;
        @include responsive("smDown") {
          height: 12px;
        }
        border-radius: 48px;
        background-blend-mode: color, overlay, normal;
        background: linear-gradient(0deg, #8053ea 0%, #8053ea 100%),
          linear-gradient(339deg, rgba(255, 255, 255, 0) 52.79%, #fff 95.95%),
          linear-gradient(
            77deg,
            rgba(87, 98, 101, 0.5) 11.6%,
            rgba(158, 161, 161, 0.5) 21.66%,
            rgba(132, 139, 138, 0.5) 48.06%,
            rgba(87, 98, 101, 0.5) 74.22%,
            rgba(87, 98, 101, 0.5) 77.23%,
            rgba(117, 122, 123, 0.5) 88.05%,
            rgba(87, 98, 101, 0.5) 91.31%
          );
      }
    }
  }

  .headerDetails {
    color: $white-0;
    margin-bottom: 43px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    @include responsive("smDown") {
      margin-bottom: 24px;
      padding: 0px 20px;
    }
    .content {
      max-width: 377px;
      .accessTitle {
        color: $white-0;
        text-align: center;
        font-family: "Inter";
        font-size: 40px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0px;
        @include responsive("smDown") {
          color: $white-0;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-left: 16px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .profileTitle {
        font-family: "Inter";
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;

        @include responsive("smDown") {
          margin-top: 32px;
          color: $white-0;
          text-align: center;
          font-family: Milonga;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
      .description {
        margin-top: 24px;
        @include responsive("smDown") {
          font-size: 18px;
          line-height: normal;
        }
        > span {
          font-weight: 700;
        }
      }
      .additionalDetails {
        color: $white-0;
        text-align: center;
        font-family: "Inter";
        margin-top: 34px;
        font-size: 18px;
        font-weight: 400;

        &.detailsStep3 {
          font-size: 24px;
          margin-top: 50px;
          @include responsive("smDown") {
            font-size: 18px;
            line-height: normal;
            margin-top: 25px;
          }
        }
      }
      &.detailsStep1 {
        max-width: 549px;
        // @include responsive("smDown") {
        //   .accessTitle,
        //   .termsTitle {
        //     padding: 0 16px;
        //   }
        // }
      }
      &.detailsStep3 {
        max-width: 613px;
        // @include responsive("smDown") {
        //   .accessTitle,
        //   .subTitle {
        //     max-width: 720px;
        //   }
        // }
      }
      &.detailsStep4 {
        max-width: 715px;
        // @include responsive("smDown") {
        //   .accessTitle,
        //   .subTitle {
        //     max-width: 448px;
        //     padding-left: 16px;
        //     padding-right: 16px;
        //   }
        // }
      }
      // &.detailsStep6 {
      //   @include responsive("smDown") {
      //     .accessTitle,
      //     .subTitle {
      //       max-width: 634px;
      //       padding-left: 16px;
      //       padding-right: 16px;
      //     }
      //   }
      // }
    }
  }
  &.isNotDefaultStep {
    .titleImage {
      margin-top: -15px;
    }
  }
}
.topHeader {
}
