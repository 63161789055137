.wrapper {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  :global {
    p {
      margin: 0;
    }
    
    .game-container {
      margin: auto;
      position: relative;
    }
    
    #play-box {
      background: linear-gradient(110deg, #6c69f8 -13.71%, #363495 150.49%);
      padding: 25px 24px;
    
      display: grid;
      gap: 8.7px;
      perspective: 1000px;
      overflow: hidden;
    }
    
    .card {
      width: 67px;
      height: 67px;
      cursor: pointer;
      position: relative;
      transition: transform 0.6s;
      transform-style: preserve-3d;
    }
    
    .card-animation {
      transform: rotateY(180deg);
    }
    
    .card > .card-front,
    .card > .card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      pointer-events: none;
    }
    
    .card-back {
      transform: rotateY(180deg);
    }
    
    .matched {
      cursor: default !important;
      pointer-events: none;
    }
    
    .hide {
      display: none !important;
    }
    
    .center-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    
    .center-wrapper > div {
      margin: auto;
    }
    
    #win,
    .center-wrapper {
      background: radial-gradient(
        50% 50% at 50% 50%,
        #ce0d98 0%,
        rgba(254, 0, 220, 0.5) 100%
      );
      text-align: center;
    }
    
    #win .congrats {
      color: #fff;
      font-family: Kavoon;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    
      margin-bottom: 30px;
    }
    
    .center-wrapper .direction {
      color: #fff;
      font-family: Inter;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 14px;
      margin-top: 32px;
    }
    
    #time-up {
      background: radial-gradient(
        100% 75% at 50% 50%,
        #121429 0%,
        rgba(128, 83, 234, 0) 100%
      );
    
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    
    #time-up button {
      border-radius: 8px;
      border: none;
      background: #8053ea;
      display: inline-flex;
      padding: 7px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
    
      margin-top: 32px;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    
      cursor: pointer;
    }
    
    .center-wrapper:not(.time-up-wrapper) p:not(.direction) {
      color: #fff;
      // text-align: left;
      font-family: Kavoon;
      font-size: 64px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    
    .center-wrapper button {
      padding: 7px 18px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    
      border: none;
      border-radius: 8px;
      background: #77c54d;
    
      cursor: pointer;
    }
    #start > div > p {
      margin-bottom: 20px;
    }
    .hide {
      display: none !important;
    }
  }
}