@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  color: $white-0;
}
.content {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.title {
  font-size: 32px;
  font-weight: 600;
}