.content {
  display: flex;
  justify-content: space-between;
  .left, .right {
    min-width: calc(50% - 40px);
    max-width: calc(50% - 40px);
  }
}
.left {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .socials {
    margin-bottom: 8px;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.viewItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  > span {
    &:nth-child(1) {
      font-size: 14px;
      font-weight: 700;
      color: #BDBDBD;
    }
  }
  > p {
    font-size: 16px;
    font-weight: 400;
    margin: 0;
  }
}
.region {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  > span {
    &:nth-child(1) {
      font-size: 16px;
      font-weight: 600;
    }
    &:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
    }
    &:nth-child(3) {
      font-size: 12px;
      font-weight: 400;
      position: relative;
      top: -1px;
    }
  }
}
.tags {
  display: flex;
  gap: 8px;
}