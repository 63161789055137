.modalWrapper {
  width: 750px;
  max-width: 100%;
  :global {
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8px 70px;
      gap: 32px;
      p {
        text-align: center;
      }
      h1, p {
        margin: 0;
      }
      h1 {
        font-size: 40px;
        font-weight: 400;
        font-family: "Milonga";
      }
      p {
        font-size: 24px;
        font-weight: 400;
        line-height: 120%;
        font-family: "Inter";
      }
    }
    .modal-content {
      border-radius: 4px;
    }
  }
}