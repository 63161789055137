@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";


.wrapper {
  display: flex;
  gap: 12px;
  > div {
    &:nth-child(1) {
      flex: 1;
    }
  }
  p {
    text-align: left;
    margin: 0;
  }
  .title {
    text-transform: capitalize;
  }
  .description {
  }
  &.isWhite {
    p, .title {
      color: $white-0;
    }
  }
  &.game_tic_toe, &.game_matching {
    .score {
      padding-left: 10px;
      gap: 5px;
    }
  }
}
.score {
  color: $white-0;
  font-size: 30px;
  font-weight: 600;
  padding: 10px 8px 10px 25px;
  border-radius: 8px;
  border: 1px solid #6C69F8;
  background: linear-gradient(98deg, #6C69F8 -5.24%, #13132D 98.53%);
  display: flex;
  align-items: center;
  gap: 10px;
  > span {
    line-height: 36px;
  }
}
.close {
  display: flex;
  margin-left: 48px;
  align-items: center;
  > span {
    display: inline-flex;
    align-items: center;
    height: 32px;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
    color: $white-0;
    cursor: pointer;
    svg {
      path {
        fill: $white-0;
      }
    }
  }
}