@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  color: $white-0;
  max-width: 60%;
  margin: 16px auto 0px;
  text-align: left;
  height: calc(100vh - 350px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include responsive("smDown") {
    height: calc(100%);
    max-width: 90%;
    p {
      font-size: 16px !important;
    }
  }
  .confirmTitle {
    display: flex;
    justify-content: space-between;
    @include responsive("smDown") {
      gap: 50px;
    }
    .confirmText {
      @include responsive("smDown") {
        display: none;
      }
    }
    .edit {
      display: flex;
      gap: 6px;
      margin-top: 8px;
      cursor: pointer;
      @include responsive("smDown") {
        margin-top: 0px;
        p {
          font-size: 12px !important;
        }
        svg {
          height: 13px;
          width: 13px;
          margin-top: 2px;
        }
      }
    }
  }
  .purchase {
    display: grid;
    grid-template-columns: 130px 49px 2fr;
    // @include responsive("xsDown") {
    //   grid-template-columns: 1fr;
    // }
    @include responsive("smDown") {
      grid-template-columns: 130px 29px 2fr;
    }
  }
  .nameWrapper {
    display: grid;
    grid-template-columns: 130px 49px 2fr;
    margin-bottom: 10px;
    @include responsive("smDown") {
      grid-template-columns: 130px 29px 2fr;
    }
  }
  .bottomMg {
    display: grid;
    grid-template-columns: 130px 49px 2fr;
    margin-bottom: 10px;
    @include responsive("smDown") {
      margin-bottom: 90px;
      grid-template-columns: 130px 29px 2fr;
    }
  }
  .buttonWrapper {
    @include responsive("smDown") {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $black-4;
      box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
      padding: 12px 24px 34px 24px;
      z-index: 10;
      button {
        position: relative;
        span {
          font-size: 18px;
          line-height: normal;
        }
      }
    }
    .button {
      margin-top: 17px;
      width: 100%;
      span {
        font-weight: 400;
        font-size: 32px;
        line-height: 39px;
        @include responsive("smDown") {
          font-size: 27px;
        }
        @include responsive("xsDown") {
          font-size: 22px;
        }
      }
    }
  }
}
