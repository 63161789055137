@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  margin: 0 auto;
  max-width: 720px;
  height: calc(100vh - 390px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include responsive("smDown") {
    padding: 16px;
  }
  .cardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 56px;
    margin-bottom: 32px;
    @include responsive("smDown") {
      grid-template-columns: 1fr;
    }

    .card {
      padding: 12px;
      background: $black-5;
      border-radius: 4px;
      p {
        text-align: left;
      }
      .title {
        margin-bottom: 12px;
      }
      .list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        svg {
          margin-right: 8px;
        }
      }
    }
  }
  .text {
    margin-bottom: 24px;
    @include responsive("smDown") {
      margin-bottom: 112px;
    }
  }
  .buttonWrapper {
    max-width: 540px;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 40px;
    @include responsive("smDown") {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 100%;
      background: $black-4;
      box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
      padding: 12px 24px 34px 24px;
      z-index: 10;
      button {
        position: relative;
        span {
          font-size: 18px !important;
          line-height: normal !important;
        }
      }
      .button {
        width: 100%;
        span {
          font-size: 18px;
          line-height: normal;
        }
      }
    }
    .button {
      width: 100%;
      span {
        font-size: 32px;
        line-height: 39px;
      }
    }
  }
}
