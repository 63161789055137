@import "src/assets/scss/colors";

.avatarContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 16px;
  color: $white-0;
  font-weight: 400;
  > img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
}
