@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.datePopover {
  padding: 8px;
  .datePicker {
  }
  :global {
    .react-datepicker {
      border: none;
    }
    .react-datepicker__header {
      background-color: transparent;
    }

    .react-datepicker__day--selected {
      background-color: $purple-1 !important;
      color: $white-0 !important;
    }
    .react-datepicker__day--in-selecting-range:hover {
      background-color: $purple-8;
      color: $black-0;
    }
    .react-datepicker__day--in-selecting-range {
      background-color: $purple-8;
      color: $black-0;
    }
    .react-datepicker__day--in-range {
      background-color: $purple-1;
      color: $white-0;
    }
    .react-datepicker__day--in-range:hover {
      background-color: $purple-1;
      color: $white-0;
    }

    .react-datepicker__month--selecting-range
      .react-datepicker__day--in-range:not(.react-datepicker_day--in-selecting-range
        .react-datepicker__month-text--in-selecting-range.react-datepicker__quarter-text--in-selecting-range
        .react-datepicker__year-text--in-selecting-range) {
      background-color: $purple-1;
      color: $white-0;
    }

    .selected-range-custom {
      background-color: $purple-8 !important;
      color: $black-0 !important;
      &:hover {
        background-color: $purple-8 !important;
        color: $black-0;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      border: none;
      // background-color: $purple-1;
    }

    .react-datepicker__day--in-selecting-range,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__year-text--in-selecting-range {
      border: none;
      // background-color: $purple-1;
      // color: $white-0;
    }
  }
  .dateActions {
    display: flex;
    align-items: center;
    justify-content: right;
    .clear {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $blue-4;
      text-decoration: none;
    }
    .done {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      padding: 4px 15px;
      background-color: $purple-1;
      border: none;
    }
  }
}

.wrapper {
  width: 289px;
  .cardBody {
    .typeSelection {
      .title {
        margin-bottom: 13px;
        user-select: none;
      }
      .typeInputs {
        .checkbox {
          border: 1px solid $black-9;
          margin-bottom: 7px;
          background-color: transparent;
          &:checked[type="checkbox"] {
            background-color: $purple-1;
            filter: none;
            border: none;
          }
        }
        .inputLabel {
          user-select: none;
          margin-bottom: 0;
          display: flex;
          // align-items: center;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: $black-0;
          input {
            margin-right: 10px;
          }
        }
      }
    }
    .divider {
      border-bottom: 1px solid $gray-19;
      margin-top: 4px;
      margin-bottom: 13px;
    }
    .dateSelection {
      margin-bottom: 12px;
      .dateRangeToggle {
        cursor: pointer;
        margin-bottom: 8px;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          color: $gray-11;
        }
        &.open {
          svg {
            transform: rotate(180deg);
          }
        }
        .dateRangeText {
          display: flex;
          align-items: center;
          gap: 4px;
          .dates {
            color: $green-0;
          }
        }
      }
      .dateContent {
        .datePopoverButton {
          margin-bottom: 7px;
          cursor: pointer;
          user-select: none;
          display: flex;
          align-items: center;
          gap: 6px;
        }
        .dateSelectButton {
          margin-bottom: 7px;
          cursor: pointer;
          user-select: none;
        }
        .dateActiveSelectButton {
          background: $blue-6;
          border-radius: 1px;
          padding: 2px 4px;
          color: $white-0;
          margin-bottom: 7px;
          cursor: pointer;
          user-select: none;
          svg {
            path {
              stroke: $white-0;
            }
            rect {
              stroke: $white-0;
            }
          }
        }
      }
    }
    .filterActions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 8px;
      .apply {
        width: 100%;
        padding: 2px 0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: $purple-1;
        border: 1px solid $purple-1;
        &:hover {
          background: transparent;
        }
      }
      .clear {
        padding: 0;
        font-family: "Inter";
        font-style: normal;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $blue-4;
      }
    }
  }
}
