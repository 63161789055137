@import "src/assets/scss/colors";

.typography {
  font-size: var(--size);
  color: var(--color);
  margin: 0;
}

//family

.inter {
  font-family: Inter;
}

.milonga {
  font-family: Milonga;
}

.timesNewRoman {
  font-family: "Times New Roman";
}

.swiss {
  font-family: Swiss;
}

.impact {
  font-family: Impact;
}

.raleway {
  font-family: Raleway;
}

.merriweather {
  font-family: Merriweather-Black;
}

.arial {
  font-family: Arial;
}

.lobster {
  font-family: Lobster-Regular;
}

.arvo {
  font-family: Arvo-Regular;
}

//weights

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.black {
  font-weight: 900;
}
