@import "src/assets/scss/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 481px;
  margin: auto;
  flex: 1;
  justify-content: center;
  gap: 32px;
  > p {
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 8px;
    > span {
      font-size: 16px;
      font-weight: 400;
      &:nth-child(1) {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
.uploadBtn {
  width: 150px;
  margin: 0 auto;
  border-radius: 12px;
  backdrop-filter: blur(7.5px);
  padding: 6px 12px;
  font-size: 24px;
  font-weight: 700;
  > span {
    &:nth-child(1) {
      width: 36px;
      height: 36px;
      margin-right: 6px;
      position: relative;
      top: -2px;
      display: inline-block;
      > svg {
        width: 36px;
        height: 36px;
        path, rect {
          stroke: $white-0;
        }
      }
    }
    &:nth-child(2) {
      line-height: 36px;
    }
  }
}