@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  height: 70px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include responsive("smDown") {
    // height: 100px;
    padding: 0 16px;
  }
  .tgsImage {
    height: 26px;
  }
  .btnWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    @include responsive("smDown") {
      display: none;
    }

    .divider {
      height: 40px;
      border-right: 1px solid $white-0;
    }
    .homeButton {
      span {
        font-family: Inter;
        color: $white-0 !important;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
      }
    }
    .signUpButton {
      span {
        font-family: Inter;
        color: $white-0 !important;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
}
