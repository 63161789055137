@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.content {
  position: relative;
  padding: 158px 90px 120px 90px;
  max-width: 1440px;
  margin: 0 auto;
  @include responsive("mdDown") {
    padding: 61px 33px 40px;
  }
  .MobileRightIcon {
    display: none;
    @include responsive("xsDown") {
      display: block;
      position: absolute;
      z-index: 10;
      top: 41%;
      right: 10px;
    }
  }
  .cardWapper {
    display: flex;
    flex-direction: row;
    gap: 46px;
    flex-wrap: wrap;
    justify-content: center;
    @include responsive("smDown") {
      display: none;
    }
    .cards {
      background: rgba(20, 20, 20, 0.4);
      border: 1px solid $yellow-2;
      border-radius: 12px;
      height: 236px;
      width: 324px;
      .cardImage {
        margin: 21px 20px 4px;
      }
      .title {
        font-family: "Impact";
        color: $white-0;
        text-align: center;
      }
    }
  }
  .RightIcon {
    position: absolute;
    right: 13px;
    z-index: 10;
    padding-top: 110px;
}

  .sliderWrapper {
    display: none;
    align-items: center;
    margin-bottom: 24px;
    @include responsive("smDown") {
      display: block;
    }
    :global {
      .slick-initialized {
        button {
        }
      }
      .slick-arrow {
        display: none !important;
      }
      .slick-track {
        display: flex;
      }
      .slick-list {
        overflow: hidden;
      }
      .slick-slide {
        margin: 0 10px;
        width: 445px !important;
      }
      .slick-dots {
        display: flex !important;
        justify-content: center;

        margin: 0;
        padding: 30px 0 16px;

        list-style-type: none;

        li {
          margin: 0 0.25rem;
        }

        button {
          display: block;
          width: 8px;
          height: 8px;
          padding: 0;

          border: none;
          border-radius: 100%;
          background-color: $yellow-2;
          opacity: 0.2;

          text-indent: -9999px;
        }

        li.slick-active button {
          background-color: $yellow-2;
          opacity: 1;
        }
      }
    }

    .cards {
      background: rgba(20, 20, 20, 0.4);
      border: 1px solid $yellow-2;
      border-radius: 12px;
      height: 236px;
      width: 324px;
      .cardImage {
        margin: 8px;
        height: 168px;
        border-radius: 12px;
        @include responsive("smDown") {
          margin: 8px 8px 16px;
        }
      }
      .title {
        color: $white-0;
        text-align: center;
        font-family: 'Raleway';
      }
    }
  }
}
