@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modalWrapper {
  max-width: 602px;
  :global {
    .modal-content {
      background: $black-4;
      color: $white-0;
    }
  }
}
.modalBody {
  padding: 44px;
}
.content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  img {
    width: 472px;
  }
  h1 {
    margin: 0;
    font-family: "Milonga";
    font-size: 40px;
  }
  p {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
    font-family: "Inter";
  }
  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-family: "Inter";
    > div {
      font-size: 20px;
      font-weight: 500;
      > span {
        display: inline-block;
        position: relative;
        cursor: pointer;
        &:before {
          content: " ";
          position: absolute;
          width: 100%;
          left: 0;
          bottom: 2px;
          height: 1px;
          border-top: 1px solid $white-0;
        }
      }
    }
  }
}