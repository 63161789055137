@import "src/assets/scss/colors";

.modalWrapper {
  width: 683px;
  max-width: 683px;
  :global {
    .modal-content {
      border-radius: 8px;
      border: 1px solid $blue-7;
      background: $blue-4;
      color: $white-0;
      font-size: 16px;
      font-weight: 400;
    }
  }
  h2 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 16px;
  }
}
.content {
  margin-bottom: 40px;
  > div {
    color: $gray-16;
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
  font-weight: 500;
  gap: 32px;
  align-items: center;
  button {
    height: 45px;
    > span {
      font-size: 24px;
      font-weight: 500;
    }
  }
}