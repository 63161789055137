@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  position: relative;
  background: $black-5;
  box-shadow: none;
  padding: 12px;
  cursor: pointer;
  &.activeCard {
    border: 1px solid var(--gradient-gold, #c1a875);
  }
  &.error {
    border: 1px solid $orange-0;
  }

  .header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .title {
      margin-bottom: 12px;
      // line-height: 39px;
      color: $yellow-2;
      span {
        font-size: 12px;
      }
    }
    .select {
      position: absolute;
      right: 7px;
      top: 7px;
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid rgba(255, 255, 255, 0.27);
      &.activeBorder {
        border: 1px solid $yellow-8;
      }
      .active {
        border: 1px solid $black-5;
        background: $yellow-8;
        width: 9px;
        border-radius: 50%;
        height: 9px;
      }
    }
  }

  .details {
    text-align: left;
    margin-bottom: 0px;
    color: $white-0;
    span {
      font-weight: 700;
    }
  }
  .bodyStyles {
    background: $black-5;
    display: flex;
    padding: 0;

    border-radius: 6px;

    .button {
      width: fit-content;
    }
  }
}
