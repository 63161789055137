.inputs {
  .header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .text {
      text-align: left;
    }
    .numberOfInputs {
      display: flex;
      align-items: center;
      gap: 11px;
    }
  }
}
