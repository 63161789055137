@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

/* Variables for easy customization */
$overlay-background-color: rgba(0, 0, 0, 0.5);
$drawer-width: 300px;
$drawer-height: 300px;
$transition-duration: 0.3s;
$transition-duration-slow: 0.5s;
$transition-duration-fast: 0.1s;

$transition-timing-function: ease-in-out;

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $overlay-background-color;
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-duration $transition-timing-function,
    visibility $transition-duration $transition-timing-function;

  &.open {
    opacity: 1;
    visibility: visible;
  }
  &.noBg {
    background-color: transparent;
  }
  &.slow {
    transition-duration: $transition-duration-slow;
    visibility: $transition-duration-slow;
  }
  &.fast {
    transition-duration: $transition-duration-fast;
    visibility: $transition-duration-fast;
  }
}

.drawer {
  position: fixed;
  background: $black-4;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: transform $transition-duration $transition-timing-function;
  z-index: 999;
  &.open {
    &.left {
      transform: translateX($drawer-width);
    }
    &.right {
      transform: translateX(-$drawer-width);
    }
    &.top {
      transform: translateY($drawer-height);
    }
    &.bottom {
      transform: translateY(-$drawer-height);
    }
  }
  &.slow {
    transition-duration: $transition-duration-slow;
  }
  &.fast {
    transition-duration: $transition-duration-fast;
  }
}

.left {
  top: 0;
  left: -$drawer-width;
  width: $drawer-width;
  height: 100%;

  &.open {
    transform: translateX($drawer-width);
  }
}

.right {
  top: 0;
  right: -$drawer-width;
  width: $drawer-width;
  height: 100%;

  &.open {
    transform: translateX(-$drawer-width);
  }
}

.top {
  top: -$drawer-height;
  left: 0;
  width: 100%;
  height: $drawer-height;

  &.open {
    transform: translateY($drawer-height);
  }
}

.bottom {
  bottom: -$drawer-height;
  left: 0;
  width: 100%;
  height: $drawer-height;

  &.open {
    transform: translateY(-$drawer-height);
  }
}
