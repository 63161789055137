@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  display: flex;

  .leftContainer {
    border-radius: 20px 0 0 20px;
    height: calc(100% - 23px);
    max-width: 544px;
    background-color: $blue-4;
    color: $white-0;
    font-family: "Inter";

    .header {
      max-width: 431px;
      max-height: 227px;
      margin: 0 auto;
      margin-top: 33px !important;
      @include responsive("smDown") {
        padding: 0 24px;
      }

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .upperContainer {
      margin: 43px 0px 81px 0px;
      padding: 0 23px;
      @include responsive("smDown") {
        margin: 24px 0 40px 0;
        padding: 0 16px;
      }

      .title {
        font-size: 22px;
        font-weight: 700;
        line-height: normal;
      }

      .body {
        margin: 51px 0px 56px 0px;
        font-size: 18px;
        line-height: normal;
        @include responsive("smDown") {
          margin: 24px 0;
        }
      }

      .details {
        color: #fff;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .lowerContainer {
      padding: 0px 48px;
      @include responsive("smDown") {
        padding: 0 8px;
      }

      .title {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 11px;
        text-align: left;
        @include responsive("mdDown") {
          width: 100%;
          max-width: 467px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .optionContainer {
        @include responsive("smDown") {
          > label {
            width: 100%;
          }
        }
        .optionWrapper {
          cursor: pointer;
          width: 467px;
          height: 125px;
          position: relative;
          border-radius: 8px;
          margin-bottom: 23px;
          border: 4px solid $white-13;
          background: $white-0;
          margin-left: auto;
          margin-right: auto;
          @include responsive("smDown") {
            width: 100%;
            max-width: 467px;
          }

          &.selected {
            border: 3px solid $white-0 !important;
            background: $blue-4 !important;
            .leftWrapper {
              input[type="radio"] {
                position: relative;
                &:before {
                  content: " ";
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  background: white;
                  border-radius: 50%;
                }
                &:after {
                  content: " ";
                  position: absolute;
                  left: 1px;
                  right: 1px;
                  top: 1px;
                  bottom: 1px;
                  border-radius: 50%;
                  border: 3px solid #121429;
                }
              }
            }
          }

          .leftWrapper {
            position: absolute;
            left: 5px;
            top: 5px;
            height: 100%;

            input[type="radio"] {
              cursor: pointer;
              width: 22px;
              height: 22px;
              // border: 2px solid $blue-4;
              // accent-color: $white-0;
            }
          }

          .rightWrapper {
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .price {
              color: $blue-4;
              font-size: 32px;
              font-weight: 600;
              line-height: normal;
              margin-bottom: 4px;

              &.selected {
                color: $white-0 !important;
              }
            }

            .details {
              max-width: 238px;
              color: $blue-4;
              font-size: 14px;
              line-height: normal;

              &.selected {
                color: $white-0 !important;
              }
            }

            .saleContainer {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              position: absolute;
              bottom: 5px;
              right: 5px;
              width: 60px;
              height: 60px;
              transform: rotate(-23.924deg);
              border-radius: 50%;
              background: linear-gradient(
                94deg,
                rgba(124, 68, 205, 0.67) 46.64%,
                #fe00dc 154.68%
              );
            }
          }
        }
      }
    }
    .footerText {
      margin: 44px 0px;
      color: $white-0;
      font-family: "Inter";
      font-size: 18px;
      > span {
        margin-left: 5px;
        cursor: pointer;
        text-decoration-line: underline;
      }
      @include responsive("smDown") {
        margin: 24px 0;
      }
    }
  }

  .rightContainer {
    width: 544px;
    border-radius: 0 20px 20px 0;
    padding: 20px;

    background-color: $white-0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .stripeForm {
      border: 3px solid $blue-4;
      border-radius: 20px;
      padding: 15px 30px;

      .checkoutWrapper {
        padding: 12px 14px;

        .checkout {
          display: flex;
          padding: 15px;
          flex-direction: column;
          align-items: flex-start;
          gap: 7px;
          border-radius: 6px;
          border: 0.383px solid #b2bcca;

          .summary {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }

          //.divider {
          //  border: 0.3px solid $gray-16;
          //  width: 100%;
          //}
        }
      }

      .payBtn {
        width: calc(100% - 30px);
        box-shadow: 6.2px 6.2px 18.6px 0px rgba(2, 2, 70, 0.15);
        border-radius: 6.2px;
        border: 0.775px solid $blue-4;
        background: $blue-4;
        padding: 9px 15px;
        color: $white-0;
        font-family: Inter;
        font-size: 12.4px;
        font-weight: 500;
        margin: 25px 15px;
        line-height: 18.6px; /* 150% */
      }

      :global {
        .StripeElement {
          border: none;
        }
      }
    }
  }
  @include responsive("mdDown") {
    flex-direction: column;
    margin: 0 8px;
    > div {
      max-width: 100% !important;
      min-width: 100% !important;
    }
    .leftContainer {
      border-radius: 20px 20px 0 0;
    }
    .rightContainer {
      border-radius: 0 0 20px 20px;
    }
  }
  @include responsive("smDown") {
    margin: 0px;
    .rightContainer {
      padding: 8px 0;
      .stripeForm {
        border: 0;
        padding: 0;
      }
    }
  }
}
.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paymentLoader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  padding-top: 0;
  :global {
    .spinner-border {
      border-color: white;
      border-right: 0.252rem solid #9887C7;
      width: 48px;
      height: 48px;
    }
  }
}
.paymentLoaderText {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 84px;
  z-index: 11;
  > span {
    font-size: 18px;
    color: white;
    font-weight: 500;
  }
}
