@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  color: $white-0;
  padding: 28px 126px;
  position: relative;
  @include responsive("mdDown") {
    padding: 28px 32px;
  }
  @include responsive("smDown") {
    padding: 12px;
  }
  @include responsive("xsDown") {
    padding: 0;
  }
}
.loading {
  position: fixed;
  background: #000000cc;
  align-items: center;
  justify-content: center;
  padding: 0;
}