@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.signInOption {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 230px;
  margin: 0 auto;
  gap: 24px;
  .optionWrapper {
    cursor: pointer;
    display: flex;
    max-width: 60px;
    max-height: 60px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $gray-7;
    background: $purple-12;
    svg {
      width: 36px;
      height: 36px;
    }
  }
}

.divider {
  text-align: center;
  font-weight: 700;
  margin: 50px auto 61px auto;
  padding: 0px 20px;

  .wordWithLine {
    display: inline-block;
    position: relative;
    padding: 0px 14px;
    color: $white-0;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    line-height: normal;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 195px;
      height: 1px;
      background: #7b7b7b;
      top: 50%;
      @include responsive("smDown") {
        width: 100px;
      }
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }
}

.lowerContainer {
  max-width: 444px;
  padding: 0px 20px;
  margin: 0 auto;

  .signupInputs {
    .formGroup {
      text-align: left;
      width: 100%;

      .label {
        color: $white-0;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }

      .inputWrapper {
        outline: none !important;

        > span {
          background-color: $white-0 !important;
        }
      }

      .input {
        background-color: $white-0;
        color: $black-0 !important;
        border-radius: 3px;

        &:focus,
        &:active,
        &:focus-visible {
          box-shadow: none;
          color: $black-0;
          outline: none;
          background-color: $white-0;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: $black-0;
        }

        &::placeholder {
          color: $gray-26 !important;
        }
      }
    }

    .buttonContainer {
      @include responsive("smDown") {
        display: none;
      }
      margin-top: 43px;

      &.error {
        margin-top: 3px !important;
      }
    }

    .mobileNext {
      display: none;
      @include responsive("smDown") {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $blue-4;
        box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
        padding: 12px 24px 34px 24px;
        z-index: 10;
        button {
          position: relative;
        }
      }
    }
  }

  .terms {
    padding: 34px 0px;
    color: $white-0;
    font-family: "Inter";
    font-size: 18px;
    line-height: normal;

    > span {
      cursor: pointer;
      font-weight: 700;

      a {
        text-decoration: none;
        color: $white-0;
      }
    }
  }

  .tAndC {
    margin-top: 34px;
    color: $white-0;
    font-family: "Inter";
    font-size: 24px;
    line-height: normal;

    > span {
      margin-left: 5px;
      cursor: pointer;
      font-weight: 700;
    }
  }
}
