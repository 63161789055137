@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modalWrapper {
  :global {
    font-family: "Inter";
    .modal-content {
      border-radius: 8px;
      background: $blue-4;
    }
  }

  &:global(.modal-dialog) {
    max-width: 450px;
  }
}

.modalBody {
  color: $white-0;
  padding: 26px 38px 38px 38px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 22px;
  align-items: center;
  margin-top: 28px;
}

.header {
  padding-bottom: 19px;
  margin-bottom: 19px;

  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
}

.thankWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  >p {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    text-align: center;
    width: 370px;
  }
}