.wrapper {
  display: flex;
  flex-direction: column;
  background: #F4E9FF;
  padding: 0 16px;
  border-radius: 6px;
  text-align: left;
  > div {
    &:last-child {
      border-bottom: 0;
    }
  }
  &.isInactive {
    background: #D1D1D180;
  }
}
.season {
  border-bottom: 1px solid #625272;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.hasPrize {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  &.hasLamp {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
.name {
  font-size: 12px;
  line-height: 16px;
  color: black;
  &.status_not_live, &.status_active {
    position: relative;
    &:before {
      content: ' ';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #E5C85F;
      position: absolute;
      left: -12px;
      top: 4px;
    }
  }
  &.status_active {
    &:before {
      background: #5AA332;
    }
  }
}
.activateDate {
  font-size: 12px;
  font-weight: 600;
}
.prize {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 5px 8px;
  background: #8053EA80;
  border-radius: 50px;
  > span {
    &:nth-child(1) {
      font-size: 10px;
      color: black;
    }
    &:nth-child(2) {
      font-size: 14px;
      font-weight: 700;
      color: black;
      line-height: 17px;
    }
  }
}
.lamp {
  display: flex;
  align-items: center;
  gap: 4px;
  background: #DBDBDB;
  border-radius: 6px;
  padding: 4px;
  > span {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span {
      &:nth-child(1) {
        font-size: 10px;
        line-height: 12px;
        color: black;
      }
      &:nth-child(2) {
        font-size: 8px;
        font-weight: 600;
        text-transform: uppercase;
        color: #5AA332;
      }
    }
  }
  svg {
    path {
      fill: #FFCA35;
    }
  }
}
.defaultLabel {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: black;
}