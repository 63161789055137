/**
* Color
**/

// White
$white-0: #fff;
$white-1: #f8f9fe;
$white-2: #f7fafc;
$white-3: #d9d9d9;
$white-4: #dee2e6;
$white-5: #f0f3f6;
$white-6: #c6c6c6;
$white-7: #f5f5f5;
$white-8: #f2f2f2;
$white-9: #eaeaea;
$white-10: #f1f1f1;
$white-11: #e8e8e8;
$white-12: #efefef;
$white-13: #eae9f0;

// Black
$black-0: #000;
$black-1: #595959;
$black-2: #333333;
$black-3: #212529;
$black-4: #1c1c1c;
$black-5: #2d2d2d;
$black-6: #06070e;
$black-7: #0f101f;
$black-8: #141414;
$black-9: #0e1219;
$black-10: #393939;
$black-11: #232422;
$black-12: #0f001a;

// red
$red-0: #f00;
$red-1: #ed5656;
$red-2: #ff5050;

// Gray
$gray-0: #dcdcdc;
$gray-1: #8898aa;
$gray-2: #b9bdc0;
$gray-3: #e9ecef;
$gray-4: #dde3e8;
$gray-5: #d1d5d9;
$gray-6: #b5b5b5;
$gray-7: #cbcbcb;
$gray-8: #7a769f;
$gray-9: #b4b4b4;
$gray-10: #a4a4a4;
$gray-11: #6c6d6c;
$gray-12: #b5b6b5;
$gray-13: #676875;
$gray-14: #a8a8a8;
$gray-15: #666;
$gray-16: #e0e0e0;
$gray-17: #868686;
$gray-18: #919191;
$gray-19: #bcbcbc;
$gray-20: #a1a1a1;
$gray-21: #404040;
$gray-22: #848484;
$gray-23: #5b5b5b;
$gray-24: #7b7b7b;
$gray-25: #6c6c6c;
$gray-26: #b7b7b7;
$gray-27: #b2bbca;

// Green
$green-0: #5aa332;

// Blue
$blue-0: #1f76fe;
$blue-1: #32325d;
$blue-2: #525f7f;
$blue-3: #172b4d;
$blue-4: #121429;
$blue-5: #13132d;
$blue-6: #2d2c7c;
$blue-7: #2c3063;
$blue-8: #323771;
$blue-9: #2f80ed;
$blue-10: #29aefa;

// purple
$purple-0: #49348a;
$purple-1: #8053ea;
$purple-2: #c7b4f3;
$purple-3: #6c69f8;
$purple-4: #baa4ef;
$purple-5: #382f64;
$purple-6: #2d1d5d;
$purple-7: #5033b3;
$purple-8: #e5dbfc;
$purple-9: #6966f5;
$purple-10: #6242b4;
$purple-11: #6100c1;
$purple-12: #191239;

//green
$green-1: #05eb38;

//Yellow
$yellow-0: #f5a524;
$yellow-1: #f4f862;
$yellow-2: #deb771;
$yellow-3: #ffca35;
$yellow-4: #e1b232;
$yellow-5: #c19e1f;
$yellow-6: #b79e6b;
$yellow-7: #c1a875;
$yellow-8: #927946;
$yellow-9: #ffe6b4;
$yellow-10: #ffc525;

//pink
$pink-0: #b626d4;
$pink-1: #e089ff;

//orange
$orange-0: #d04e55;

// Input Colors
$input-placeholder-color: #adb5bd;

// Button Colors
$primary-btn-color: #5e72e4;
$secondary-btn-color: #ffffff;
$info-btn-color: #11cdef;
$success-btn-color: #2dce89;
$danger-btn-color: #f5365c;
$warning-btn-color: #fb6340;

//Link
$link: #5e72e4;

//Alert color

$alert-default: #3c4d69;
$alert-warning: #fc7c5f;
$alert-danger: #f75676;
$alert-success: #4fd69c;
$alert-info: #37d5f2;
$alert-primary: #7889e8;
$alert-secondary: #f8fbfc;

//toast color

$toast-default: #3c4d69;
$toast-danger: #f5365c;
$toast-success: #2dce89;
