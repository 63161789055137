@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.cardWrapper1 {
  border-radius: 18px;
}
.cardWrapper {
  user-select: none;
  min-width: 281px;
  min-height: 150px;
  border-radius: 16px;
  padding: 8px !important;
  border: 8px solid #493389;
  > div {
    padding: 32px 0 0 0;
    margin-bottom: 0;
    width: 100%;
  }
  .addIcon {
    svg {
      height: 26px;
      width: 26px;
    }
  }
  &.joined {
    border: 8px solid $green-0;
    background-color: initial;
    .addIcon {
    }
  }
  &.founded {
    // border: 4px solid $gray-11;
    background-color: initial;
    .addIcon {
      svg {
        border-radius: 8px;
        path {
          stroke: $gray-12 !important;
        }
      }
    }
    .statesList {
      span {
        color: $gray-12;
      }
    }
    .actions {
      color: $gray-11;
      span {
        color: $gray-11;
      }
    }
  }
  &.hover.founded {
    // border: 4px solid $gray-11;
    background-color: initial;
    .addIcon {
      // svg {
      //   border-radius: 8px;
      //   path {
      //     fill: $gray-12 !important;
      //   }
      // }
    }
    .statesList {
      span {
        color: $gray-12;
      }
    }
  }
  .header {
    margin-bottom: 21px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .statesList {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
    }
    .addIcon {
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .badgeWrapper {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 8px;
      text-transform: capitalize;
      &.joined {
        span {
          background-color: $green-0 !important;
        }
      }
      &.founded {
        span {
          background-color: $gray-12 !important;
        }
        p {
          color: $gray-12;
        }
      }
      span {
        background: $blue-5;
        border-radius: 43.2328px;
        padding: 5px 17px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .joinRegion {
      width: 100%;
      border-radius: 12px;
      font-family: "Inter";
      font-weight: 600;
      font-size: 16px;
      line-height: normal;
      text-transform: capitalize;
    }
    .viewClues {
      padding-top: 4px;
      padding-bottom: 4px;
      width: 100%;
      background: $white-0 !important;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      font-family: "Inter";
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $blue-5;
      display: flex;
      justify-content: center;
      border: none;
    }
    .previewClues {
      width: 100%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: center;
      display: flex;
      justify-content: center;
      color: $blue-5;
      text-decoration: none;
    }
  }
}
.contentWrapper {
  :global {
    .region-name-badge {
      top: 0;
      right: 0;
    }
  }
}
