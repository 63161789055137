.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  > span {
    cursor: pointer;
  }
}