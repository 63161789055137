@import "src/assets/scss/colors";

.wrapper {
  height: 100vh;
  background: $blue-4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  gap: 8px;
  span {
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
    font-size: 32px;
  }
}