@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  position: relative;

  background-color: $black-0;
  .container {
    padding: 59px 50px 45px;
    max-width: 1440px;
    margin: 0 auto;
    background-size: 1283px 682px;
    background-position: 130px 95px;

    @include responsive("mdDown") {
      background-position: center center;
      background-size: 898px 722px;
    }
    @media only screen and (max-width: 1570px) {
      background-size: 1187px 682px;
      background-position: 130px 95px;
    }
    @media only screen and (max-width: 1400px) {
      background-size: 1104px 708px;
      background-position: 51px 95px;
    }
    @media only screen and (max-width: 1060px) {
      background-size: 967px 708px;
      background-position: -13px 194px;
    }
    @include responsive("smDown") {
      padding: 40px 20px 83px;
      background-position: 23% 86%;
      background-size: 544px 399px;
    }
    .imageWrapper {
      position: absolute;
      inset: 0;
      .keyImage {
        max-width: 85%;
      }
    }

    .title {
      z-index: 10;
      p {
        text-align: left;
        color: $white-0;
      }
      .titleText {
        margin-bottom: 13px;
        span {
          font-size: 50px;
          font-family: "Times New Roman";
          @include responsive("smDown") {
            font-size: 39px;
          }
        }
        @include responsive("smDown") {
          font-weight: 400;
          font-size: 32px;
          line-height: 49px;
          text-align: center;
          margin-bottom: 30px;
        }
      }
      .subtitleText {
        margin-bottom: 18px;
        .break {
          @include responsive("smDown") {
            display: none;
          }
        }
        @include responsive("smDown") {
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          font-family: "Inter";
        }
      }
    }
    .content {
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include responsive("smDown") {
        flex-direction: column;
        gap: 157px;
      }
      .info {
        width: 60%;
        @include responsive("smDown") {
          width: 100%;
        }
        text-align: left;
        p:nth-child(1) {
          max-width: 97%;
        }
        p:nth-child(2) {
          max-width: 75%;
          @include responsive("smDown") {
            max-width: 100%;
          }
        }
        p:nth-child(3) {
          max-width: 47%;
          .underLine {
            @include responsive("mdUp") {
              color: $white-0;
              border-bottom: 1px solid $white-0;
            }
          }
          @include responsive("mdDown") {
            max-width: 25%;
          }
          @include responsive("smDown") {
            max-width: 100%;
          }
        }
        p:nth-child(4) {
          @include responsive("smDown") {
            max-width: 250px;
          }
        }

        p {
          color: $white-0;
          margin-bottom: 32px;
          @include responsive("smDown") {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
          }
          span {
            font-weight: 900;
            color: $yellow-2;
            @include responsive("smDown") {
              color: $white-0;
              font-weight: 500;
            }
          }
        }
      }
      .button {
        @include responsive("smDown") {
          width: 100%;
        }
        button {
          span {
            font-size: 32px;
            font-weight: 400;
            line-height: 39px;
            @include responsive("lgDown") {
              padding: 0 20px;
              font-size: 24px;
            }
            @include responsive("smDown") {
              font-size: 22px;
              padding: 12px 0px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}
