@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  height: auto !important;
  // height: calc(100vh - 490px);
  @include responsive("smDown") {
    padding: 0 20px;
  }
  .content {
    width: 100%;
    height: 335px;
    position: relative;
    overflow-y: auto;
    background-color: $white-0;
    margin: 0px auto;
    color: $black-0;
    margin-bottom: 16px;
    text-align: left;
    padding: 16px;
    font-weight: 500;
    font-family: "Inter";
    @include responsive("smDown") {
      word-break: break-all;
    }
    .scrollBottom {
      height: 16px;
    }
    &::-webkit-scrollbar {
      width: 14px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $gray-6;
      border-radius: 14px;
      border: 4px solid transparent;
      background-clip: content-box;
    }
    h2 {
      text-align: center;
      font-size: 20px;
      margin: 0 0 24px 0;
      font-weight: bold;
    }
    ul {
      list-style: auto;
      padding-left: 20px;
      li {
        > span {
          text-decoration: underline;
        }
      }
    }
    .termContent {
      ul {
        padding-left: 36px;
      }
    }
    .scrollIndicator {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 155px;
      background: #00000080;
      border-radius: 24px;
      color: $white-0;
      padding: 16px;
      display: flex;
      gap: 24px;
      align-items: center;
      > svg {
        transform: rotate(-90deg);
        path {
          fill: #ffffff80;
        }
      }
    }
  }
  .inputs {
    max-width: 778px;
    margin: 0 auto;
    height: 100%;
    .checkboxWrapper {
      height: calc(100%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .formGroupCheckBox {
        margin-bottom: 12px !important;
      }
      label {
        p {
          color: $white-0;
        }
        .disabledText {
          color: $gray-18;
        }
      }
      .buttonWrapper {
        margin-top: 60px;
        padding-bottom: 50px;
        max-width: 100%;
        @include responsive("smDown") {
          display: block;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          background: $black-4;
          box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
          padding: 12px 24px 34px 24px;
          z-index: 10;
          button {
            position: relative;
            span {
              font-size: 18px !important;
              line-height: normal !important;
            }
          }
          .button {
            max-width: 430px;
            span {
              font-size: 18px;
              line-height: normal;
            }
          }
        }
        .button {
          max-width: 430px;
          span {
            font-size: 32px;
            line-height: 39px;
          }
        }
      }
    }
    .error {
      color: rgb(245, 26, 26);
      font-size: 14px;
    }
  }
  .footerText {
    color: $white-0;
    font-family: "Inter";
    font-size: 18px;
    > span {
      margin-left: 5px;
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
}
.text {
  &.noMargin {
    margin: 0 !important;
  }
  &.marginLeft {
    margin-left: 16px;
  }
  > ul {
    list-style: none !important;
    padding-left: 24px !important;
    margin-top: 1rem;
  }
  > span {
    text-decoration: underline;
  }
}
.underline {
  > span {
    display: inline-block;
    position: relative;
    &:after {
      content: " ";
      display: block;
      height: 1px;
      width: 100%;
      border-top: 1px solid $black-0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}
