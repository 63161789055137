@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
.wrapper {
  padding: 75px 16px 45px;
  background-color: $black-0;
  .container {
    max-width: 1440px;
    margin: 0 auto;
    @include responsive("smDown") {
      padding: 40px 16px 43px;
    }
    .title {
      margin-bottom: 45px;
      @include responsive("smDown") {
        margin-bottom: 0px;
      }
      p {
        font-family: "Milonga";
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 49px;
        color: $white-0;
        @include responsive("smDown") {
          font-size: 32px;
        }
      }
    }
    .images {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin-bottom: 32px;
      .watch {
        position: relative;
        width: 138%;
        margin-top: 40px;
        img {
          transform: skew(1deg, 4deg);
        }
      }
      .coinOne {
        position: relative;
        margin-bottom: 40px;
        margin-left: 25px;
        margin-right: -25px;
        z-index: 10;
      }
      .coinTwo {
        position: relative;
        margin-top: 40px;
      }
      .coinThree {
        position: relative;
        margin-bottom: 40px;
        margin-left: -35px;
        margin-right: 35px;
      }
      img {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
      @include responsive("xsDown") {
        grid-template-columns: 1fr;
        padding-bottom: 40px;
        overflow: hidden;
        .watch {
          width: 90%;
          overflow: hidden;
          margin-left: -63px;
        }
        .coinOne {
          margin: -69px 50px 0;
          text-align: left;
          width: 100%;
          img {
            width: 80% !important;
          }
        }
        .coinTwo {
          text-align: right;
          width: 100%;
          margin: -69px -66px 0;
          img {
            width: 80% !important;
          }
        }
        .coinThree {
          text-align: left;
          width: 100%;
          margin: -69px 50px 0;
          img {
            width: 80% !important;
          }
        }
        img {
          height: auto;
          max-width: 100%;
        }
      }
    }
    .button {
      span {
        font-size: 32px;
        font-weight: 400;
        line-height: 39px;
      }
    }
  }
}
