@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.questionsWrapper {
  border-right: 2px solid $gray-9;
  padding: 32px 20px;
  overflow-x: auto;

  &.disable {
    pointer-events: none;
    opacity: 0.6;
  }
  //height: calc(100vh - 458px);
  //overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
  }
}
