@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.details {
  color: $white-0;
  display: flex;
  gap: 4px;
  background: $blue-4;
  border-radius: 10px;
  padding: 13px 21px;
  flex-wrap: wrap;
  margin-bottom: 36px;
  @include responsive("smDown") {
    gap: 24px;
    flex-direction: column;
  }
  .detail {
    display: flex;
    align-items: center;
    gap: 15px;
    .imageWrapper {
      width: 53px;
    }
    div {
      p {
        text-align: left;
      }
    }
  }
  .detail:nth-child(1) {
    margin-right: 58px;
  }
  .detail:nth-child(2) {
    margin-right: 74px;
  }
}
