@import "src/assets/scss/colors";
.wrapper {
  .header {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .buyMore {
    .buyMoreButton {
      display: flex;
      background: linear-gradient(115deg, #8053ea 1.53%, #3835a0 138.07%);
      span {
        color: $white-0;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 8px 16px;
      }
    }
  }
  .progressText {
    text-align: center;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    .done {
      margin-top: 20px;
      background: linear-gradient(115deg, #8053ea 1.53%, #3835a0 138.07%);
      span {
        color: $white-0;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 16px 24px;
      }
    }
  }
}
