@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  overflow-x: auto;
  &.modalQuestionWrapper {
    text-align: center;
    padding: 50px 10px;
    background-color: $white-0;
    border-radius: 0.375rem;
  }

  .inputsWrapper {
    padding: 2px;
    .errorText {
      text-align: left;
      padding-left: 7px;
    }
    .inputWrapper {
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;

      .dash {
        height: 56px;
        width: 64px;
        margin-bottom: 6px !important;
        margin-right: 6px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      .formGroup {
        margin-bottom: 6px !important;
        margin-right: 6px;
        .inputGroup {
          height: 56px;
          width: 64px;
          border-radius: 2px;
          border: 1px solid $gray-17;
          &:focus,
          &:focus-within,
          &:active,
          &:focus-visible {
            border-radius: 2px;
            border: 2px solid $blue-10;
            box-shadow: 0px 2px 4px 0px rgba(41, 174, 250, 0.6);
          }
          &.error {
            border-radius: 2px;
            border: 1px solid $red-0;
          }
          .input {
            caret-color: transparent;
            padding: 0;
            height: 100%;
            width: 100%;
            background-color: transparent;
            color: $blue-10;
            text-align: center;
            font-family: Inter;
            font-size: 56px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            &.preFilled {
              color: $black-0;
            }
          }
        }
      }
    }
  }
}
