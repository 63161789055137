@import "src/assets/scss/colors";

.wrapper {
  border-radius: 100px;
  border: 2px solid $purple-9;
  background: $blue-4;
  padding: 23px 49px;
  color: $white-0;
  > span {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    > span {
      font-size: 18px;
      font-weight: 600;
    }
  }
}