@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  margin: 0 auto;
  max-width: 720px;
  height: calc(100vh - 390px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include responsive("smDown") {
    padding: 16px;
  }
  .cardWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 56px;
    padding-bottom: 48px;
    @include responsive("smDown") {
      grid-template-columns: 1fr;
      padding-bottom: 100px;
    }

    .cardContainer {
      position: relative;
      padding: 3px;
      border-radius: 4px;
      cursor: pointer;
      &.selected {
        background: linear-gradient(0deg, #c1a875, #c1a875),
          linear-gradient(
            339.03deg,
            rgba(255, 255, 255, 0) 52.79%,
            #ffffff 95.95%
          ),
          linear-gradient(
            76.82deg,
            #576265 11.6%,
            #9ea1a1 21.66%,
            #848b8a 48.06%,
            #576265 74.22%,
            #576265 77.23%,
            #757a7b 88.05%,
            #576265 91.31%
          );
      }
      &:hover {
        background: linear-gradient(0deg, #c1a875, #c1a875),
          linear-gradient(
            339.03deg,
            rgba(255, 255, 255, 0) 52.79%,
            #ffffff 95.95%
          ),
          linear-gradient(
            76.82deg,
            #576265 11.6%,
            #9ea1a1 21.66%,
            #848b8a 48.06%,
            #576265 74.22%,
            #576265 77.23%,
            #757a7b 88.05%,
            #576265 91.31%
          );
      }

      .select {
        position: absolute;
        z-index: 10;
        right: 16px;
        top: 16px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.27);

        .active {
          border: 1px solid $black-5;
          background: $yellow-2;
          width: 16px;
          border-radius: 50%;
          height: 16px;
        }
      }

      .card {
        padding: 12px;
        background: $black-5;
        border-radius: 4px;
        height: 100%;
        &.selected {
          opacity: 0.8;
        }
        p {
          text-align: left;
        }
        .title {
          margin-bottom: 12px;
        }
        .list {
          color: $white-0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }
      }
    }
  }
  .text {
    margin-bottom: 40px;
  }
  .buttonWrapper {
    max-width: 540px;
    margin: 0 auto;
    width: 100%;
    @include responsive("smDown") {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $black-4;
      box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
      padding: 12px 24px 34px 24px;
      z-index: 10;
      button {
        position: relative;
        span {
          font-size: 18px !important;
          line-height: normal !important;
        }
      }
      .button {
        width: 100%;
        span {
          font-size: 18px;
          line-height: normal;
        }
      }
    }
    .button {
      width: 100%;
      span {
        font-size: 32px;
        line-height: 39px;
      }
    }
  }
}
