@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  padding: 0 20px;
  margin-top: 80px;
  @include responsive("smDown") {
    margin-top: 40px;
  }

  .bgImage {
    height: 100vh;
    width: 100vw;
  }

  .mainWrapper {
    bottom: 0px;
    padding: 1px;
    height: 100%;
    margin: 0 auto;
    max-width: 538px;
    color: $white-0;
    height: calc(100%);

    .signupInputs {
      margin-top: 26px;
      height: calc(100%);
      display: flex;
      flex-direction: column;
      justify-content: center;

      .inputContainer {
        margin-bottom: 89px;
        @include responsive("smDown") {
          margin-bottom: 30px;
        }

        .formGroup {
          text-align: left !important;
          @include responsive("smDown") {
            margin-bottom: 12px !important;
          }
        }

        .InputName {
          display: grid;
          gap: 28px;
          grid-template-columns: 1fr 1fr;
        }

        .label {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          font-family: "Inter";
        }

        .dateInput {
          input {
            border-radius: 3px;
            border: 1px solid $gray-7 !important;
            background: $white-0 !important;
            color: $black-0 !important;
            -webkit-text-fill-color: unset !important;
          }
        }

        .input {
          border-radius: 3px;
          border: 1px solid $gray-7 !important;
          background: $white-0 !important;
          color: $black-0;

          :global {
            .rselect__menu {
              z-index: 20;
              border-radius: 3px;
              border: 1px solid $gray-7;
              background: $white-0;
            }

            .rselect__input-container {
              color: $black-0;
            }

            .rselect__control {
              border: 1px solid $gray-7;
              border-radius: 4px;
              font-size: 16px;
              padding: 5px 1px;
              color: $black-0;
              background: $white-0;

              &:hover {
                border-color: $gray-6;
              }

              &.rselect__control--is-focused {
                box-shadow: none;
                border-color: $gray-0;
              }
            }

            .rselect__placeholder {
              color: $input-placeholder-color;
              font-family: "Inter";
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
            }

            .rselect__single-value {
              color: $black-0;
            }

            .rselect__option {
              color: $black-0;
              background-color: $white-0;
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
            }

            .rselect__option--is-focused {
              background-color: $white-4;
              color: $black-4;
            }

            .rselect__option--is-selected {
              background-color: $black-4 !important;
              color: $white-0 !important;
            }

            .rselect__option:hover {
              background-color: $yellow-6;
              color: $black-4;
            }
          }
        }
      }

      .buttonWrapper {
        max-width: 100%;
        @include responsive("smDown") {
          display: block;
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          background: $black-4;
          box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
          padding: 12px 24px 34px 24px;
          z-index: 10;
          button {
            position: relative;

            span {
              font-size: 18px !important;
              line-height: normal !important;
            }
          }
          .button {
            width: 100%;

            span {
              font-size: 18px;
              line-height: normal;
            }
          }
        }

        .button {
          max-width: 430px;

          span {
            font-size: 32px;
            line-height: 39px;
          }
        }
      }
    }
  }

  .footerText {
    margin-top: 37px;
    color: $white-0;
    font-family: "Inter";
    font-size: 18px;

    > span {
      margin-left: 5px;
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
}
