@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.fileModal {
  max-width: 600px;
  .select {
    width: fit-content;
    span {
      padding: 4px 25px;
      font-size: 32px;
      line-height: 39px;
    }
  }
  label {
    text-align: center;
    display: block;
    font-family: "Impact";
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: black;
  }
  .input {
    width: 0;
    height: 0;
    overflow: hidden;
  }
  :global {
    .modal-content {
      align-items: center;
      border-radius: 4px;
      background: $black-4;
      padding: 40px 80px;
    }
  }
}

.wrapper {
  text-align: left;
  p {
    color: $white-0;
  }
  .personalInfo {
    border-radius: 4px;
    background: $black-5;
    margin-bottom: 24px;
    padding: 22px 25px;
    .formGroup {
      max-width: 260px;
      .input {
        background-color: transparent;
      }
    }
    p {
      word-break: break-all;
    }
    label {
      color: $white-0;
    }
    .title {
      margin-bottom: 26px;
    }
    .photo {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      gap: 16px;
      .img {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        object-fit: cover;
      }
      .changePic {
        margin-top: 20px;
        button {
          border-radius: 4px;
          border: 1px solid $white-0;
          background: $black-5;
          color: $white-0;
        }
      }
    }
    .names {
      display: grid;
      grid-template-columns: 300px 1fr;
      gap: 24px;
      @include responsive("smDown") {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
    .numbers {
      display: grid;
      grid-template-columns: 300px 1fr;
      gap: 24px;
      @include responsive("smDown") {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
    .email {
      display: grid;
      grid-template-columns: 300px 1fr;
      gap: 24px;
      @include responsive("smDown") {
        grid-template-columns: 1fr;
        gap: 0;
      }
      .gender {
        :global {
          .rselect__control {
            background-color: transparent;
          }
        }
      }
    }
  }
  .username {
    border-radius: 4px;
    background: $black-5;
    padding: 22px 25px;
    margin-bottom: 32px;
    .title {
      margin-bottom: 24px;
    }
    label {
      color: $white-0;
    }
    .formGroup {
      max-width: 586px;
      .input {
        background-color: transparent;
      }
    }
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 32px;
    .submit {
      span {
        padding: 4px 32px;
        font-size: 32px;
        line-height: 39px;
      }
    }
    .cancelWrapper {
      span {
        text-decoration: none !important;
      }
      .cancel {
        color: $white-0
      }
    }
  }
}
