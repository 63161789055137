@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;

  .nav {
    // height: 100%;
    justify-content: space-between;
    position: sticky;
    top: -16px;
    background: $blue-4;
    z-index: 10;

    .mainTabs {
      display: flex;
      gap: 32px;
    }
    .viewSelect {
      display: flex;
      gap: 14px;
      svg {
        cursor: pointer;
      }
    }
    .navItem {
      min-width: 135px;
      cursor: pointer;
      user-select: none;
      &.disabled {
        pointer-events: none;
      }
      a {
        color: $white-0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        &.active {
          background: transparent !important;
          font-weight: 700;
          border-bottom: 4px solid $purple-1 !important;
        }

        &:hover {
          border: 1px solid transparent;
        }
      }
    }
  }
  .tabContent {
    position: relative;
    &.allFound {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .noData {
    color: $white-0;
    padding: 20px;
  }
}
