@import "src/assets/scss/colors";

.p10 {
  font-size: 10px;
  line-height: 14px;
}

.p12 {
  font-size: 12px;
  line-height: 16px;
}

.p13 {
  font-size: 13px;
  line-height: 16px;
}

.p14 {
  font-size: 14px;
  line-height: 18px;
}

.p16 {
  font-size: 16px;
  line-height: 20px;
}

.p18 {
  font-size: 18px;
  line-height: 22px;
}

.p20 {
  font-size: 20px;
  line-height: 24px;
}

.p24 {
  font-size: 24px;
  line-height: 32px;
}

.p28 {
  font-size: 28px;
  line-height: 36px;
}

.p32 {
  font-size: 32px;
  line-height: 44px;
}

.p42 {
  font-size: 42px;
  line-height: 50px;
}

.p44 {
  font-size: 44px;
  line-height: 50px;
}

.p48 {
  font-size: 48px;
  line-height: 64px;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.black {
  font-weight: 900;
}

.inter {
  font-family: Inter;
}

.milonga {
  font-family: Milonga;
}

.poppins {
  font-family: "Poppins-Regular";
}

.timesNewRoman {
  font-family: "Times New Roman";
}

.swiss {
  font-family: Swiss;
}

.impact {
  font-family: Impact;
}

.raleway {
  font-family: Raleway;
}

.merriweather {
  font-family: Merriweather-Black;
}

.arial {
  font-family: Arial;
}

.lobster {
  font-family: Lobster-Regular;
}

.arvo {
  font-family: Arvo-Regular;
}

//font-styles

.italic {
  font-style: italic;
}
