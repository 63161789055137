@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.dropdown {
  .actionButton {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .menu {
    width: fit-content;
    border-radius: 3px;
    border: 1px solid $gray-7;
    background: $black-4;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .item {
      display: flex;
      align-items: center;
      gap: 8px;
      &:hover {
        background-color: inherit;
      }
      img {
        height: 20px;
        width: 22px;
      }
    }
  }
}