@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding: 90px 16px;
  min-height: 100vh;
  background-color: $blue-4;
  display: flex;
  align-items: center;
  flex-direction: column;
  .lampWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    .lamp {
      position: relative;
    }
  }
  .logoContainer {
    width: 100%;
    img {
      width: 235px;
      height: 124px;
      height: 100%;
    }
  }
  .congratulationText {
    text-align: center;
    margin-top: 39px;
    font-family: Kavoon;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 54px */
    letter-spacing: -0.396px;
    -webkit-text-fill-color: $yellow-10;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $purple-1;
  }
  .successText {
    margin-bottom: 100px;
    padding: 4px 17px;

    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -0.176px;
    border-radius: 40px;
    background: linear-gradient(0deg, #ffc525 0%, #ffc525 100%),
      linear-gradient(
        339deg,
        rgba(255, 255, 255, 0) 52.79%,
        rgba(255, 255, 255, 0.58) 95.95%
      ),
      linear-gradient(
        77deg,
        rgba(177, 184, 186, 0.5) 11.6%,
        rgba(158, 161, 161, 0.5) 25.31%,
        rgba(255, 255, 255, 0.5) 48.06%,
        rgba(216, 233, 238, 0.5) 55.72%,
        rgba(158, 169, 172, 0.5) 77.23%,
        rgba(117, 122, 123, 0.5) 85.34%,
        rgba(205, 227, 233, 0.5) 91.31%
      ),
      #a2a0dc;
    background-blend-mode: color, overlay, normal, normal;
  }
  .confettiLamp {
  }
  .footer {
    .footerText {
      max-width: 340px;
      line-height: 24px;
      letter-spacing: -0.176px;
    }
    .buttonContainer {
      width: 100%;
      .downloadButton {
        width: 100%;
        max-width: 361px;
        margin-top: 20px;
        background-color: $purple-1 !important;
        span {
          color: $white-0;
          text-align: center;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding: 20px;
        }
      }
    }
  }
}
