@import "src/assets/scss/colors";

.modalWrapper {
  max-width: 1169px;
  :global {
    .modal-content {
      border-radius: 8px;
      border: 1px solid $blue-7;
      background: $blue-4;
      color: $white-0;
      min-height: calc(100vh - 300px);
      max-height: calc(100vh - 80px);
      > div {
        padding: 30px 32px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
      }
    }
    .modal-header {
      padding: 0;
      border: 0;
      .modal-title {
        display: flex;
        gap: 16px;
        > span {
          margin: 0;
          display: flex;
          gap: 16px;
          align-items: flex-end;
          font-size: 30px;
          & + span {
            font-size: 16px;
            font-weight: 400;
            position: relative;
            top: -5px;
            color: #E0E0E0;
          }
        }
        & + span {
          color: $white-0;
          svg {
            path {
              fill: $white-0;
            }
          }
        }
      }
    }
  }
}
.uploadPlaceholderWrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadPlaceholderInner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  > span {
    width: 132px;
    height: 132px;
    background: $blue-8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    line-height: 32px;
  }
  > button {
    width: 140px;
    margin: 0 auto;
    border-radius: 8px;
    padding: 8px 32px;
    height: 44px;
    > span {
      font-size: 24px;
      font-weight: 700;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 621px;
    margin: 0 auto;
    font-size: 12px;
    font-style: normal;
    gap: 8px;
    font-weight: 400;
  }
}