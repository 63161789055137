@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.card {
  position: absolute;
  right: 30px;
  bottom: 30px;
  border-radius: 8px;
  background: rgba(84, 85, 100, 0.6);
  padding: 11px;
  width: fit-content;
  @include responsive("mdDown") {
    position: relative;
    right: 0;
    margin: 0 16px;
  }
  .ctaWrapper {
    border-radius: 8px;
    border: 3px solid $purple-3;
    padding: 22px;
    .image {
      margin-bottom: 22px;
      img {
      }
    }
    .buttonWrapper {
      width: 100%;
      .button {
        width: 100%;
        border-radius: 16px;
        span {
          font-size: 28.636px;
          border-radius: 16px;
          line-height: normal;
        }
      }
    }
  }
}
