@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  &.modalQuestionWrapper {
    text-align: center;
    padding: 50px 0px;
    aspect-ratio: 16/9;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  img {
    max-width: 100%;
  }
}
