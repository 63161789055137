@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.buttonStyling {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
  line-height: 20px;
  .leftIconStyling {
    margin-right: 10px;
  }

  .rightIconStyling {
    margin-left: 10px;
  }

  border: none;

  &.noButtonText {
    .leftIconStyling {
      margin-right: 0;
    }
  }
  &:global(.btn-lg) {
    font-size: 16px;
    padding-top: 18px;
    padding-bottom: 18px;
  }
  &.fontWeight_semibold {
    font-weight: 600;
  }
  &.fontWeight_bold {
    font-weight: bold;
  }
  &.fullWidth {
    width: 100%;
  }
  &.radius_lg {
    border-radius: 8px;
  }
  &.gap_xs {
    .leftIconStyling {
      margin-right: 4px;
    }
  }
  &.flex {
    display: flex;
    align-items: center;
    > span {
      display: flex;
    }
  }
}

.link {
  display: inline-flex;
  gap: 4px;
  cursor: pointer;
}

.linkStyling {
  border: none !important;
}

.md {
  padding: 12px 20px;
}

.sm {
  padding: 7px 12px;
}

.disabled {
  opacity: 0.4;
  cursor: default;
  &:disabled {
    opacity: 0.4 !important;
  }
}

//btn-fill

.primary-fill {
  background-color: $primary-btn-color !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $primary-btn-color;
    border: none;
  }
  &.dark {
    background-color: $blue-4 !important;
  }
}

.secondary-fill {
  border: none;
  padding: 3px 6px;
  border-radius: 24px;
  background: linear-gradient(0deg, #c1a875, #c1a875),
    linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
    linear-gradient(
      76.82deg,
      #576265 11.6%,
      #9ea1a1 25.31%,
      #848b8a 48.06%,
      #576265 55.72%,
      #576265 77.23%,
      #757a7b 85.34%,
      #576265 91.31%
    );
  background-blend-mode: color, overlay, normal;
  @include responsive("smDown") {
    width: 100%;
    padding: 3px 3px;
  }
  .spanWrapper {
    display: block;
    font-family: "Impact";
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 49px;
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: black;
    color: $white-0;
    background: linear-gradient(0deg, #c1a875, #c1a875),
      linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
      linear-gradient(
        76.82deg,
        #576265 11.6%,
        #9ea1a1 25.31%,
        #848b8a 48.06%,
        #576265 55.72%,
        #576265 77.23%,
        #757a7b 85.34%,
        #576265 91.31%
      );
    border: 2.5px solid #141414;
    border-radius: 22px;
    padding: 0 56px;
    background-blend-mode: color, overlay, normal;
    @include responsive("smDown") {
      -webkit-text-stroke-width: 1px;
      font-style: normal;
      padding: 8px 0;
      font-weight: 400;
      font-size: 30px;
      line-height: 37px;
    }
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    background-color: $secondary-btn-color;
    color: $black-0;
    border: none;
  }
  &:disabled {
    color: $black-0;
  }
}

.secondary-filled-fill {
  border: none;
  padding: 8px 18px;
  border-radius: 8px;
  background: linear-gradient(0deg, #c1a875, #c1a875),
    linear-gradient(339.03deg, rgba(255, 255, 255, 0) 52.79%, #ffffff 95.95%),
    linear-gradient(
      76.82deg,
      #576265 11.6%,
      #9ea1a1 25.31%,
      #848b8a 48.06%,
      #576265 55.72%,
      #576265 77.23%,
      #757a7b 85.34%,
      #576265 91.31%
    );
  background-blend-mode: color, overlay, normal;

  span {
    display: block;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $white-0;
    background-blend-mode: color, overlay, normal;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    background-color: $secondary-btn-color;
    color: $black-0;
    border: none;
  }
  &:disabled {
    color: $black-0;
  }
}

.purple-fill {
  background: $purple-1 !important;
  &:disabled {
    background: $purple-4 !important;
    opacity: 1 !important;
  }
}

.info-fill {
  background-color: $info-btn-color !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $info-btn-color;
    border: none;
  }
}

.success-fill {
  background-color: $success-btn-color !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $success-btn-color;
    border: none;
  }
}

.danger-fill {
  background-color: $danger-btn-color !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $danger-btn-color;
    border: none;
  }
}

.warning-fill {
  background-color: $warning-btn-color !important;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 4px 6px rgba(50, 50, 93, 0.11),
      0px 1px 3px rgba(0, 0, 0, 0.08);
    color: $white-0;
    background-color: $warning-btn-color;
    border: none;
  }
}

.gray-light-fill {
  background: rgba(224, 224, 224, 0.4) !important;
}
.white-fill {
  background: white !important;
  color: $blue-4 !important;
  &.isGhost {
    background: transparent !important;
    color: $white-0 !important;
    border: 1px solid;
    border-color: $white-0 !important;
  }
}

//btn-outline

.primary-outline {
  border: 1px solid $primary-btn-color !important;
  color: $primary-btn-color !important;
  background-color: transparent !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $primary-btn-color;
    background-color: $primary-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}

.secondary-outline {
  border: 1px solid $black-0;
  color: $black-0;
  background-color: transparent !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $black-0;
    background-color: $black-0;
    color: $black-0;
    box-shadow: none;
  }
}

.info-outline {
  border: 1px solid $info-btn-color !important;
  color: $info-btn-color !important;
  background-color: transparent !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $info-btn-color;
    background-color: $info-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}

.success-outline {
  border: 1px solid $success-btn-color !important;
  color: $success-btn-color !important;
  background-color: transparent !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $success-btn-color;
    background-color: $success-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}

.danger-outline {
  border: 1px solid $danger-btn-color !important;
  color: $danger-btn-color !important;
  background-color: transparent !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $danger-btn-color;
    background-color: $danger-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}

.warning-outline {
  border: 1px solid $warning-btn-color !important;
  color: $warning-btn-color !important;
  background-color: transparent !important;

  &:hover,
  &:focus,
  &:active {
    border: 1px solid $warning-btn-color;
    background-color: $warning-btn-color;
    color: $white-0;
    box-shadow: none;
  }
}

.primary-link {
  color: $primary-btn-color;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.secondary-link {
  color: $white-0;
  cursor: pointer;
  text-decoration: underline;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.info-link {
  color: $info-btn-color;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.success-link {
  color: $success-btn-color;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.danger-link {
  color: $danger-btn-color;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.warning-link {
  color: $warning-btn-color;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

.text_size_lg {
  font-size: 24px;
}
.fontWeight_semibold1 {
  font-weight: 500;
}
.padding_size_lg {
  padding: 8px 32px;
}

.purple-gradient-fill {
  border-radius: 8px;
  border: 1px solid $white-0;
  background: linear-gradient(94deg, #7c44cd 46.64%, #fe00dc 154.68%);
  box-shadow: 0px 2px 28px 0px rgba(0, 0, 0, 0.85);
  width: 100%;
  font-family: "Inter";
  font-size: 28px;
  line-height: normal;
  padding: 15px 0px;
  font-weight: 600;
}
.purple-gradient-outline {
  border-radius: 8px;
  border: 3px solid $purple-3;
  background: transparent;
  box-shadow: 0px 2px 28px 0px rgba(0, 0, 0, 0.85);
  width: 100%;
  font-family: "Inter";
  font-size: 28px;
  font-weight: 600;
  background: #13132d;
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
    border: 3px solid $purple-1;
  }
}
