@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  position: relative;
  padding-top: 138px;
  max-width: 1440px;
  margin: 0 auto;

  @include responsive("smDown") {
    grid-template-columns: 1fr;
  }
  @include responsive("mdDown") {
    padding-top: 34px;
    //overflow: hidden;
  }
  .downloadApp {
    display: none;
    margin-top: 32px;
    color: $white-0;
    @include responsive("smDown") {
      display: block;
    }
  }
  .imgWrapper {
    .imgSide {
      width: 100%;
      position: absolute;
      right: -2%;
      top: -26%;
      @include responsive("mdDown") {
        top: -14%;
      }
      @include responsive("smDown") {
        max-width: 138%;
        position: relative;
      }
    }
  }

  .content {
    padding: 50px;
    z-index: 10;
    @include responsive("smDown") {
      grid-template-columns: 1fr 1fr;
      padding: 23px;
    }
    .contentWrapper {
      display: flex;
      justify-content: flex-end;
      .info {
        max-width: 585px;
      }
      .button {
        text-align: end;
        margin-bottom: 32px;
        button {
          span {
            font-weight: 400;
            font-size: 32px;
            line-height: 39px;
            @include responsive("mdDown") {
              font-size: 22px;
              line-height: 28px;
            }
          }
        }
      }
      .text {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 150%;
        text-align: start;
        color: $white-0;
        @include responsive("smDown") {
          font-weight: 500;
          font-size: 16px;
          line-height: 150%;
          text-align: left;
        }
      }
    }
  }
}
.btnWrapper {
  @include responsive("smDown") {
    position: relative;
  }
  .bottomImage {
    width: 100%;
    @include responsive("smDown") {
      height: 221px;
    }
  }
  .button {
    display: none;
    button {
      span {
        @include responsive("smDown") {
          font-size: 22px;
        }
      }
    }
    @include responsive("smDown") {
      display: block;
      position: absolute;
      bottom: 0px;
      width: 100%;
      padding: 0px 23px 54px 23px;
    }
  }
}
