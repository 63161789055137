.spinnerWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #00000099;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.wrapper {
  &.center {
    margin: 0 auto;
  }
}