@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.contentColumn {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  img {
    min-width: 126px;
    max-width: 126px;
    height: 71px;
    object-fit: contain;
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    > span {
      font-size: 14px;
      font-weight: 300;
      &:nth-child(1) {
        font-weight: 400;
        @include tooLongText(1);
      }
      &:nth-child(2) {
        @include tooLongText(2);
      }
    }
  }
  .image {
    position: relative;
    height: 71px;
    min-width: 126px;
    max-width: 126px;
  }
  .videoDuration {
    position: absolute;
    bottom: 4px;
    right: 4px;
    padding: 2px 4px;
    color: $white-0;
    border-radius: 4px;
    background: $blue-4;
  }
}
.table {
  display: flex;
  flex-direction: column;
  height: 100%;
  > table {
    & + div {
      margin-top: auto;
    }
  }
}
.statusDisplay {
  display: flex;
  align-items: center;
  gap: 4px;
  > svg {
    width: 24px;
    height: 24px;
    circle, path {
      stroke: #27AE60;
    }
  }
}