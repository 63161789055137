@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  text-align: left;
  > span {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding: 7px 20px;
    border-radius: 100px;
    color: #0E012B;
    display: inline-block;
  }
}

@include responsive("smDown1") {
  .wrapper {
    > span {
      font-size: 18px;
    }
  }
}