body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Milonga";
  src: url("./assets/fonts/Milonga-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Variable.ttf");
}

@font-face {
  font-family: "Times New Roman";
  src: url("./assets/fonts/Times-New-Roman.ttf");
}

@font-face {
  font-family: "Swiss";
  src: url("./assets/fonts/Swiss.ttf");
}

@font-face {
  font-family: "Impact";
  src: url("./assets/fonts/Impact.ttf");
}

@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway-Variable.ttf");
}

@font-face {
  font-family: "Merriweather";
  src: url("./assets/fonts/Merriweather-Regular.ttf");
}

@font-face {
  font-family: "Merriweather-Black";
  src: url("./assets/fonts/Merriweather-BlackItalic_3.ttf");
}

@font-face {
  font-family: "Lobster-Regular";
  src: url("./assets/fonts/Lobster-Regular.ttf");
}

@font-face {
  font-family: "Arvo-Regular";
  src: url("./assets/fonts/Arvo-Regular.ttf");
}

@font-face {
  font-family: "Kavoon";
  src: url("./assets/fonts/Kavoon-Regular.ttf");
}

@font-face {
  font-family: "Verdana";
  src: url("./assets/fonts/Verdana.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: "Poppins-Semibold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("./assets/fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Black";
  src: url("./assets/fonts/Poppins-Black.ttf");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("./assets/fonts/Poppins-Medium.ttf");
}

.customTooltip {
  .tooltip.show {
    opacity: 1 !important;
  }
}
.pointer {
  cursor: pointer !important;
}
.pointer-default {
  cursor: default !important;
}

// Toastify
.Toastify {
  .Toastify__toast-container {
    width: auto;
  }
  .Toastify__toast {
    &.Toastify__toast--error {
      background: #f5365c;
      color: white;
    }
    .Toastify__close-button {
      color: white;
      opacity: 1;
      > svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
    .Toastify__toast-icon {
      svg {
        fill: white;
      }
    }
    &.Toastify__toast--success {
      color: white;
      background: linear-gradient(90deg, #9553ea -26.03%, #241945 105.46%);
      border-radius: 8px;
      border: 1px solid rgba(255, 255, 255, 0.7);
      min-height: 64px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      .Toastify__toast-body {
        padding: 0;
        margin-right: 96px;
        font-size: 20px;
      }
      .Toastify__close-button {
        align-self: center;
      }
    }
    &.toast-free {
      background: #393939;
      border-color: #393939;
    }
  }
}

// Custom game
.game-header-time-up {
  color: #f85a5a !important;
}
#custom-game-answer {
  margin-top: 20px;
  border-radius: 8px;
  background: #4645a2;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  color: #fff;
  text-align: center;
  padding: 10px 28px;
  min-width: 102px;
  &.getting {
    text-align: left;
    &:after {
      content: ".";
      animation-name: loading-text;
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
  }
}
@keyframes loading-text {
  0% {
    content: ".";
  }
  25% {
    content: "..";
  }
  50% {
    content: "...";
  }
  75% {
    content: "....";
  }
}
