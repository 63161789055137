@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  background: $black-5;
  box-shadow: none;
  &:hover {
    background: radial-gradient(
        ellipse farthest-corner at right bottom,
        #9f7928 0%,
        #fdb931 8%,
        #9f7928 30%,
        #8a6e2f 40%,
        transparent 80%
      ),
      radial-gradient(
        ellipse farthest-corner at left top,
        #ffffff 0%,
        #ffffac 8%,
        #d1b464 25%,
        #5d4a1f 62.5%,
        #5d4a1f 100%
      );
      box-shadow: 0px 2px 30px black;
  }

  .title {
    margin-bottom: 4px;
    line-height: 39px;
  }
  .details {
    margin-bottom: 22px;
    opacity: 0.6;
  }
  .bodyStyles {
    background: $black-5;
    margin: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    border: 3px solid transparent;
    border-radius: 6px;
    color: $white-0;

    .button {
      width: fit-content;
    }
  }
}
