@import "src/assets/scss/colors";

.modalStyle {
  position: absolute;
}
.modalBackdrop {
  position: absolute;
  top: -16px;
  left: -24px;
  bottom: -32px;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(3.5px);
  opacity: 0.9 !important;
}

.modalContent {
  max-height: calc(100vh - 205px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $gray-0;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-23;
    border-radius: 8px;
    border: 0.5px solid rgba(255, 255, 255, 0.5);
  }
}

.modalWrapper {
  max-width: 702px;
  :global {
    .modal-content {
      border-radius: 8px;
      border: 1px solid $blue-7;
      background: $blue-4;
      color: $white-0;
      // min-height: calc(100vh - 300px);
      // max-height: calc(100vh - 80px);
      > div {
        padding: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
      }
    }
  }
  .modalBody {
    padding: 0;
    .spinnerWrapper {
      margin-top: 50px;
    }
    .map {
      img {
        width: 100%;
        height: auto;
      }
    }
    .countdown {
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        line-height: 38px;
      }
      div:last-child {
        width: 90px;
      }
    }
  }
}
