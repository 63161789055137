@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  color: $white-0;
  padding: 0 16px;
  margin-top: 40px;
  :global {
    .StripeElement {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      padding: 12px 14px;
      font-size: 1em;
      border: 1px solid $white-4;
      color: $white-0;
      outline: 0;
      border-radius: 4px;
      background: transparent;
    }
    .inputFieldStripe {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      padding: 12px 14px;
      font-size: 1em;
      border: 1px solid $white-4;
      color: $white-0;
      outline: 0;
      border-radius: 4px;
      background: transparent;
    }
    .stripeError {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      padding: 12px 14px;
      font-size: 1em;
      color: $white-0;
      outline: 0;
      border-radius: 4px;
      background: transparent;
      border: 1px solid $warning-btn-color;
    }
    .StripeElement--invalid {
      border: 1px solid $warning-btn-color;
    }
  }
  @include responsive("smDown") {
    margin-top: 16px;
  }

  .formWrapper {
    max-width: 538px;
    margin: 0 auto;
    height: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .formGroupCheckBox {
      margin-bottom: 35px !important;
    }

    .label {
      @include responsive("smDown") {
        margin-bottom: 8px !important;
      }
    }
    label {
      @include responsive("smDown") {
        margin-bottom: 8px !important;
      }
    }
    .formGroupInput {
      @include responsive("smDown") {
        margin-bottom: 24px !important;
      }
    }

    .selectWrapper {
      text-align: left;
      @include responsive("smDown") {
        margin-bottom: 24px !important;
        :global {
          .rselect__placeholder {
            color: $input-placeholder-color;
            font-family: "Inter";
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
    .buttonWrapper {
      button {
        width: 100%;
      }
      span {
        font-size: 32px;
        line-height: 39px;
      }
    }
    .address {
      display: grid;
      gap: 28px;
      grid-template-columns: 1fr 1fr;
      @include responsive("smDown") {
        gap: 0px;
        grid-template-columns: 1fr;
      }
    }
    .stripeInputs {
      display: grid;
      gap: 28px;
      grid-template-columns: 1fr 1fr;
      @include responsive("smDown") {
        grid-template-columns: 1fr;
        gap: 8px;
      }
      .cardNumber {
        text-align: left !important;
      }
      .expiryAndCvc {
        display: grid;
        gap: 28px;
        grid-template-columns: 1.5fr 1fr;
        text-align: left !important;
        margin-bottom: 8px;
        @include responsive("smDown") {
          grid-template-columns: 1fr;
          gap: 8px;
        }
      }
      .errorMessage {
        font-size: 14px;
        color: $warning-btn-color;
        text-align: left;
      }
    }
  }
  .form {
    .paymentDetails {
      .cvcExpiry {
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-gap: 18px;
      }
    }
    .terms {
      :global {
        .custom-checkbox {
          align-items: center;
        }
      }
    }
  }
}
