@import "src/assets/scss/colors";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  :global {
    .ReactTags__tags {
      display: flex;
      .ReactTags__selected {
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        border: 1px solid rgba(28, 28, 28, 0.60);
        background: $blue-8;
        padding: 10px 16px;
        width: 100%;
        flex-wrap: wrap;
        .tag-wrapper.ReactTags__tag {
          border-radius: 4px;
          border: 1px solid #FDFDFD;
          display: flex;
          padding: 4.5px 8.5px;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          font-weight: 700;
          > button {
            background: transparent;
            border: 1px solid #ffffff99;
            border-radius: 50%;
            display: flex;
            width: 16px;
            height: 16px;
            align-items: center;
            justify-content: center;
            color: #ffffff99;
            padding-bottom: 4px;
            padding-right: 6px;
          }
        }
        .ReactTags__tagInput {
          flex: 1;
          input {
            background: transparent;
            border: 0 !important;
            outline: none !important;
            box-shadow: none !important;
            color: white;
            width: 100%;
          }
        }
      }
    }
  }
}