@import "src/assets/scss/colors";

.wrapper {
  color: $white-0;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  > * {
    min-width: 100%;
    max-width: 100%;
  }
}
.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  > *:not(.loading) {
    min-width: 100%;
    max-width: 100%;
  }
}
.subWrapper {
}