@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding-top: 90px;
  @include responsive("smDown") {
    padding-top: 0;
  }
  .tgMobileImg {
    display: none;
    @include responsive("smDown") {
      display: block;
      margin-bottom: 74px;
      img {
        max-width: 277px;
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
  .checkIcon {
    max-width: 181px;
    margin-bottom: 43px;
    @include responsive("smDown") {
      max-width: 83px;
      margin-bottom: 24px;
    }
  }
  .content {
    color: $white-0;
    max-width: 617px;
    margin: 0 auto 16px;
    @include responsive("smDown") {
      margin-bottom: 70px;
    }
    .welcomeText {
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;
      @include responsive("smDown") {
        font-size: 24px;
        line-height: normal;
      }
    }
    .infoText {
      @include responsive("smDown") {
        font-size: 16px;
        line-height: normal;
        max-width: 254px;
        margin: 0 auto;
      }
    }
  }
  .buttonWrapper {
    color: $white-0;
    margin-bottom: 16px;
    text-align: center;
    @include responsive("smDown") {
      p {
        margin-bottom: 30px;
        font-size: 14px;
        line-height: 21px;
        max-width: 236px;
        margin: 0 auto 28px auto;
      }
    }
    .button {
      border-radius: 20px;
      width: 60%;
      @include responsive("xsDown") {
        width: 100%;
        border-radius: 14px;
      }

      span {
        border-radius: 20px;
        font-size: 32px;
        line-height: 39px;
        @include responsive("xsDown") {
          font-size: 18px;
          line-height: 18px;
          border-radius: 14px;
        }
      }
    }
  }
  .accessOnMobile {
    // display: none;
    @include responsive("smDown") {
      .access {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .footer {
    margin-top: 100px;
    margin-bottom: 50px;
    color: $white-0;
    @include responsive("smDown") {
      display: none;
    }
    .footerText {
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 32px;
    }
  }
}
