@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  margin-top: 40px;
  .purchaseTitle {
    text-align: left;
  }
  .cardContainer {
    margin-top: 21px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    .spinnerWrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
    .noData {
      padding: 140px;
      width: 100%;
    }
  }
}
