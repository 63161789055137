@import "src/assets/scss/variables";


.wrapper {
  min-width: $clue-game-width;
  min-height: $clue-game-height;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
  }
  :global {
    .play-board {
      /* border : 4px solid black; */
      height: $clue-game-height;
      width: $clue-game-width;
      display: grid;
      /* background: #212837; */
      grid-template: repeat(7, 1fr) / repeat(12, 1fr);
    
      background-image: linear-gradient(90deg, #9b99f4 60px, #8b89e7 60px),
        linear-gradient(90deg, #8b89e7 60px, #9b99f4 60px),
        linear-gradient(90deg, #9b99f4 60px, #8b89e7 60px),
        linear-gradient(90deg, #8b89e7 60px, #9b99f4 60px),
        linear-gradient(90deg, #9b99f4 60px, #8b89e7 60px),
        linear-gradient(90deg, #8b89e7 60px, #9b99f4 60px),
        linear-gradient(90deg, #9b99f4 60px, #8b89e7 60px);
      background-position: 0 0, 0 60px, 0 120px, 0 180px, 0 240px, 0 300px, 0 360px,
        0 $clue-game-height;
      background-repeat: repeat-x;
      background-size: 120px 60px, 120px 60px, 120px 60px, 120px 60px, 120px 60px,
        120px 60px, 120px 60px, 120px 60px, 120px 60px;
      :global {
        .food {
          background: url(~src/assets/images/game/lamp.png) no-repeat center center;
        }
        .body {
          background: url(~src/assets/images/game/snake.png) no-repeat center center;
        }
        
        .head {
          background: url(~src/assets/images/game/snake.png) no-repeat right center;
        }
        
        .tail {
          background: url(~src/assets/images/game/snake.png) no-repeat left center;
        }
    
        .direct-right {
          transform: rotate(0);
        }
        
        .direct-up {
          transform: rotate(-90deg);
        }
        
        .direct-left {
          transform: rotate(-180deg);
        }
        
        .direct-down {
          transform: rotate(90deg);
        }
      }
    }

    .success_popup {
      position: absolute;
      z-index: 5;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      color: white;
    
      background: radial-gradient(
        50% 50% at 50% 50%,
        #8053ea 0%,
        rgba(128, 83, 234, 0) 100%
      );
    
      h2 {
        color: white;
        display: block;
        font-family: "Kavoon";
        font-size: 64px;
        margin-bottom: 21px;
      }
    }

    #bt_start_game {
      border-radius: 8px;
      color: white;
      background: #77c54d;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      padding: 10px 18px;
      outline: none;
      box-shadow: none;
      border: none;
      cursor: pointer;
    }
    
    #fail_game {
      font-size: 24px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #121429 0%,
        rgba(128, 83, 234, 0) 100%
      );
    }
    
    #bt_restart_game {
      border-radius: 8px;
      background: #8053ea;
      padding: 10px 18px;
      color: white;
      margin-top: 21px;
      outline: none;
      box-shadow: none;
      border: none;
      cursor: pointer;
      font-size: 24px;
      font-weight: 600;
    }
    
    .hide {
      display: none;
    }
  }
  
}

