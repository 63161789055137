@import "src/assets/scss/colors";

.actionBtn {
  svg {
    transform: rotate(90deg);
  }
}
.wrapper {
  button {
    background: transparent !important;
  }
  :global {
    .dropdown-item {
      > div {
        margin-right: 8px !important;
      }
    }
  }
  &.disabled {
    :global {
      .dropdown-menu {
        display: none !important;;
      }
    }
    .actionBtn {
      cursor: default !important;
    }
  }
}
.dropMenuItem {
  &:active {
    color: $white-0 !important;
  }
}