@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.mainWrapper {
  background-color: $black-4;
  padding: 16px;
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 140px;
    @include responsive("mdDown") {
      margin-bottom: 50px;
    }
    .logo {
      max-width: 600px;
      width: 100%;
      margin-bottom: 16px;
    }
  }
  .container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;

    .spinner {
      margin-bottom: 30px;
    }
  }
  .bottomText {
    padding-bottom: 140px;
  }
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
