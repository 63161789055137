@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  &.isInvalid {
    .input {
      border-color: $warning-btn-color;
    }
  }
}
.input {
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(50,50,93,.15), 0 1px 0 rgba(0,0,0,.02);
  > input {
    color: $white-0;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    line-height: 1.5;
    text-transform: uppercase;
    padding: 0.375rem 0.75rem;
    background: transparent;
    border: 0 !important;
    width: 100%;
    outline: 0 !important;
    height: 46px;
  }
}
.invalidFeedback {
  text-align: left;
  display: flex;
  color: $warning-btn-color;
  padding: 8px 12px;
  font-size: 14px;
}