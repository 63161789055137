@import "src/assets/scss/colors";

.wrapper {
  margin-bottom: 24px;
  .header {
  }
  .alert {
    margin-bottom: 16px;
    display: flex;
    height: 91px;
    padding: 16px;
    align-items: center;

    border-radius: 8px;
    background: $blue-4;
    .notiMessage {
      text-align: left;
      span {
        color: $pink-0;
      }
    }
  }
  .notificationWrapper {
    display: flex;
    > div {
      flex: 1;
      color: $white-0;
      margin-right: 8px;
      min-width: calc(33.3% - 8px);
      max-width: calc(33.3% - 8px);
    }
    // .card {
    //   cursor: pointer;
    //   display: grid;
    //   grid-template-columns: 8px 1fr 70px;
    //   border-radius: 6.026px;
    //   overflow: hidden;
    //   background-color: $purple-5;
    //   margin-bottom: 10px;
    //   align-items: center;
    //   .indicator {
    //     background-color: transparent;
    //     &.active {
    //       background-color: $white-0;
    //       height: 100%;
    //     }
    //   }
    //   .content {
    //     padding: 10px 15px;
    //     .msg {
    //     }
    //     .details {
    //       .title {
    //         display: flex;
    //         align-items: center;
    //         gap: 10px;
    //       }
    //     }
    //   }
    //   .bell {
    //     width: 58px;
    //     height: 58px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     border-radius: 50%;
    //     background-color: $purple-6;
    //   }
    // }

  }
}
