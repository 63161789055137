@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  overflow: hidden;
  width: 100%;
  background: $blue-4;
  border-radius: 10px;
  margin-bottom: 24px;
  // padding: 40px 30px;
  position: relative;

  .mapHeader {
    user-select: none;
    position: absolute;
    padding: 40px 30px 0 40px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .image {
      img {
        max-width: 100%;
      }
    }
    .mapActions {
      display: flex;
      gap: 12px;
      button {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        border: 1px solid $white-0;
        padding: 3px 8px;
      }
    }
  }
  .map {
    margin-top: 70px;
    z-index: 1000;

    svg {
      overflow: visible;
    }
  }
  .state {
  }
  .lampImage {
    width: 105px;
    height: auto;
    &:focus {
      outline: none;
    }
  }
}
