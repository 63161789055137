@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.wrapper {
  position: relative;
}
.video {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  position: relative;
  max-width: 100%;
  display: flex;
  video {
    border: 3px solid $purple-3;
    border-radius: 8px;
    padding: 1px 0;
  }
}
.duration {
  position: absolute;
  right: 8px;
  bottom: 8px;
  padding: 2px 4px;
  border-radius: 4px;
  background: $blue-4;
  font-size: 16px;
  font-weight: 400;
  color: white;
  line-height: 24px;
}