@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  margin: 0 auto;
  background-color: black;
  padding: 20px 0px 106px 0px;
  max-width: 1276px;
  @include responsive("smDown") {
    padding: 20px 16px 35px 16px;
  }
  .title {
    margin-bottom: 32px;
    p {
      color: $white-0;
      text-align: left;
      font-weight: 400;
      font-size: 30px;
      line-height: 38px;
    }
  }
  .content {
    padding-left: 80px;
    padding-right: 80px;
    .image {
      max-width: 450px;
      margin: 0 auto;
      img {
        width: 100%;
        margin-bottom: 48px;
        @include responsive("smDown") {
          margin-bottom: 24px;
        }
      }
    }
    .action {
      max-width: 360px;
      margin: 0 auto;
      padding-left: 16px;
      padding-right: 16px;
      .button {
        font-family: Impact;
        width: 100%;
        border-radius: 17.569px;

        span {
          border-radius: 17.569px;
          font-size: 32px;

          @include responsive("smDown") {
            font-size: 16px;
            line-height: normal;
          }
        }
      }
    }
  }
}
