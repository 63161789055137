@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.thumbnailWrapper {
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
  max-height: 138px;

  img {
    width: 100%;
  }
}

.cardWrapper {
  .clueCardBody {
    padding: 8px 10px 16px 10px;
    // height: 260px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftSide {
      display: flex;
      align-items: center;
      gap: 10px;

      .notViewedBadge {
        width: 43px;
        height: 18px;
        border-radius: 10px;
        background: $purple-1;
        color: $white-0;
        font-family: "Inter";
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        margin-left: 4px;
      }

      .complete {
      }

      .skip {
        overflow: visible;

        circle {
          fill: $white-0;
          stroke-width: 1.5px;
          stroke: $green-0;
        }
      }

      .upcoming {
        overflow: visible;

        circle {
          fill: $white-0;
          stroke-width: 1.5px;
          stroke: #696969;
        }
      }

      .pending {
        overflow: visible;

        circle {
          fill: $white-0;
          stroke-width: 2px;
          stroke-dasharray: 4 4;
          stroke: $white-6;
        }
      }
    }

    .clueType {
      display: flex;
      align-items: center;
      gap: 7px;

      p {
        text-transform: capitalize;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .cardContent {
    height: 100%;

    .button {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .completedCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      color: $white-0;
      padding: 12px 16px;
      height: 100%;
      background: linear-gradient(114.8deg, #121429 1.53%, #3835a0 138.07%);
      border-radius: 8px;

      .answer {
        .title {
          margin-bottom: 9px;
        }
      }
    }

    .skipCard {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: inherit;

      .skipImg {
        position: relative;

        .cluImg {
          svg {
            width: 148px;
            height: 148px;
          }
        }

        .expandSvg {
          cursor: pointer;
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
      }

      .button {
        width: 100%;
        background: linear-gradient(114.8deg, #121429 1.53%, #3835a0 138.07%);
        border-radius: 8px;
        padding: 12px 0px;
      }
    }

    .activeCard {
      svg {
        width: 148px;
        height: 148px;
      }

      .button {
        width: 100%;
        background: linear-gradient(114.8deg, #121429 1.53%, #3835a0 138.07%);
        border-radius: 8px;
        padding: 12px 0px;
      }
    }

    .pendingCard {
      svg {
        width: 148px;
        height: 148px;
        filter: blur(9px);
      }

      .button {
        width: 100%;
        background: linear-gradient(114.8deg, #121429 1.53%, #3835a0 138.07%);
        border-radius: 8px;
        padding: 12px 0px;

        &:disabled {
          border: none !important;
          background: linear-gradient(114.8deg, #818181 1.53%, #cdcdcd 138.07%);
        }
      }
    }

    .upcomingCard {
      svg {
        width: 148px;
        height: 148px;
      }

      .button {
        width: 100%;
        background: linear-gradient(114.8deg, #121429 1.53%, #3835a0 138.07%);
        border-radius: 8px;
        padding: 12px 0px;

        &:disabled {
          background: linear-gradient(114.8deg, #818181 1.53%, #cdcdcd 138.07%);
        }
      }
    }

    .lockedCard {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      justify-content: space-between;

      svg {
        width: 100px;
        height: 100px;
      }

      .button {
        width: 100%;
        background: linear-gradient(114.8deg, #121429 1.53%, #3835a0 138.07%);
        border-radius: 8px;
        padding: 12px 0px;
      }
    }
  }
}
