@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.inputs {
  .buttonsWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;
    .hintSection {
      width: -webkit-fill-available;
      padding: 8px;
      border-radius: 2px 0px 0px 2px;
      border: 1px solid $gray-7;
      background: rgba(230, 221, 250, 0.48);
      display: flex;
      align-items: center;
      gap: 4px;
      .text {
        text-align: left;
        .hint {
          color: $blue-4;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
    .purchase {
      border: 1px solid $purple-1 !important;
      color: $purple-1 !important;
      background-color: transparent !important;
      padding: 7px 20px;
      display: flex;
      align-items: center;
    }
    .submit {
      border: none;
      padding: 0 20px;
      background: linear-gradient(
        91.81deg,
        #7c44cd 10.66%,
        #b328d4 62.92%,
        #f5b500 104.68%
      );
      border-radius: 8px;
      span {
        display: flex;
        align-items: center;
      }
    }
  }
}
