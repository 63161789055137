@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.required {
  color: $danger-btn-color;
  margin-left: 3px;
}

.inputStyling {
  background: $black-4;
  color: $white-0;
  height: 46px;
  font-family: "Inter";
  width: 100%;
  font-size: 18px;
  border: none;
  @include responsive("smDown") {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &:focus,
  &:active,
  &:focus-visible {
    box-shadow: none;
    color: $white-0;
    outline: none;
    background-color: $black-4;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $white-0;
  }

  &::placeholder {
    color: $gray-20;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    font-family: "Inter";
    @include responsive("smDown") {
      color: $gray-20;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(11%);
}

input[type="date"] {
  color: $white-0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

input[type="date"][value=""]::-webkit-datetime-edit {
  color: $gray-20 !important;
}
:global {
  .pac-container {
    border: none;
    box-shadow: 0px 3px 2px rgba(233, 236, 239, 0.05);
  }
}

.requiredLabel {
  margin-left: 3px;
  color: $white-0;
}

.isInvalid {
  outline: none !important;
  border: 1px solid $warning-btn-color !important;
}

.invalidFeedback {
  text-align: left;
  display: flex;
  color: $warning-btn-color;
  padding: 8px 12px;
  font-size: 14px;
}

.formGroupStyling {
  border: 1px solid $gray-7;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);

  &:focus,
  &:focus-within,
  &:active,
  &:focus-visible {
    outline: 1px solid $gray-7;
    box-shadow: none;
    border: none;
  }
  textarea {
    padding-top: 12px;
  }
}
.color_blue {
  background: $blue-8 !important;
  border-color: rgba(28, 28, 28, 0.60) !important;
  outline: none !important;
  > input, > textarea {
    background: $blue-8 !important;
    border-color: rgba(28, 28, 28, 0.60) !important;
    outline: none !important;
    color: $white-8;
  }
}
.passwordIcon {
  cursor: pointer;
}

.iconStyling {
  background: $black-4;
  border: none;
  border-left: none !important;
  color: $white-0;
  .eyeImage {
    width: 19px;
  }
}
