@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  .userLevel {
    margin-bottom: 32px;

    .freeTrialInfo {
      max-width: 740px;

      .title {
        margin: 16px 0px;
      }

      .list {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        svg {
          margin-right: 8px !important;
        }
      }
    }

    .card {
      padding: 12px;
      padding-left: 0px;
      background: $black-5;
      border-radius: 4px;

      p {
        text-align: left;
      }

      .title {
        &:first-of-type {
          margin-bottom: 12px;
        }

        .freeAccessText {
          color: $white-0 !important;
          font-family: Inter;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }

      .list {
        display: grid;
        width: 60%;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
        @include responsive("mdDown") {
          width: 80%;
        }
        @include responsive("smDown") {
          width: 100%;
          grid-template-columns: 1fr;
        }

        svg {
          margin-right: 8px;
        }
      }

      .levelIncludesText {
        margin-top: 32px;
        margin-bottom: 16px;
      }
    }
  }

  .action {
    span {
      padding: 4px 32px;
      font-size: 32px;
      line-height: 39px;
    }
  }
}
.confirmDrawer {
  padding: 20px 18px;
  text-align: left;
  height: max-content;
  .btnWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .confirmBtn {
      width: max-content;
      span {
        padding: 8px 16px;
      }
    }
  }
}
