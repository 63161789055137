.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  h2 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  &.paddingBottom {
    padding-bottom: 16px;
  }
}
.media {
  width: 100%;
}
.loadingWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}