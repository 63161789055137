.wrapper {
  transition: all 0.2s ease-out;
  &.clicked {
    height: 38px;
    margin-top: -3px;
    line-height: 28px;
    font-size: 20px;
    background: #8053EA;
    backdrop-filter: blur(5.78125px);
    border-radius: 10px;
    svg {
      width: 27px;
      height: 27px;
    }
    > span:nth-child(1) {
      width: 27px;
      height: 27px;
    }
  }
  &.isFree {
    background: transparent;
  }
}