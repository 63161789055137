@import "src/assets/scss/colors";

.wrapper {
  color: $white-0;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 100%;
  > * {
    min-width: 100%;
    max-width: 100%;
  }
}
.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 50px;
  .externalWrapper {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}