.wrapper {
  &.status_verifying {
    position: relative;
    .loading {
      position: absolute;
      left: -35px;
      top: -35px;
      transform: rotate(-3.461deg);
      animation-name: spin;
      animation-duration: 1000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear; 
    }
  }
  &.status_success {
    > svg {
      border: 4px solid #7FD94E;
      border-radius: 50%;
    }
  }
}

@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}