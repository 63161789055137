@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";
.footerWrapper {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  justify-content: space-between;
  padding: 45px;
  max-width: 1440px;
  margin: 0 auto;
  @include responsive("smDown") {
    @include footerMobile();
    .button {
      display: block !important;
      > button {
        display: none;
      }
    }
  }
  .leftSide {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  .copyRight {
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: left;
    color: $white-0;
    margin-bottom: 2px;
  }
  .button {
    color: $white-0;
    span {
      font-family: "Impact";
      font-style: normal;
      font-weight: 400;
      font-size: 36px;
      line-height: 44px;
    }
    @include responsive("smDown") {
      display: none;
    }
    .footerText {
      margin-top: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 32px;
    }
  }
  .footerLinks {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 500;
    > img {
      display: none;
    }
    a {
      text-align: right;
      cursor: pointer;
      color: $white-0;
      @include responsive("smDown") {
        text-align: left;
      }
      &:hover {
        color: $white-0;
        text-decoration: underline;
      }
    }
  }
  .links {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    font-family: "Inter";
    a {
      cursor: pointer;
      color: $white-0;
      text-decoration: underline;
      &:hover {
        color: $white-0;
        text-decoration: none;
      }
    }
    span {
      height: 3px;
      width: 3px;
      border-radius: 50%;
      background: $white-0;
    }
  }
}
