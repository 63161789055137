@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.clueNumber {
  display: flex;
  gap: 4px;
  color: $white-0;
  margin-left: -21px;
  p {
    margin-bottom: 12px;
    text-align: left;
  }
}
.receivedDate {
  color: $white-0;
  p {
    text-align: left;
  }
}