@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.lobster {
  font-family: Lobster-Regular;
  font-weight: 400;
  margin-right: 4px;
  font-style: initial;
}

.wrapper {
  padding-top: 25px;
  padding-bottom: 80px;
  margin-bottom: -100px;
  position: relative;
  .container {
    max-width: 1600px;
    padding-left: 32px;
    padding-right: 32px;
    margin-left: auto;
    margin: 0 auto;
    @include responsive("mdDown") {
      padding-left: 16px;
      padding-right: 16px;
    }
    .title {
      img {
        max-width: 100%;
        width: 1332px;
      }
      p {
        color: $white-0;
        font-size: 75px;
        font-weight: 900;
        line-height: 112px;
        text-shadow: -5px -5px 0 #000, 0 -5px 0 #000, 5px -5px 0 #000,
          5px 0 0 #000, 5px 5px 0 #000, 0 5px 0 #000, -5px 5px 0 #000,
          -5px 0 0 #000;
        -webkit-text-fill-color: $white-0;
        -webkit-text-stroke-color: $black-0;
        @include responsive("smDown") {
          font-size: 32px;
          line-height: 150%;
        }
        @include responsive("xsDown") {
          font-size: 26px;
          line-height: 150%;
        }
      }
    }
    .wishLampImage {
      position: relative;

      .lampImage {
        max-width: 100%;
      }
      .button {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include responsive("smDown") {
          > button {
            width: auto;
          }
        }
        span {
          font-size: 45px;
          line-height: 54px;
          padding: 3px 39px;
        }
      }
    }
    .wishLampText {
      position: relative;
      margin-bottom: 50px;
      .lampTextImage {
        max-width: 100%;
      }
      .lampText {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 74%;
        color: white;
        -webkit-text-fill-color: white;
        text-shadow: -5px -5px 0 #000, 0 -5px 0 #000, 5px -5px 0 #000,
          5px 0 0 #000, 5px 5px 0 #000, 0 5px 0 #000, -5px 5px 0 #000,
          -5px 0 0 #000;
        -webkit-text-stroke-color: black;
        font-size: 75px;
        font-weight: 900;
        line-height: 112px;
      }
    }
    .info {
      position: relative;
      .jinWrapper {
        display: flex;
        justify-content: center;
        .jinImage {
          max-width: 140%;
        }
      }

      .content {
        .cardsContainer {
          margin-top: -185px;
          margin-bottom: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 32px;
          @include responsive("mdDown") {
            flex-direction: column;
          }
          .cardWrapper {
            max-width: 518px;
            border-radius: 20px;
            padding: 8px;
            background: radial-gradient(
                ellipse farthest-corner at right bottom,
                #9f7928 0%,
                #fdb931 8%,
                #9f7928 30%,
                #8a6e2f 40%,
                transparent 80%
              ),
              radial-gradient(
                ellipse farthest-corner at left top,
                #ffffff 0%,
                #ffffac 8%,
                #d1b464 25%,
                #5d4a1f 62.5%,
                #5d4a1f 100%
              );
            .card {
              background-color: black;
              padding: 28px;
              height: 100%;
              border-radius: 20px;
              p {
                color: white;
                margin-bottom: 0;
              }
              .title {
                margin-bottom: 24px;
              }
              .description {
                .yellow {
                  color: $yellow-4;
                }
              }
            }
          }
        }
      }
      .bottomHero {
        @include responsive("smDown") {
          margin-top: 48px;
        }
        p {
          color: white;
          margin-bottom: 0;
          font-size: 45px;
          line-height: 52px;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        button {
          span {
            font-size: 45px;
            line-height: 54px;
            padding: 3px 37px;
          }
          @include responsive("smDown") {
            width: auto;
            span {
              font-size: 28px;
              line-height: 33px;
            }
          }
        }
        .createAccountText {
          margin-bottom: 30px;
          color: $yellow-3;
        }
      }
    }
  }
}
