@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.newsModalWrapper {
  @include responsive("mdDown") {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 48px);
    .modalBody {
      .close {
        top: 4px;
        right: 5px;
      }
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 1185px;
  :global {
    .modal-content {
      background-color: transparent;
    }
  }
  @media (max-height: 800px) and (min-width: 1800px) {
    max-width: 800px;
  }
  .close {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    top: -40px;
    right: 30px;
    cursor: pointer;
  }

  .modalBody {
    border-radius: 8px;
    background: $black-10;

    .content {
      width: 100%;
      background-color: $black-10;
      color: $white-0;
      padding-right: 10px;

      .videoClassName {
        aspect-ratio: 16/9;
      }

      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background: $white-0;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-21;
        border-radius: 8px;
        border: 0.5px solid rgba(255, 255, 255, 0.5);
      }
    }
  }

  .media {
    &.isImage {
      display: flex;
      justify-content: center;
      height: calc(100vh - 315px);
      img {
        border: 3px solid $gray-15;
        height: 100%;
        border-radius: 8px;
        max-width: 100%;
      }
      :global {
        .image-placeholder {
          display: inline-block;
          position: relative;
          font-size: 48px;
          font-weight: 600;
          line-height: 150%;
          > img {
            margin: auto;
          }
          > span {
            @include imageText();
            height: auto;
            position: absolute;
            top: 30px;
            left: 30px;
            bottom: 30px;
            right: 15px;
            text-align: center;
            overflow-x: hidden;
            overflow-y: auto;
          }
        }
      }
    }
  }

  .video {
    position: relative;
    max-width: 100%;
    display: flex;
    height: calc(100vh - 350px) !important;
    min-height: 610px;
    video {
      border: 3px solid $gray-15;
      border-radius: 8px;
      padding: 1px 0;
      // object-fit: fill;
    }
  }
  .info {
    border-radius: 0 0 8px 8px;
    background: $black-10;
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 24px 10px 24px;
    font-weight: 400;
    font-size: 16px;
    flex-wrap: wrap;
    .leftSide {
      .title {
        @include responsive("xsDown") {
          margin-top: 15px;
        }
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
      }
      .middle {
        @include responsive("xsDown") {
          margin-top: 15px;
        }
        margin-top: 5px;
        align-items: flex-start;
        > div {
          display: flex;
          gap: 8px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          &:nth-child(1) {
            // cursor: pointer;
            > span {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              > span {
                &:nth-child(2) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 8px;
      }
    }
    .rightSide {
      .postedAt {
        @include responsive("xsDown") {
          font-size: 14px;
          text-align: left;
        }
        text-align: right;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        margin-top: 20px;
      }
      .socialButtons {
        margin-top: 30px;
        @include responsive("xsDown") {
          font-size: 14px;
          margin-top: 15px;
          gap: 20px;
        }
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 32px;
        .likeCount {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }
}
