.container {
  display: flex;
  flex: 1;
  height: auto;
  max-height: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4;
  border-radius: 8px;
}
.title {
  text-align: center;
  color: #ffffff; 
  font-size: 20px;
  font-weight: 800;
}
.description {
  text-align: center;
  color: #ffffff; 
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 24px;
  white-space: pre-line;
}