@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
.button {
  padding: 16px 24px;
  bottom: 50px;
  width: fit-content;
  background: linear-gradient(97.51deg, #3c3f8f -9.24%, #62036b 119.35%);
  border: 3px solid $purple-7 !important;
  border-radius: 8px;
  font-weight: 700;
  font-size: 32px;
  line-height: normal;
  text-align: center;
}
.container {
  margin: 0 auto;
  @include responsive("mdDown") {
    max-width: 100% !important;
    padding: 0 !important;
  }
  @include responsive("xl") {
    max-width: 92vw !important;
    width: 100%;
  }
  > p {
    font-size: 26px;
    color: $white-0;
    line-height: normal;
    font-weight: 500;
    width: 420px;
    text-align: center;
    position: relative;
    z-index: 2;
    max-width: 100%;
    @include responsive("smDown") {
      width: 100%;
      margin-bottom: 40px;
    }
  }
}
.wrapper {
  padding-top: 60px;
  text-align: left;
  padding-bottom: 60px;
  position: relative;
  background: $black-0;
  &:after {
    content: " ";
    display: block;
    width: 100%;
    height: 200px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    position: absolute;
    bottom: -200px;
    left: 0;
    right: 0;
  }
  &:before {
    content: " ";
    display: block;
    width: 100%;
    height: 70px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.01) 0%, #000 100%);
    position: absolute;
    bottom: -70px;
    left: 0;
    z-index: 10;
    right: 0;
  }
  @include responsive("mdDown") {
    padding: 120px 16px;
    &:after {
      display: none;
    }
  }
}
.buttonWrapper {
  width: 420px;
  max-width: 100%;
  text-align: center;
  position: relative;
  z-index: 2;
  @include responsive("smDown") {
    width: 100%;
  }
}
.icon {
  //left: -130px;
  position: relative;
  margin-bottom: -180px;
  margin-top: 40px;
  max-width: 100%;
  @include responsive("smDown") {
    left: 0;
    width: 100%;
    margin: 0 0 40px 0;
  }
}
.background {
  position: absolute;
  top: 0;
  width: calc(100% - 530px);
  bottom: 0;
  right: 0;
  height: calc(100% - 30px);
  max-width: 100%;
  &:before,
  &:after {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  &:before {
    top: 0;
    width: 400px;
    max-width: 100%;
    height: 693px;
    background: linear-gradient(90deg, #000 0.08%, rgba(0, 0, 0, 0) 85.83%),
      linear-gradient(36deg, #000 24.71%, rgba(0, 0, 0, 0) 62.09%);
  }
  &:after {
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    height: 100px;
  }
  @include responsive("smDown") {
    left: 0;
    width: 100%;
    height: 100%;
    &:before {
      display: none;
    }
  }
}
