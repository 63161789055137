@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.questionModalWrapper {
  max-width: 1169px;
  @media (max-height: 800px) and (min-width: 1800px) {
    max-width: 800px;
  }
  .modalBody {
    background: $blue-4;

    .header {
      color: $white-0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 32px;
      .rightSide {
      }
      .close {
        cursor: pointer;
      }
    }
    .questionWrapper {
      max-height: calc(100vh - 220px);
      overflow: auto;
      padding-right: 10px;

      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background: $white-0;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: $purple-0;
        border-radius: 8px;
        border: 0.5px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
  &.customGame {
    max-width: 900px;
  }
}
