@import "src/assets/scss/colors";

.wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  > div {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > div {
      width: 100%;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  > span {
    width: 132px;
    height: 132px;
    background: $blue-8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    line-height: 32px;
  }
  > button {
    width: 140px;
    margin: 0 auto;
    border-radius: 8px;
    padding: 8px 32px;
    height: 44px;
    > span {
      font-size: 24px;
      font-weight: 700;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 621px;
    margin: 0 auto;
    font-size: 12px;
    font-style: normal;
    gap: 8px;
    font-weight: 400;
  }
}