@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  .mapWrapper {
    width: 100%;
    text-align: center;
  }
  .content {
    margin-bottom: 20px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 16px;
    .proceedButton {
      background: linear-gradient(115deg, #8053ea 1.53%, #3835a0 138.07%);
      span {
        color: $white-0;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 20px;
      }
    }
    .tryAgainButton {
      border-radius: 8px;
      background: transparent !important;
      border: 0.93px solid $purple-1;
      span {
        color: $white-0;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 20px;
      }
    }
  }
}
