@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.homeWrapper {
  position: relative;
  background-image: url("../../../src/assets/images/landing-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(
        120deg,
        #0a021d 27.21%,
        rgba(14, 0, 47, 0) 64.08%
      ),
      linear-gradient(
        358deg,
        #090015 0.1%,
        rgba(14, 0, 31, 0.86) 19.79%,
        rgba(27, 0, 61, 0) 85.93%
      );
    z-index: 0;
  }
  .container {
    max-width: 1440px;
    z-index: 1;
    width: 100%;
    padding: 88px;
    @include responsive("mdDown") {
      max-width: 100% !important;
      padding: 30px !important;
    }
  }
}
.homeRegionsWrapper {
  h3 {
    font-size: 42px;
    font-weight: 700;
    line-height: 48px;
    color: white;
    margin: 0 0 16px 0;
  }
}
.regionTable {
  margin-top: 80px;
}
