@import "src/assets/scss/colors";

.selectStyling {
  position: relative;
  .leftIcon {
    position: absolute;
    top: 12px;
    left: 15px;
    z-index: 1;
  }

  .rightIcon {
    position: absolute;
    top: 12px;
    right: 15px;
    z-index: 1;
  }

  &.hasLeftIcon {
    :global {
      .rselect__control {
        padding-left: 35px;
      }
    }
  }
  &:focus-visible {
    outline: none;
  }
  .reactSelect {
    &:focus-visible {
      outline: none;
    }
  }
  :global {
    .rselect__menu {
      z-index: 20;
      background-color: $black-4;
      border: 1px solid $gray-7;
    }
    .rselect__input-container {
      color: $white-0;
    }
    .rselect__control {
      border: 1px solid $gray-7;
      border-radius: 4px;
      font-size: 16px;
      padding: 5px 1px;
      color: $white-0;
      background-color: $black-4;

      &:hover {
        border-color: $yellow-6;
      }

      &.rselect__control--is-focused {
        box-shadow: none;
        border-color: $white-4;
      }
    }

    .rselect__placeholder {
      color: $input-placeholder-color;
      font-family: "Inter";
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
    .rselect__single-value {
      color: $white-0;
    }
    .rselect__option {
      color: $white-0;
      background-color: $black-4;
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }

    .rselect__option--is-focused {
      background-color: $black-4;
      color: $white-4;
    }

    .rselect__option--is-selected {
      background-color: $black-4 !important;
      color: $white-0 !important;
    }

    .rselect__option:hover {
      background-color: $yellow-6;
      color: $black-4;
    }

    .rselect__indicator {
      padding: 0 2px;
    }

    .rselect__indicator-separator,
    .rselect__dropdown-indicator {
      display: none;
    }
    .rselect__indicators {
      margin-right: 20px;
    }
  }
  &.color_blue {
    :global {
      .rselect__control {
        background: $blue-8;
        border-color: rgba(28, 28, 28, 0.6);
        outline: none !important;
      }
      .rselect__menu-list, .rselect__menu {
        background: $blue-8;
        border-color: rgba(28, 28, 28, 0.6);
      }
      .rselect__option {
        background: $blue-8;
        font-size: 14px;
        font-weight: 400;
        &:hover, &.rselect__option--is-selected {
          color: $white-0;
          background: $black-6 !important;
        }
      }
    }
  }
}

.isInvalid {
  border-radius: 4px;
  border: 1px solid $warning-btn-color;
  outline: none;
  :global {
    .rselect__control {
      border: none;
    }
  }
}
label {
  font-family: "Inter";
}

.invalidFeedback {
  text-align: left;
  display: flex;
  color: $warning-btn-color;
  font-size: 14px;
  padding: 8px 12px;
}
.helperText {
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  margin-top: 12px;
}