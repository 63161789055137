@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  width: 100%;
  margin: 0 auto 120px;
  background-color: $black-0;
  padding: 24px 0px 45px 0px;
  max-width: 1270px;
  padding: 16px;
  .header {
    display: flex;
    margin-bottom: 8px;
  }
  .form {
    .cardsWrapper {
      .card {
        text-align: left;
        border-radius: 4px;
        background: $black-5;
        width: 100%;
        margin-bottom: 19px;
        padding: 18px;
        color: $white-0;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 32px;
      .submit {
        span {
          padding: 4px 32px;
          font-size: 32px;
          line-height: 39px;
        }
      }
      .cancelWrapper {
        span {
          text-decoration: none !important;
        }
        .cancel {
          color: $white-0;
        }
      }
    }
  }
}
