@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.navWrapper {
  height: 100px;
  position: absolute;
  width: 100%;
  z-index: 1;

  .wrapper {
    padding: 22px 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $black-12;
    @include responsive("smDown") {
      max-width: 100vw;
    }

    .logoWrapper {
      max-height: 35px;
      max-width: 360px;
      display: flex;
      align-items: center;
      justify-content: center;

      .titleImage {
        cursor: pointer;
        height: 26px;
      }
    }

    .btnWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      @include responsive("smDown") {
        display: none;
      }

      .divider {
        height: 20px;
        border-right: 3px solid $white-0;
      }

      .homeButton {
        span {
          font-family: "Poppins-SemiBold";
          color: $white-0 !important;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
        }
      }

      .signUpButton {
        span {
          font-family: "Poppins-SemiBold";
          color: $white-0 !important;
          font-weight: 400;
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
}
