@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  color: $white-0;
  :global {
    .StripeElement {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      padding: 12px 14px;
      font-size: 1em;
      border: 1px solid $white-4;
      color: $black-0 !important;
      outline: 0;
      border-radius: 4px;
      border-radius: 4px;
      background: $white-3;
    }
    .inputFieldStripe {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      padding: 12px 14px;
      font-size: 1em;
      border: 1px solid $white-4;
      color: $black-0 !important;
      outline: 0;
      border-radius: 4px;
      border-radius: 4px;
      background: $white-3;
    }
    .stripeError {
      display: block;
      width: 100%;
      margin-bottom: 8px;
      padding: 12px 14px;
      font-size: 1em;
      color: $black-0 !important;
      outline: 0;
      border-radius: 4px;
      border-radius: 4px;
      background: $white-3;
      border: 1px solid $warning-btn-color;
    }
    .StripeElement--invalid {
      border: 1px solid $warning-btn-color;
    }
  }

  @include responsive("smDown") {
    margin-top: 16px;
  }
  .inputs {
    height: calc(100%);
    margin: 0 auto;
    .formWrapper {
      height: calc(100%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .buttonWrapper {
        text-align: center;
        .button {
          border: none;
          padding: 4px 45px;
          background: $purple-1;
          border-radius: 8px;
          span {
            display: flex;
            align-items: center;
          }
        }
      }
      .label {
        @include responsive("smDown") {
          margin-bottom: 8px !important;
        }
      }
      label {
        color: $white-0;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @include responsive("smDown") {
          margin-bottom: 8px !important;
        }
      }
      .formGroupInput {
        .label {
          color: $white-0;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .inputGroup {
          background: $white-3;
          input {
            background: $white-3;
            color: $black-0;
          }
        }
        @include responsive("smDown") {
          margin-bottom: 24px !important;
        }
      }

      .selectWrapper {
        text-align: left;
        .inputGroup {
          background: $white-3;
          input {
            background: $white-3;
            color: $black-0;
          }
        }
        .select {
          :global {
            .rselect__placeholder {
              color: $input-placeholder-color;
              font-family: "Inter";
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
            .rselect__single-value {
              color: $black-0 !important;
            }
            .rselect__input-container {
              color: $black-0 !important;
              .rselect__input {
                color: $black-0;
              }
            }
            .rselect__control {
              border: 1px solid $gray-7;
              border-radius: 4px;
              font-size: 16px;
              padding: 5px 1px;
              color: $black-0;
              background: $white-3;
            }
          }
        }
        @include responsive("smDown") {
          margin-bottom: 24px !important;
          :global {
            .rselect__placeholder {
              color: $input-placeholder-color;
              font-family: "Inter";
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }

      .address {
        display: grid;
        gap: 28px;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 4px;
        @include responsive("smDown") {
          gap: 0px;
          grid-template-columns: 1fr;
        }
      }
      .stripeInputs {
        display: grid;
        gap: 28px;
        grid-template-columns: 1fr 1fr;
        @include responsive("smDown") {
          grid-template-columns: 1fr;
          gap: 8px;
        }
        .cardNumber {
          text-align: left !important;
        }
        .expiryAndCvc {
          display: grid;
          gap: 28px;
          grid-template-columns: 1.5fr 1fr;
          text-align: left !important;
          margin-bottom: 8px;
          @include responsive("smDown") {
            grid-template-columns: 1fr;
            gap: 8px;
          }
        }
        .errorMessage {
          font-size: 14px;
          color: $warning-btn-color;
          text-align: left;
        }
      }
    }
  }
  .form {
    .paymentDetails {
      .cvcExpiry {
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-gap: 18px;
      }
    }
  }
}

.divider {
  opacity: 1;
  border-top: 1px solid $white-0;
}
