.wrapper {
  display: flex;
  align-items: center;
}
.searchInactive {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
}
.searchActive {
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  :global {
    .input-group {
      border: 1px solid #E0E0E0 !important;
      outline: none !important;
      border-radius: 16px;
      height: 32px;
      position: relative;
      z-index: 1;
      background: white;
      input {
        color: black;
        border-radius: 16px;
        height: 32px;
        width: 412px;
        font-size: 14px;
        padding-right: 54px;
        border: 0;
        background: transparent;
      }
    }
  }
}
.closeIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 50px;
  top: 4px;
  z-index: 2;
  width: 24px;
  height: 24px;
  svg {
    width: 18px;
    height: 18px;
  }
}
.searchIcon {
  display: inline-flex;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 4px;
  z-index: 2;
  svg {
    width: 24px;
    height: 24px;
    circle, path {
      stroke: #BDBDBD;
    }
  }
  &:before {
    content: ' ';
    display: block;
    position: absolute;
    right: 32px;
    top: 1px;
    bottom: 1px;
    width: 1px;
    border-right: 1px solid #4F4F4F;
  }
}