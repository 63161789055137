@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.navWrapper {
  height: 70px;
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 0;
  background: $black-0;
  @include responsive("smDown") {
    background: transparent;
  }

  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: $black-12;
    padding: 0 30px;
    // max-width: 88vw;
    @include responsive("smDown") {
      padding: 0 24px;
    }
    margin: 0 auto;
    &.aboutWrapper {
      background: $black-0;
    }
    .signInButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      span {
        font-family: Inter;
        color: $white-0 !important;
        font-weight: 600;
        font-size: 18px;
        line-height: 29px;
        @include responsive("smDown") {
          font-size: 20px;
          line-height: 26px;
        }
      }
      &.aboutButton {
        span {
          font-size: 24px;
        }
      }
      .dropdownText {
        font-family: Inter;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        svg {
          height: 24px;
          width: 24px;
        }
      }
      .divider {
        height: 40px;
        border-right: 1px solid $white-0;
      }
    }
    .userDropdown {
      top: 0;
      right: 0;
      @include responsive("smDown") {
        top: -5px;
        right: -5px;
      }
    }
    .desktopLogo {
      margin-right: auto;
      img {
        height: 26px;
        @include responsive("smDown") {
          height: 29px;
        }
      }
    }
  }
}

.dropdown {
  .bellButton {
    background-color: transparent;
    border: none;
  }
  .menu {
    width: fit-content;
    border-radius: 3px;
    border: 1px solid $gray-7;
    background: $black-4;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .item {
      display: flex;
      align-items: center;
      gap: 5px;
      &:hover {
        background-color: inherit;
      }
      img {
        height: 20px;
        width: 22px;
      }
    }
  }
}
