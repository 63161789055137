@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  border: 5px solid $white-7;
  border-radius: 8px;
  text-align: center;
  aspect-ratio: 16/9;
  :global {
    .react-player__preview {
      background-repeat: no-repeat;
      background-size: contain !important;
    }
  }
  .playOverlay {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 16 / 9 * 100vh;
    &:after {
      content: "";
      display: block;
      padding-bottom: (9 / 16) * 100%;
    }
  }
  &.modalQuestionWrapper {
    text-align: center;
    aspect-ratio: 16/9;
    max-height: calc(100vh - 250px);
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  img {
    max-width: 100%;
  }
}
