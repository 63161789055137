@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 4fr 2fr;
}
.noData {
  color: $white-0;
  padding: 20px;
}
.freeCompleted {
  border-right: 2px solid $gray-9;
  padding-top: 32px;
  padding-bottom: 68px;
  margin-bottom: 32px;

  .icon {
    margin-bottom: 50px;
    > img {
      max-width: 332px;
      max-height: 174px;
    }
  }
  .completedText {
    margin: 0 auto;
    max-width: 591px;
    color: $white-0;
    font-family: "Inter";
    font-size: 34px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 34px;
  }
  .message {
    max-width: 591px;
    margin: 0 auto;
    color: $white-0;
    font-family: "Inter";
    font-size: 22px;
    line-height: normal;
    margin-bottom: 32px;
    > span {
      font-weight: 700;
    }
  }
  .upgradeButton {
    margin: 0 auto;
    max-width: 457px;
    padding: 7px 0px !important;
  }
}
