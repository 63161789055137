@import "src/assets/scss/colors";

.wrapper {
  .description {
    margin-bottom: 24px;
  }
  .content {
    display: grid;
    grid-template-columns: 9fr 5fr;
    gap: 24px;
    .details {
      .infoWrapper {
        padding: 24px 16px 48px 16px;
        background: $white-0;
        border-radius: 8px;
        .text {
          margin-bottom: 12px;
          line-height: 19px;
          font-weight: 500;
        }
        .info {
          gap: 12px;
          display: flex;
          justify-content: center;
          .example {
            text-align: center;
            img {
              max-width: 100%;
            }
            > p {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    .buyActions {
      .actions {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
        .button {
          padding: 14px 24px;
          border-radius: 8px;
          border: none;
          background: linear-gradient(115deg, #8053ea 1.53%, #3835a0 138.07%);
          &:global(.disabled) {
            background: #797979;
            p {
              color: #333333;
            }
          }
        }
        .availableOn {
          font-style: italic;
          padding-left: 35px;
        }
        .alreadyPurchased {
          font-style: italic;
          padding-left: 5px;
        }
      }
    }
  }
}
.detailsBlockInner {
  background: white;
  border-radius: 8px;
  padding: 22px 32px;
  color: #121429;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 28px;
  > div {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    b {
      font-weight: 700;
    }
    ul {
      margin: 0;
    }
  }
  :global {
    .how-it-works {
      ul {
        list-style: decimal;
        padding-left: 22px;
      }
    }
    .distance-availability {
      ul {
        padding-left: 12px;
        list-style: none;
      }
    }
    .additional-information {
      ul {
        padding-left: 22px;
      }
    }
  }
}