@import "src/assets/scss/colors";

.wrapper {
  margin-bottom: 20px;
  .mapWrapper {
    position: relative;
    border-radius: 8px;
    background: $black-7;
    backdrop-filter: blur(5px);
    .map {
      padding: 50px;
      width: 80%;
    }
  }
}
