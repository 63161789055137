@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.viewWrapper {
  padding: 20px 0px;
  .filter {
    margin-bottom: 4px;
    text-align: right;
    :global {
      .dropdown-menu.show {
        padding: 0;
        // transform: translate(991px, 40px) !important;
      }
    }
    .filterButton {
      background-color: transparent;
      border: none;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      gap: 15px;
      &:hover {
        background-color: $purple-1;
      }
    }
    .filterButtonActive {
      background-color: $purple-1;
      border: none;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      gap: 15px;
    }
  }
  .cardsWrapper {
    padding-right: 8px !important;
    height: calc(100vh - 516px);
    overflow-y: auto;
    &.disable {
      pointer-events: none;
      opacity: 0.6;
    }
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 8px;
      margin-left: 3px;
    }

    &::-webkit-scrollbar-track {
      background: $white-3;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: $gray-14;
      border-radius: 8px;
    }
    .cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 24px;
      @media only screen and (max-width: 1300px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media only screen and (max-width: 1050px) {
        grid-template-columns: 1fr 1fr;
      }
      .noData {
        color: $white-0;
        padding: 20px;
        grid-column-start: 2;
      }
    }
  }
}
