@import "src/assets/scss/colors";

.checkboxStyling {
  display: flex;
  align-items: flex-start;
  .outline {
    box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  }

  input {
    height: 16px;
    width: 16px;
    border: 1px solid $white-0;
    box-shadow: none;
    border-radius: 2px;
    cursor: pointer;
    margin-top: 0;
    background-color: $black-4;

    &:focus {
      border: 1px solid $white-0;
    }
  }

  input[type="checkbox"] + label {
    cursor: pointer;
  }

  :global {
    .form-check-input:checked {
      filter: invert(1);
      background-color: $black-4;
      border: none;
    }
  }
  &.radio {
    :global {
      .form-check-input:checked {
        filter: none;
        background-color: transparent;
        border: 2px solid $purple-1;
        position: relative;
        &:before {
          content: " ";
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background: $purple-1;
          position: absolute;
          left: 3px;
          top: 3px;
        }
      }
    }
  }
}

.invalidFeedback {
  display: flex;
  color: $warning-btn-color;
  padding: 8px 12px;
}
