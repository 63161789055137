@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.layoutWrapper {
  display: flex;
  min-height: 100vh;
  .wrapper {
    width: 100%;
    height: 100%;
    .playContainer {
      display: flex;

      .container {
        width: calc(100% - 230px);
        padding: 16px 16px 16px 24px;
        transition: width 0.3s ease;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        &.collapsed {
          width: calc(100% - 75px);
        }
      }
    }
  }
}

.upgradeContainer {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
  padding: 7px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 47px;
  gap: 64px;
  .title {
    color: #b99dfa;
    font-family: "Poppins-SemiBold";
    font-size: 22px;
  }
  .clueInfoWrapper {
    color: #b99dfa;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    .openClue {
      font-family: "Poppins-SemiBold";
      font-size: 30px;
    }
    .totalClue {
      font-family: "Poppins-Regular";
      font-size: 16px;
      margin-left: 6px;
    }
  }
  .upgradeButton {
    padding: 5px 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
