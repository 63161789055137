@import "src/assets/scss/colors";

.headerText {
  cursor: pointer;
  display: flex;
  width: fit-content;
  padding: 4px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid $white-0;
  // background: linear-gradient(158deg, #7c44cd 0%, #fe00dc 100%);
  background: linear-gradient(94deg, #7C44CD 46.64%, #FE00DC 154.68%);
  margin-bottom: 12px;
  > p {
    line-height: normal;
  }
}
