@import "src/assets/scss/colors";

.thumb {
  width: 10px;
  height: 10px;
  background: $white-0;
  color: $white-0;
  border-radius: 50%;
  cursor: pointer;
  border-color: $white-0;
  box-shadow: none;
  outline: none !important;
}
.track {
  height: 10px;
  border-radius: 6px;
}
.slider {
  position: relative;
  z-index: 2;
  > .track {
    &:nth-child(1) {
      background: $blue-4;
    }
    &:nth-child(2) {
      border-radius: 6px;
      background: $white-3;
      left: 0 !important;
    }
  }
  &.loadedSlider {
    position: absolute !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    > .track {
      &:nth-child(1) {
        background: $gray-22;
      }
      &:nth-child(2) {
        background: $white-3;
      }
    }
    .thumb {
      display: none !important;
    }
  }
}
.wrapper {
  width: 100%;
  position: relative;
  &.hideThumb {
    .thumb {
      background: transparent;
    }
    .slider {
      > .track {
        &:nth-child(1) {
          z-index: 2;
        }
      }
    }
    &.displayLoaded {
      .slider {
        &:not(.loadedSlider) {
          > .track {
            &:nth-child(1) {
              margin-left: -2px !important;
              margin-right: -1px !important;
            }
          }
        }
      }
    }
    &:hover, &:active, &:visited {
      .thumb {
        background: $white-0;
      }
    }
  }
  &.displayLoaded {
    .slider:not(.loadedSlider) {
      > .track {
        &:nth-child(2) {
          background: transparent;
        }
      }
    }
  }
  &.isEmpty {
    .slider {
      > .track {
        &:nth-child(1) {
          max-width: 0px;
        }
      }
    }
  }
}