@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.details {
  right: 30px;
  top: 30px;
  position: absolute;
  color: $white-0;
  display: flex;
  gap: 22px;
  background: $purple-5;
  border-radius: 10px;
  padding: 13px 21px;
  flex-wrap: wrap;
  margin-bottom: 36px;
  @include responsive("mdDown") {
    gap: 24px;
    flex-direction: column;
    position: relative;
    right: 0;
    margin: 0 16px;
  }
  .detail {
    display: flex;
    align-items: center;
    gap: 11px;
    .imageWrapper {
      width: 53px;
    }
    div {
      p {
        text-align: left;
      }
    }
  }
}
