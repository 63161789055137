@import "src/assets/scss/colors";

.closeModalWrapper {
  max-width: 750px;
  .modalBody {
    max-width: 490px;
    margin: 0 auto;
  }
  .modalHeader {
    text-align: center;
    margin-bottom: 40px;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    .submit {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
    .cancelWrapper {
      span {
        text-decoration: none !important;
      }
    }
  }
  :global {
    .modal-content {
      border-radius: 4px;
      background: $black-4;
      color: $white-0;
      // min-height: calc(100vh - 300px);
      // max-height: calc(100vh - 80px);
      > div {
        padding: 30px 32px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
      }
    }
    .modal-header {
      padding: 0;
      border: 0;
      .modal-title {
        display: flex;
        gap: 16px;
        > span {
          margin: 0;
          display: flex;
          gap: 16px;
          align-items: flex-end;
          font-size: 30px;
          & + span {
            font-size: 16px;
            font-weight: 400;
            position: relative;
            top: -5px;
            color: $gray-16;
          }
        }
        & + span {
          color: $white-0;
          svg {
            path {
              fill: $white-0;
            }
          }
        }
      }
    }
  }
}

.wrapper {
  .cardWrapper {
    margin-bottom: 16px;
    .deviceCard {
      border-radius: 4px;
      border: 1px solid $white-0;
      background: $black-5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 18px;
      margin-bottom: 10px;
    }
  }
}
