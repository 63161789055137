@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding: 0 16px;
  .content {
    color: $white-0;
    font-size: 16px;
    max-width: 448px;
    margin: 0 auto;
    .lampImage {
      margin-bottom: 8px;
      width: 180px;
      height: auto;
      margin-bottom: 16px;
    }
    .title {
      margin-bottom: 20px;
      line-height: 29px;
      color: $white-0;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }
    .subTitle {
      margin-bottom: 24px;
      line-height: 24px;
      text-align: left;
    }
    .subTitle1 {
      text-align: left;
    }
    .seasons {
      margin-bottom: 24px;
      p {
        margin-bottom: 0px;
      }

      .seasonCard {
        min-height: 46px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        border-radius: 4px;
        background: rgba(45, 45, 45, 0.6);
        margin-bottom: 16px;
        .seasonTitle {
          color: $white-0;
          border-radius: 4px 0px 0px 4px;
          background: $black-5;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .dates {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 1px 1fr;
          padding: 16px;
          .divider {
            height: 10px;
            width: 1px;
            background: $gray-15;
          }
          .date {
            color: $yellow-7;
            .label {
              color: $white-0;
              margin-bottom: 6px;
            }
          }
        }
      }
    }
    .bottomContent {
      text-align: left;
      margin-bottom: 24px;
      p {
        line-height: 24px;
      }
      @include responsive("smDown") {
        margin-bottom: 70px;
      }
    }
  }
  .subscriptionCards {
    color: $white-0;
    margin-bottom: 24px;
    .cardsWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 27px;
      max-width: 710px;
      margin: 0 auto;
    }
    .error {
      margin-top: 8px;
      margin-bottom: 0;
      color: $orange-0;
      text-align: left;
    }
  }
  .buttonWrapper {
    @include responsive("smDown") {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $black-4;
      box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
      padding: 12px 24px 34px 24px;
      z-index: 10;
      button {
        position: relative;
        span {
          font-size: 18px;
          line-height: normal;
        }
      }
      span {
        font-size: 16px;
      }
    }
    button {
      width: 100%;
    }
    span {
      font-size: 32px;
      line-height: 39px;
    }
  }
}
