@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding: 45px 16px 60px 16px;
  margin: 70px 0px;
  border-radius: 80px;
  border: 5px solid $purple-11;
  background: rgba(15, 0, 48, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  @include responsive("lgDown") {
    margin: 30px 0px;
    padding: 20px 16px 30px 16px;
  }
  .container {
    max-width: 1440px;
    margin: 0 auto;
    @include responsive("smDown") {
      padding: 40px 16px 43px;
    }
    .title {
      margin-bottom: 32px;
      > p {
        font-family: "Poppins-Bold" !important;
        font-size: 56px !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: normal !important;
        text-transform: uppercase !important;
        background: linear-gradient(180deg, #fff 11.46%, #b872ff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include responsive("smDown") {
          font-size: 40px !important;
        }
      }
      @include responsive("smDown") {
        margin-bottom: 0px;
      }
      p {
        font-family: "Milonga";
        font-style: normal;
        font-weight: 400;
        font-size: 50px;
        line-height: 49px;
        color: $white-0;
        @include responsive("smDown") {
          font-size: 32px;
        }
      }
    }
    .stepContainer {
      display: flex;
      justify-content: center;
      gap: 88px;
      margin-bottom: 50px;
      flex-wrap: wrap;
      @include responsive("smDown") {
        gap: 44px;
      }
      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        max-width: 250px;
        .iconContainer {
          .iconWrapper {
            display: flex;
            width: 88px;
            height: 88px;
            padding: 16px;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            background: linear-gradient(257deg, #570077 1.08%, #2d0048 105.53%),
              rgba(163, 45, 255, 0.3);
            box-shadow: -4px -4px 4px 0px rgba(18, 4, 49, 0.3) inset,
              3px 3px 4px 0px rgba(169, 74, 193, 0.1) inset;
            backdrop-filter: blur(30px);
          }
        }
        .textWrapper {
          display: flex;
          flex-direction: column;
          gap: 8px;
          .header {
            color: $white-10;
            text-align: center;
            font-family: "Poppins-Bold";
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 116%; /* 27.84px */
            text-transform: uppercase;
          }
          .description {
            color: $white-13;
            text-align: center;
            font-family: "Poppins-Regular";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 116%; /* 18.56px */
          }
        }
      }
    }
    .buttonWrapper {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      .joinButton {
        display: flex;
        padding: 16px 85px;
        justify-content: center;
        align-items: center;
        border-radius: 93px;
        border: 2px solid $pink-1;
        background: linear-gradient(257deg, #7500a0 1.08%, #2d0048 105.53%);
        span {
          color: $white-0;
          font-family: "Poppins-Bold";
          font-size: 18px;
          line-height: normal;
          text-transform: uppercase;
        }
      }
      .trialButton {
        background-color: transparent !important;
        > span {
          color: $white-0;
          font-family: "Poppins-Semibold";
          font-size: 18px;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }
  }
}
