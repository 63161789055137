@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  margin: 0 auto;
  gap: 18px;

  .dashboardWrapper {
    background-color: $blue-4;
    border-radius: 8px;
    padding: 27px 20px 0 20px;
  }
}
