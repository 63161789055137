@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.contentRight {
  display: flex;
  align-items: center;
  gap: 24px;
  color: $white-0;
  button {
    border-radius: 4px;
    font-size: 24px;
    font-weight: 600;
    height: 52px;
  }
  span {
    font-size: 24px;
    font-weight: 600;
    margin: 0 12px;
  }
}
.header {
  border-bottom: 1px solid $gray-8;
  > div {
    > div {
      > div {
        border-bottom: 0 !important;
      }
    }
  }
  @include responsive("mdDown") {
    > div {
      > div {
        &:nth-child(2) {
          padding-right: 0;
        }
      }
    }
  }
  @include responsive("smDown") {
    padding-left: 30px;
    height: 72px;
    > div {
      > div {
        &:nth-child(1) {
          > div {
            &:nth-child(1) {
              display: none !important;
            }
            &:nth-child(2) {
              p {
                font-size: 16px !important;
                font-weight: 600 !important;
              }
            }
          }
        }
      }
    }
  }
}