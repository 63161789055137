@import "src/assets/scss/colors";

.wrapper {
  .mainCard {
    border-radius: 10.676px;
    background: $blue-4;
    padding: 16px;
    margin-right: 32px;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;
    }
    .cardsWrapper {
      display: grid;
      grid-template-columns: 48% 48%;
      gap: 16px;
      min-height: 622px;
      .card {
        .image {
          width: 100%;
          border-radius: 6.026px;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
