@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  display: flex;
  justify-content: center;
  padding: 0 16px;
}
.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1060px;
  border-radius: 30px;
  background: #0F0030B2;
  position: relative;
  color: white;
  padding: 24px 56px;
  &:before {
    z-index: 1;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 30px; 
    padding: 5px; 
    background: linear-gradient(108.29deg, #6100C1 5.11%, #C843F8 97.4%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
  }
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: white;
    margin: 0 0 8px 0;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 2;
    li {
      display: flex;
      padding: 45px 0;
      border-top: 1px solid #C843F866;
      align-items: center;
      gap: 0px;
      font-size: 24px;
      line-height: 28px;
      text-align: left;
      &:first-child {
        border-top: 0;
      }
      > * {
        &:nth-child(1) {
          width: 150px;
          margin-right: 40px
        }
        &:nth-child(2) {
          width: 120px;
          margin-right: 60px;
        }
        &:nth-child(3) {
          flex: 1;
          margin-right: 16px;
        }
        &:last-child {
          margin-left: auto;
        }
      }
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  position: relative;
  z-index: 2;
  .prev {
    margin-right: 12px;
  }
  .next {
    margin-left: 12px;
  }
  > * {
    cursor: pointer;
  }
  > span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    &.active, &:hover {
      background: #E5C85F;
    }
    &.active {
      cursor: default;
    }
  }
  > svg {
    width: 24px;
    height: 24px;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
.price {
  display: flex;
  gap: 5px;
  font-weight: 400;
  flex-wrap: wrap;
  b {
    font-weight: 600;
  }
}

@include responsive("mdDown") {
  .inner {
    padding: 12px 24px;
  }
}
@include responsive("smDown1") {
  .inner {
    ul {
      li {
        font-size: 18px;
        > * {
          &:nth-child(1) {
            margin-right: 20px;
          }
          &:nth-child(2) {
            width: 90px;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
@include responsive("smDown") {
  .inner {
    ul {
      li {
        flex-wrap: wrap;
        gap: 12px;
        > * {
          margin: 0 !important;
          &:nth-child(1) {
            min-width: calc(60% - 6px) !important;
            max-width: calc(60% - 6px) !important;
          }
          &:nth-child(2) {
            min-width: calc(40% - 6px) !important;
            max-width: calc(40% - 6px) !important;
          }
          &:nth-child(3) {
            min-width: calc(60% - 6px) !important;
            max-width: calc(60% - 6px) !important;
          }
          &:nth-child(4) {
            min-width: calc(40% - 6px) !important;
            max-width: calc(40% - 6px) !important;
          }
        }
      }
    }
  }
}
@include responsive("xsDown") {
  .inner {
    ul {
      li {
        > * {
          &:nth-child(1) {
            min-width: calc(100% - 102px) !important;
            max-width: calc(100% - 102px) !important;
          }
          &:nth-child(2) {
            min-width: 90px !important;
            max-width: 90px !important;
          }
          &:nth-child(3) {
            min-width: 100% !important;
            max-width: 100% !important;
          }
          &:nth-child(4) {
            min-width: 100% !important;
            max-width: 100% !important;
          }
        }
      }
    }
  }
}