@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.wrapper {
  position: relative;
  table {
    margin-bottom: 0;
    color: $white-8;
    thead {
      tr {
        border: 0;
        background: $blue-8;
        border-bottom: 0.5px solid $white-9;
        th {
          border: 0;
          padding: 16px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    tbody {
      tr {
        border: 0;
        border-bottom: 0.5px solid $white-9;
        &:hover {
          background: $blue-8;
        }
        td {
          border: 0;
          padding: 16px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
  &.hasLoading {
    table {
      tbody {
        tr {
          td {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
.loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.columnHasSort {
}
.sortIcon {
  width: 16px;
  height: 16px;
  position: relative;
  top: -1px;
  display: inline-block;
  margin-left: 5px;
}