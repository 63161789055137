@mixin beautyScrollbar() {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a8a8a8;
    border-radius: 8px;
  }
}
@mixin tooLongText($line: 1) {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
}
@mixin ellipsisLongText() {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin mediaBackground() {
  &:before {
    position: absolute;
    content: " ";
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.00) 46.02%, rgba(0, 0, 0, 0.53) 100%);
  }
}
@mixin footerMobile {
  align-items: center;
  gap: 32px;
  flex-direction: column;
  padding: 34px 45px 69px;
  > div {
    &:nth-child(1) {
      order: 2;
      > img {
        display: none;
      }
      > * {
        justify-content: center;
      }
    }
    &:nth-child(2) {
      order: 0;
    }
    &:nth-child(3) {
      order: 1;
      align-items: center;
      > img {
        display: block;
        margin-bottom: 6px;
        margin-top: 40px;
      }
      > a {
        &:nth-of-type(1) {
          order: 1;
        }
        &:nth-of-type(2) {
          order: 5;
        }
        &:nth-of-type(3) {
          order: 0;
        }
        &:nth-of-type(4) {
          order: 4;
        }
        &:nth-of-type(5) {
          order: 3;
        }
      }
    }
  }
}
@mixin imageText() {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  position: relative;
  z-index: 2;
  padding-right: 15px;
  @include beautyScrollbar();
}