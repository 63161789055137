@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.postTag {
  display: inline-flex;
  border-radius: 4px;
  border: 1px solid $white-0;
  font-size: 12px;
  font-weight: 600;
  line-height: 150%;
  padding: 4px 8px;
}
.wrapper {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}