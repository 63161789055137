@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding: 0 16px;
  max-width: 739px;
  margin: 0 auto;
  .content {
    color: $white-0;
    font-size: 16px;
    // max-width: 448px;
    margin: 0 auto;
    .lampImage {
      margin-bottom: 8px;
      width: 180px;
      height: auto;
    }
    .title {
      margin-bottom: 20px;
      line-height: 29px;
    }
    .subTitle {
      max-width: 492px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 24px;
      line-height: 19px;
    }
    .seasons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
      margin-bottom: 24px;
      padding-left: 10px;
      padding-right: 10px;
      p {
        margin-bottom: 0px;
      }

      .seasonCard {
        min-height: 46px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        border-radius: 4px;
        background: rgba(45, 45, 45, 0.6);
        margin-bottom: 16px;
        .seasonTitle {
          color: $white-0;
          border-radius: 4px 0px 0px 4px;
          background: $black-5;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .dates {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 1px 1fr;
          padding: 16px;
          .divider {
            height: 10px;
            width: 1px;
            background: $gray-15;
          }
          .date {
            color: $yellow-7;
            .label {
              color: $white-0;
              margin-bottom: 6px;
            }
          }
        }
      }
    }
    .bottomContent {
      max-width: 448px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .subscriptionCards {
    color: $white-0;
    margin-bottom: 60px;
    .cardsWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      max-width: 710px;
      margin: 0 auto;
      @include responsive("smDown") {
        grid-template-columns: 1fr;
      }
    }
  }
}
