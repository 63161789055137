@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
.homeWrapper {
  display: flex;
  .container {
    margin: 0 auto;
    @include responsive("mdDown") {
      max-width: 100% !important;
      padding: 0 !important;
    }
    @include responsive("xl") {
      max-width: 92vw !important;
      width: 100%;
    }
    .bottomContent {
      @include responsive("mdDown") {
        overflow: hidden;
      }
    }
    .wrapper {
      background-color: $black-0;
    }
  }
}
