@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  padding: 130px 0px 70px 0px;

  text-align: left;
  @include responsive("lgDown") {
    flex-direction: column-reverse;
    gap: 50px;
    padding: 80px 0px 30px 0px;
    text-align: center;
  }
  .leftContainer {
    max-width: 580px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    > p {
      font-family: "Poppins-Bold";
      font-size: 66px;
      color: $white-0;
      font-style: normal;
      line-height: 91px;
      max-width: 100%;
      @include responsive("mdDown") {
        font-size: 40px;
        line-height: 60px;
      }
    }
    .buttonWrapper {
      display: flex;
      gap: 28px;

      @include responsive("smDown") {
        gap: 10px;
      }

      .joinButton {
        display: flex;
        padding: 16px 85px;
        justify-content: center;
        align-items: center;
        border-radius: 93px;
        border: 2px solid $pink-1;
        background: linear-gradient(257deg, #7500a0 1.08%, #2d0048 105.53%);
        span {
          color: $white-0;
          font-family: "Poppins-Bold";
          font-size: 18px;
          line-height: normal;
          text-transform: uppercase;
        }

        @include responsive("smDown") {
          padding: 12px 40px;
        }
      }
      .trialButton {
        background-color: transparent !important;
        > span {
          color: $white-0;
          font-family: "Poppins-Semibold";
          font-size: 18px;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
      @include responsive("lgDown") {
        justify-content: center;
      }
    }
    @include responsive("lgDown") {
      max-width: 100%;
    }
  }
}
