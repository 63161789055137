@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 75px 0px 60px 0px;
  gap: 20px;
  @include responsive("lgDown") {
    flex-direction: column;
    gap: 50px;
  }
  @include responsive("mdDown") {
    padding: 30px 0px;
  }
  .leftContainer {
    max-width: 530px;
    text-align: left;
    .joinText {
      font-family: "Poppins-Black";
      font-size: 100px;
      background: linear-gradient(180deg, #ffc500 0%, #ff8e09 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @include responsive("lgDown") {
        font-size: 60px;
      }
    }
    @include responsive("lgDown") {
      max-width: 100%;
      text-align: center;
    }
  }
  .rightContainer {
    display: flex;
    flex-direction: column;
    gap: 32px;
    text-align: left;
    max-width: 560px;
    @include responsive("lgDown") {
      text-align: center;
    }
    .title {
      color: $white-0;
      font-family: "Poppins-Bold";
      font-size: 48px;
    }
    .subText {
      color: #fff;
      font-family: "Poppins-Bold";
      font-size: 18px;
      > span {
        background: linear-gradient(180deg, #ffc500 0%, #ff8e09 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Poppins-Black";
        text-transform: uppercase;
      }
    }
    .detailsWrapper {
      display: flex;
      flex-direction: column;
      gap: 28px;
      .details {
        color: $white-0;
        font-family: "Poppins-Bold";
        font-size: 18px;
        .subDetail_text {
          background: linear-gradient(180deg, #ffc500 0%, #ff8e09 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .subDetail {
          color: rgb(244, 144, 8);
          text-transform: uppercase;
          font-weight: 700;
        }
      }
    }
    .buttonWrapper {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: max-content;
      gap: 24px;
      @include responsive("lgDown") {
        max-width: 100%;
      }
      .joinButton {
        display: flex;
        padding: 16px 85px;
        justify-content: flex-start;
        align-items: center;
        border-radius: 93px;
        border: 2px solid $pink-1;
        background: linear-gradient(257deg, #7500a0 1.08%, #2d0048 105.53%);
        span {
          color: $white-0;
          font-family: "Poppins-Bold";
          font-size: 18px;
          line-height: normal;
          text-transform: uppercase;
        }
      }
      .trialButton {
        background-color: transparent !important;
        > span {
          color: $white-0;
          font-family: "Poppins-Semibold";
          font-size: 18px;
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }
  }
}
