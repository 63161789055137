@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding-top: 160px;
  padding-bottom: 60px;
  min-height: 1208px;
  max-width: 100%;
  aspect-ratio: 3/1;
  @include responsive("lgDown") {
    height: auto;
    aspect-ratio: 0;
  }
  .container {
    max-width: 1600px;
    padding-left: 32px;
    padding-right: 32px;
    margin-left: auto;
    margin: 0 auto;
    @include responsive("mdDown") {
      padding-left: 16px;
      padding-right: 16px;
    }
    .lampcards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;
      place-items: stretch;
      @include responsive("lgDown") {
        justify-items: center;
        grid-template-columns: 1fr;
        place-items: stretch;
      }
      .lampcard {
        min-height: 525px;
        position: relative;
        background: linear-gradient(
            0deg,
            rgba(193, 168, 117, 0.8),
            rgba(193, 168, 117, 0.8)
          ),
          linear-gradient(
            339.03deg,
            rgba(255, 255, 255, 0) 52.79%,
            rgba(255, 255, 255, 0.6) 95.95%
          ),
          linear-gradient(
            76.82deg,
            rgba(87, 98, 101, 0.6) 11.6%,
            rgba(144, 149, 149, 0.6) 21.66%,
            rgba(132, 139, 138, 0.6) 48.06%,
            rgba(87, 98, 101, 0.6) 74.22%,
            rgba(87, 98, 101, 0.6) 77.23%,
            rgba(117, 122, 123, 0.6) 88.05%,
            rgba(87, 98, 101, 0.6) 91.31%
          );
        background-blend-mode: color, overlay, normal;

        border-radius: 8px;
        color: $white-0;
        .cards {
          background: rgba(0, 0, 0, 0.4);
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-height: 440px;
          margin: 28px 34px 73px 34px;
          .cardContentWrapper {
            padding-left: 24px;
            padding-right: 24px;
            .title {
              font-weight: 400;
              font-size: 64px;
              line-height: 78px;
              font-family: "Impact";
              padding-top: 20px;
              margin-bottom: 8px;
              .titleBorder {
                border-bottom: 1px solid $yellow-9;
              }
            }
            .subTitle {
              // font-weight: 500;
            }
            .descriptionList {
              font-weight: 700;
              font-size: 28px;
              line-height: 156.52%;
              letter-spacing: 0.05em;
              max-width: 340px;
              .description {
                text-align: left;
              }
            }
          }
          .detailsButtonWrapper {
            text-align: center;
            margin-bottom: -34px;
            .button {
              padding: 20px 30px;
              bottom: 50px;
              width: fit-content;
              background: linear-gradient(
                97.51deg,
                #3c3f8f -9.24%,
                #62036b 119.35%
              );
              border: 3px solid $purple-7;
              border-radius: 8px;
              font-weight: 700;
              font-size: 32px;
              line-height: 39px;
              text-align: center;
            }
          }

          .buttonWrapper {
            background: rgba(27, 27, 27, 0.5);
            border-radius: 8px;
            padding: 8px 0px;
          }
        }
      }
    }
  }
}
