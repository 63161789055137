@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  width: 100%;
  margin: 0 auto 120px;
  background-color: black;
  padding: 24px 0px 45px 0px;
  max-width: 1270px;
  padding: 16px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    .edit {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 7px;
    }
  }
  .contentWrapper {
  }
}
