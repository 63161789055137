@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  position: relative;
  padding: 40px 16px;
  .title {
    margin-bottom: 62px;
    @include responsive("mdDown") {
      margin-bottom: 35px
    }
    img {
      max-width: 748px;
      height: auto;
      @include responsive("mdDown") {
        max-width: 80%;
      }
      @include responsive("smDown") {
        max-width: 100%;
      }
      @media only screen and (max-width: 400px) {
        max-width: 70%;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 29px;
    margin-bottom: 75px;
    @include responsive("mdDown") {
      margin-bottom: 17px;
      gap: 22px;
    }
    .message {
      max-width: 847px;
      font-family: "Times New Roman";
      font-style: italic;
      font-weight: 700;
      font-size: 35px;
      line-height: 40px;
      text-align: center;
      color: $white-0;
      @include responsive("mdDown") {
        font-style: italic;
        font-size: 24px;
        line-height: 28px;
      }
    }
    .secondMessage {
      max-width: 932px;
      font-family: "Times New Roman";
      font-style: italic;
      font-weight: 700;
      font-size: 35px;
      line-height: 40px;
      text-align: center;
      color: $white-0;
      @include responsive("mdDown") {
        font-family: 'Times New Roman';
        font-style: italic;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }
    }
    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 5px;
      gap: 15px;
      @include responsive("smDown") {
        margin-bottom: 0;
      }
      .hide {
        @include responsive("smDown") {
          display: none;
        }
      }
      p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 62px;
        line-height: 76px;
        color: $white-0;
        @include responsive("smDown") {
          font-size: 48px;
          line-height: 59px;
        }
      }

      span {
        height: 22px;
        width: 22px;
        border-radius: 50%;
        background: linear-gradient(
          180deg,
          #b9833b -0.15%,
          #fee7b0 17.84%,
          #fee9ba 23.85%,
          #fdecd0 25.77%,
          #feda92 29.38%,
          #ffcd64 32.39%,
          #efc875 33.9%,
          #b9833b 75.98%,
          #b9833b 99.85%
        );
      }
    }
  }
}
