@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  display: flex;
  gap: 18px;
  .mapWrapper {
    max-height: calc(100vh - 147px);
    overflow-y: auto;
    width: calc(100% - 320px);
    transition: width 0.3s ease;
    &::-webkit-scrollbar {
      width: 0px;
    }
    &.expand {
      transition: width 0.3s ease;
      width: calc(100% - 60px);
      transition: width 0.3s ease;
    }
  }
  .regionList {
    width: 320px;
    transition: width 0.3s ease;
    &.collapsed {
      transition: width 0.3s ease;
      width: 60px;
      margin-right: -24px;
    }
  }
}
