@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto;
  background-color: black;

  .container {
    background-color: $black-4;
    max-width: 884px;
    margin: 0 auto;
    // min-height: 100vh;
    padding: 40px 0px;
    overflow-y: auto;
    height: 100vh;
    // @include responsive("smDown") {
    //   padding: 29px 0px;
    // }
  }
  .container::-webkit-scrollbar {
    width: 0px;
    background-color: $gray-21;
  }
}
