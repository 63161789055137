@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.spinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 11;
  height: auto;
  background: $blue-4;
}
