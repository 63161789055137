@import "src/assets/scss/colors";

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  &:hover {
    .customControl {
      display: flex !important;
    }
  }
}
.customControl {
  position: absolute;
  left: 64px;
  right: 64px;
  bottom: 64px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
  svg {
    cursor: pointer;
  }
}
.top {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  > input {
    flex: 1;
    width: 100%;
  }
}
.slider, .sliderVolume {
  height: 6px;
  .track {
    height: 6px;
  }
  .thumb {
    width: 12px;
    height: 12px;
    top: -3px;
    z-index: 3 !important;
    margin-left: 1px;
  }
}
.sliderVolumeWrapper {
  width: 100px;
  display: none;
}
.sliderVolume {
  height: 6px;
  .trackVolume {
    height: 6px;
    &:nth-child(1) {
      background: $purple-3;
    }
  }
  .thumb {
    margin-left: 0;
  }
}
.bottom {
  display: flex;
  align-items: center;
  gap: 16px;
}
.volume {
  display: flex;
  align-items: center;
  > div {
    margin-left: 16px;
  }
  &:hover {
    .sliderVolumeWrapper {
      display: block;
    }
  }
}
.bottomRight {
  margin-left: auto;
}
.videoPlayTime {
  display: flex;
  gap: 6px;
  align-items: center;
  > span {
    &:nth-child(2) {
      display: block;
      height: 18px;
      width: 2px;
      border-right: 2px solid $white-0;
    }
  }
}
.endedOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #00000066;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  > span {
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $white-0;
    border-radius: 50%;
  }
}