.followUpContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 24px;
    border: 1px solid #34D399;
    background-color: #DEFFF3;
    padding: 1rem;
}

.iconContainer {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.title {
    color: #000;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
}

.description {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.instructionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    height: 100%;
    gap: 8px;
    text-align: left;
}

.showMore {
    color: #000;
    cursor: pointer;
    font-weight: 800;
}