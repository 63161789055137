@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modal {
  max-width: 775px;
  .modalHeader {
    text-align: center;
  }
  :global {
    .modal-content {
      border-radius: 4px;
      background: $black-4;
      color: $white-0;
      // min-height: calc(100vh - 300px);
      // max-height: calc(100vh - 80px);
      > div {
        padding: 30px 32px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
      }
    }
    .modal-header {
      padding: 0;
      border: 0;
      .modal-title {
        display: flex;
        gap: 16px;
        > span {
          margin: 0;
          display: flex;
          gap: 16px;
          align-items: flex-end;
          font-size: 30px;
          & + span {
            font-size: 16px;
            font-weight: 400;
            position: relative;
            top: -5px;
            color: $gray-16;
          }
        }
        & + span {
          color: $white-0;
          svg {
            path {
              fill: $white-0;
            }
          }
        }
      }
    }
  }
}
