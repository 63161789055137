@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

$invalid-btn-color: #d04e55;

.container {
  max-width: 430px;
  margin: 0 auto;
  @include responsive("smDown") {
    padding: 0 20px;
  }

  .signupInputs {
    .formGroup {
      text-align: left;
      width: 100%;

      .label {
        color: $white-0;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }

      .inputWrapper {
        outline: none !important;
        border: none !important;

        > span {
          background-color: $white-0 !important;
        }
      }

      .input {
        background-color: $white-0;
        color: $black-0 !important;
        border-radius: 3px;

        &:focus,
        &:active,
        &:focus-visible {
          box-shadow: none;
          color: $black-0;
          outline: none;
          background-color: $white-0;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: $black-0;
        }

        &::placeholder {
          color: $gray-26 !important;
        }
      }
    }

    .inputContainer {
      padding: 32px 0px 150px 0px;
    }

    .buttonContainer {
      margin-bottom: 43px;
    }

    .error {
      color: $warning-btn-color;
    }
  }

  .tAndC {
    padding-top: 50px;
    color: $white-0;
    font-family: "Inter";
    font-size: 24px;
    line-height: normal;

    > span {
      margin-left: 5px;
      cursor: pointer;
      font-weight: 700;
    }
  }
}
