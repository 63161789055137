@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.navWrapper {
  height: 100px;
  width: 100%;
  z-index: 1;
  padding: 0 16px;
  background-color: $black-4;

  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 88vw;
    margin: 0 auto;
    @include responsive("smDown") {
      max-width: 100vw;
      gap: 50px;
    }
    .title {
      cursor: pointer;
      img {
        max-width: 100%;
      }
      & + div {
        position: relative;
        top: -2px;
      }
    }
    .navActions {
      display: flex;
      align-items: center;
      gap: 32px;
      img {
        cursor: pointer;
      }

      .dropdown {
        .bellButton {
          background-color: transparent;
          border: none;
        }
        .menu {
          width: fit-content;
          border-radius: 3px;
          border: 1px solid $gray-7;
          background: $black-4;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          .item {
            display: flex;
            align-items: center;
            gap: 5px;
            &:hover {
              background-color: inherit;
            }
            img {
              height: 20px;
              width: 22px;
            }
          }
        }
      }
    }
  }
}
