@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  &.modalQuestionWrapper {
    text-align: center;
    padding: 50px 0px;
    img {
      min-height: 468px;
    }
  }
  img {
    max-width: 100%;
  }
}
