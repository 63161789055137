@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.wrapper {
  background: rgba(224, 224, 224, 0.4);
  display: flex;
  gap: 4px;
  border-radius: 8px;
  padding: 4px 8px;
  font-size: 18px;
  font-weight: 400;
  color: white;
  line-height: 24px;
  height: 32px;
  &.color_white {
    background: white;
    color: $blue-4;
    .socialButtonIcon {
      svg {
        path {
          stroke: $blue-4;
        }
      }
    }
  }
  &.active {
    background: $purple-1;
  }
}
.socialButtonIcon {
  display: flex;
  width: 24px;
  height: 24px;
  > svg {
    width: 24px;
    height: 24px;
  }
}
