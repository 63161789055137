@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  &.modalQuestionWrapper {
    text-align: center;
    padding: 30px 16px;
    aspect-ratio: 16/9;
    background-color: $white-0;
    border-radius: 8px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      margin-bottom: 39px;
    }
  }
  img {
    max-width: 100%;
    margin-bottom: 25px;
  }
  .extraText {
    text-align: left;
    font-size: 24px;
    color: $blue-4;
  }
}
