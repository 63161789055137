@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.mainContainer {
  height: 100%;
  width: 100%;
  padding: 32px 0px;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1160px;
    gap: 32px;
  }
}
.hintText {
  border-radius: 8px;
  background: $white-0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 16px;
  max-width: 1160px;
  text-align: center;
  margin-bottom: 32px;
  > span {
    font-size: 14px;
    font-weight: 700;
    color: $black-0;
  }
}

.warningText {
  color: $white-0;
  border-radius: 8px;
  background: $red-2;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 16px;
  max-width: 1160px;
  text-align: center;
  margin-bottom: 32px;
  > span {
    font-size: 14px;
    font-weight: 700;
    color: $black-0;
  }
}