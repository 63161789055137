@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  color: $white-0;
  padding: 0 16px;
  margin-top: 40px;
  height: calc(100vh - 400px);
  @include responsive("smDown") {
    margin-top: 16px;
  }
  .inputs {
    height: calc(100%);
    max-width: 538px;
    margin: 0 auto;
    .formWrapper {
      height: calc(100%);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .label {
        @include responsive("smDown") {
          margin-bottom: 8px !important;
        }
      }
      label {
        @include responsive("smDown") {
          margin-bottom: 8px !important;
        }
      }
      .formGroupInput {
        @include responsive("smDown") {
          margin-bottom: 24px !important;
        }
      }

      .selectWrapper {
        text-align: left;
        @include responsive("smDown") {
          margin-bottom: 24px !important;
          :global {
            .rselect__placeholder {
              color: $input-placeholder-color;
              font-family: "Inter";
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }
      .stripeErrorMessage {
        font-size: 20px;
        color: $warning-btn-color;
        text-align: left;
      }
      .buttonWrapper {
        @include responsive("smDown") {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          background: $black-4;
          box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
          padding: 12px 24px 34px 24px;
          z-index: 10;
          button {
            position: relative;
            span {
              font-size: 18px;
              line-height: normal;
            }
          }
        }

        button {
          width: 100%;
        }
        span {
          font-size: 32px;
          line-height: 39px;
        }
      }
      .address {
        display: grid;
        gap: 28px;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 40px;
        @include responsive("smDown") {
          margin-bottom: 90px;
          gap: 0px;
          grid-template-columns: 1fr;
        }
      }
      .stripeInputs {
        display: grid;
        gap: 28px;
        grid-template-columns: 1fr 1fr;
        @include responsive("smDown") {
          grid-template-columns: 1fr;
          gap: 8px;
        }
        .cardNumber {
          text-align: left !important;
        }
        .expiryAndCvc {
          display: grid;
          gap: 28px;
          grid-template-columns: 1.5fr 1fr;
          text-align: left !important;
          margin-bottom: 8px;
          @include responsive("smDown") {
            grid-template-columns: 1fr;
            gap: 8px;
          }
        }
        .errorMessage {
          font-size: 14px;
          color: $warning-btn-color;
          text-align: left;
        }
      }
    }
  }
  .form {
    .paymentDetails {
      .cvcExpiry {
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-gap: 18px;
      }
    }
  }
}
