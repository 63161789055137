@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.card {
  max-width: 571px;
  min-height: 260px;
  background-color: $white-0;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .titleContainer {
      width: 60%;
      display: flex;
      align-items: center;
      img {
        width: 32px;
      }
      p {
        margin-left: 12px;
      }
    }
    .mobileOnly {
      border-radius: 16px;
      border: 2px solid $purple-1;
      padding: 0px 8px;
    }
  }
  .descriptionContainer {
    margin: 16px 0px 20px 0px;
    min-height: 68px;
    text-align: left;
  }
  .footer {
    width: 100%;
    gap: 18px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .plansContainer {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 12px;
      > p {
        text-align: left;
        &:nth-child(2n + 1) {
          min-width: 200px;
          max-width: 200px;
        }
        &:nth-child(2n) {
          min-width: calc(100% - 200px);
          max-width: calc(100% - 200px);
        }
      }
    }
    .buttonContainer {
      display: flex;
      gap: 16px;
      margin-top: auto;
      > * {
        flex: 1;
      }
      img {
        width: 64px;
      }
      .webButton {
        width: 100%;
        height: 96px;
        background: linear-gradient(115deg, #121429 1.53%, #3835a0 138.07%),
          #fff;
        padding-left: 16px;
        padding-right: 16px;
        box-shadow: none !important;
        .btnChildren {
          display: flex;
          align-items: center;
          gap: 40px;
        }
      }
      .mobileButton {
        width: 100%;
        height: 96px;
        padding-left: 16px;
        padding-right: 16px;
        background: transparent !important;
        box-shadow: none !important;
        .btnChildren {
          display: flex;
          align-items: center;
          gap: 18px;
        }
      }
    }
  }
}

.learnMoreButton {
  position: relative;
  background: white !important;
  box-shadow: none !important;
  border-radius: 8px;
  border: 0 !important;
  font-size: 24px;
  font-weight: 700;
  color: #3835A0 !important;
  line-height: 30px;
  &:before {
    z-index: 1;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px; 
    padding: 2px; 
    background: linear-gradient(90deg, #121429 0%, #3835A0 100%);
    -webkit-mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
            mask-composite: exclude; 
  }
}
.caption {
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: black;
  margin: 0;
  text-align: left;
}
.hidden {
  visibility: hidden !important;
}