@import "src/assets/scss/colors";

.cookieContainer {
  display: inline-flex;
  padding: 10px 16px;
  left: 4px !important;
  right: 4px !important;
  bottom: 4px !important;
  width: auto !important;
  border-radius: 3px;
  border: 1px solid $gray-7;
  background: $black-4 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .cookieText {
    color: $white-0;
    font-family: "Inter";
    font-size: 16px;
    text-align: left;
    margin: 0px !important;
  }
  .cookieBtn {
    font-family: "Verdana";
    font-weight: 400;
    font-size: 14px;
    color: $white-0 !important;
    -webkit-text-fill-color: unset;
    -webkit-text-stroke-width: unset;
    -webkit-text-stroke-color: unset;
    text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;

    background: linear-gradient(0deg, #c1a875 0%, #c1a875 100%),
      linear-gradient(339deg, rgba(255, 255, 255, 0) 52.79%, #fff 95.95%),
      linear-gradient(
        77deg,
        #576265 11.6%,
        #9ea1a1 25.31%,
        #848b8a 48.06%,
        #576265 55.72%,
        #576265 77.23%,
        #757a7b 85.34%,
        #576265 91.31%
      ) !important;
    background-blend-mode: color, overlay, normal !important;

    border-radius: 4px !important;
    padding: 4px 6px !important;
    margin: 0px !important;
  }
}
