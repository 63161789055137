.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  &.isUploadImage:not(.noPadding) {
    padding-top: 34px;
  }
}
.resourceWrapper {
  border-radius: 4px;
  background: #323771;
  > div {
    &:nth-child(1) {
      border-radius: 4px;
      border: 1px solid #5D629D;
      img {
        width: 100%;
        border-radius: 4px;
        max-height: 300px;
        object-fit: contain;
      }
    }
    &:nth-child(2) {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      > span {
        display: flex;
        gap: 8px;
        align-items: center;
        > span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          word-break: break-all;
          &:nth-child(1) {
            font-size: 16px;
            font-weight: 600;
            display: inline-block;
            width: 60px;
          }
        }
      }
    }
  }
  &.isUploading {
    > div {
      &:nth-child(1) {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        border: 0;
      }
    }
  }
  &.uploadable {
    > div {
      &:nth-child(1) {
        min-height: 300px;
        background: #121429;
        padding: 0 40px;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        gap: 8px;
        text-align: center;
        align-items: center;
        justify-content: center;
        > span {
          &:nth-child(1) {
            font-size: 16px;
            margin-bottom: 8px;
          }
        }
        > div {
          margin-bottom: 32px;
        }
      }
      .uploadBtn {
        padding: 8px 30px;
        border-radius: 8px;
        > span {
          font-size: 24px;
          font-weight: 600;
        }
      }
    }
  }
}
.uploadWrapper {
  border: 0;
}