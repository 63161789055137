.wrapper {
  display: flex;
  justify-content: space-between;
  min-width: 100%;
}
.right {
  display: flex;
  gap: 32px;
  &.showManageContent {
    gap: 16px;
  }
}