@import "src/assets/scss/colors";

.wrapper {
  color: $white-0;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  > * {
    min-width: 100%;
    max-width: 100%;
  }
}
.mediaDetailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
}
.manageContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1;
  h2 {
    font-size: 32px;
    font-weight: 600;
  }
  .inner {
    flex: 1;
    border-radius: 4px;
    background: $blue-4;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 27px 16px 12px 16px;
    .uploadEmptyState {
      flex: 1;
    }
  }
  .footer {
    margin-top: auto;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
  }
  .manageContentTable {
    margin-top: -27px;
    margin-right: -16px;
    margin-left: -16px;
    height: 100%;
  }
}
.postDetailsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1;
  text-align: left;
  position: relative;
  padding-bottom: 24px;
  .inner {
    width: 100%;
    border-radius: 8px;
    border: 1px solid $blue-7;
    background: $blue-4;
    padding: 30px 30px 24px 48px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    > h3 {
      font-size: 30px;
      font-weight: 600;
      display: flex;
      gap: 12px;
      align-items: flex-end;
      margin: 0;
      > span {
        font-size: 16px;
        font-weight: 400;
        color: $gray-16;
        position: relative;
        top: -2px;
      }
    }
    .socials {
      margin-bottom: 32px;
    }
  }
}
.userDetailsWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}
.mainWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
}
.subInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 24px;
  > h2 {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 12px;
    text-align: left;
  }
}
.reportModalWrapper {
  max-width: 525px;
}

.userFollowerWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}
