@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.clueListContainer {
  position: sticky;
  top: 30px;
}
.clueListWrapper {
  padding: 25px;
  max-height: calc(100vh - 210px);
  overflow: auto;
  &.disable {
    pointer-events: none;
    opacity: 0.6;
  }
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $white-3;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray-18;
    border-radius: 8px;
  }
}
.allLampFound {
  text-align: center;
  color: $white-0;
  .button {
    border-radius: 8.49587px;
    margin-bottom: 40px;
    span {
      border: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      padding: 8px 16px;
      // text-shadow: -1px 0 $black-0, 0 1px $black-0, 1px 0 $black-0,
      //   0 -1px $black-0;
      -webkit-text-stroke-width: 0px;
    }
  }
}

.clueActions {
  padding: 5px 0;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  &.disable {
    pointer-events: none;
  }
  .divider {
    height: 30px;
    border-right: 1px solid $white-0;
    border-color: $white-0;
  }
  button {
    padding: 0;
    border-radius: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    background-color: transparent !important;
  }
}
