@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  position: relative;
  padding: 0px 16px 40px 16px;
  .container {
    max-width: 1440px;
    margin: 0 auto;
  }
  @include responsive("smDown") {
    padding-bottom: 0;
  }
  .shadows {
    width: 5vw;
    z-index: 10;
    height: calc(100% - 136px);
    position: absolute;
    @include responsive("mdDown") {
      display: none;
    }
  }
  .leftIcon {
    position: absolute;
    top: calc(50% - 90px);
    left: -49px;
    z-index: 10;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    @include responsive("mdDown") {
      display: none;
    }
  }
  .RightIcon {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 90px);
    right: -49px;
    z-index: 10;
    padding-left: 10px;
    padding-right: 10px;
    @include responsive("mdDown") {
      display: none;
    }
  }
  .MobileRightIcon {
    display: none;
    @include responsive("xsDown") {
      display: block;
      position: absolute;
      z-index: 10;
      top: 45%;
      right: 3px;
    }
  }
  .title {
    padding: 20px 0;
    @include responsive("mdDown") {
      padding-bottom: 0 !important;
    }
    p {
      font-family: "Impact";
      font-weight: 400;
      line-height: 1;
      font-size: 155px;
      color: $black-0;
      -webkit-text-stroke-width: 3px;
      -webkit-text-stroke-color: $yellow-2;
      @include responsive("mdDown") {
        font-size: 90px;
      }
      @include responsive("smDown") {
        -webkit-text-stroke-width: 1px;
        font-size: 65px;
        line-height: 79px;
      }
    }
  }
  .slickWrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
  }
  .sliderWrapper {
    position: relative;
    align-items: center;
    margin-bottom: 24px;
    :global {
      .slick-initialized {
        button {
        }
      }
      .slick-arrow {
        display: none !important;
      }
      .slick-track {
        display: flex;
      }
      .slick-list {
        margin: 0 -10px;
        overflow: hidden;
      }
      .slick-slide {
        margin: 0 10px;
      }
      .slick-dots {
        display: flex !important;
        justify-content: center;

        margin: 0;
        padding: 1rem 0;

        list-style-type: none;

        li {
          margin: 0 0.25rem;
        }

        button {
          display: block;
          width: 8px;
          height: 8px;
          padding: 0;

          border: none;
          border-radius: 100%;
          background-color: $yellow-2;
          opacity: 0.2;

          text-indent: -9999px;
        }

        li.slick-active button {
          background-color: $yellow-2;
          opacity: 1;
        }
      }
    }

    .game {
      .gameWrapper {
        background-color: $yellow-2;
        aspect-ratio: 16 / 9;
        padding: 4px;
        border: 5px solid $black-0;
        margin-bottom: 15px;
        border-radius: 8px;

        .imageWrapper {
          width: 100%;
          height: 100%;
          border: 5px solid $black-0;
          border-radius: 6px;
          position: relative;
          overflow: hidden;
          .leftCurtain {
            position: absolute;
            left: -3px;
            width: 40%;
            height: 101%;
            top: -1px;
          }
          .rightCurtain {
            position: absolute;
            right: -3px;
            width: 40%;
            height: 101%;
            top: -1px;
          }
        }
      }
      .cardBottom {
        .findButton {
          visibility: hidden;
        }
        .showButton {
          button {
            @include responsive("mdUp") {
              max-width: 360px;
            }
          }
          span {
            font-size: 32px;
            line-height: 39px;
            text-shadow: -1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000;
            -webkit-text-fill-color: unset;
            -webkit-text-stroke-width: unset;
            -webkit-text-stroke-color: unset;
          }
          visibility: visible;
        }
        p {
          margin-bottom: 15px;
          font-family: "Impact";
          font-style: normal;
          font-weight: 400;
          font-size: 36px;
          line-height: 44px;
          color: $yellow-2;
          text-shadow: -1px 0 $black-0, 0 1px $black-0, 1px 0 $black-0,
            0 -1px $black-0;
          @include responsive("smDown") {
            font-family: Inter;
            font-weight: 600;
            font-size: 28px;
            line-height: 33px;
          }
        }
        button {
          width: 100%;
          height: fit-content;
          @include responsive("smDown") {
            width: fit-content;
          }
          span {
            font-family: Impact;
            text-transform: uppercase;
            letter-spacing: 0.16em;
            font-weight: 400;
            font-size: 30px;
            line-height: 39px;
            padding: 6px 6px !important;
            @include responsive("smDown") {
              font-family: Impact;
              letter-spacing: 0.05em;
              text-transform: capitalize;
              font-weight: 700;
              padding-left: 20px !important;
              padding-right: 20px !important;
            }
          }
        }
      }
      .cardText {
        display: none;
      }
    }
  }
}
