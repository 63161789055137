@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.questionsWrapper {
  .question {
    margin-bottom: 28px;
    &.isModal {
      background: transparent;
    }
    .cardBody {
      &.isModal {
        padding: 0;
      }
      .header {
        p {
          text-align: left;
        }
        .description {
        }
      }
      .questionContent {
        // background: transparent;

        .arrow {
          cursor: pointer;
          width: 100%;
          text-align: end;
        }
      }
    }
  }
  .inputs {
    .header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      .text {
        text-align: left;
      }
      .numberOfInputs {
      }
    }
  }
}
