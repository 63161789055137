@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";

.wrapper {
  &.method_asc {
    svg {
      > path {
        &:nth-child(2) {
          fill: $white-0;
        }
      }
    }
  }
  &.method_desc {
    svg {
      > path {
        &:nth-child(1) {
          fill: $white-0;
        }
      }
    }
  }
  svg {
    > path {
      fill: #e0e0e080;
    }
  }
}