@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  margin: 0 auto;
  background-color: $black-4;
  padding: 22px;
  .wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding-left: 50px;
    padding-right: 50px;
    .title {
      margin-bottom: 32px;
      p {
        color: $white-0;
        text-align: left;
        font-weight: 400;
        font-size: 30px;
        line-height: 38px;
      }
    }
    .sliderWrapper {
      position: relative;
      align-items: center;
      margin-bottom: 24px;
      .shadows {
        width: 5vw;
        z-index: 10;
        height: calc(100% - 73px);
        position: absolute;
        &.activeShadow {
          height: calc(100% - 155px);
        }
        @include responsive("mdDown") {
          display: none;
        }
      }
      .leftIcon {
        position: absolute;
        top: calc(50% - 60px);
        left: -49px;
        z-index: 10;
        cursor: pointer;
        padding-left: 10px;
        padding-right: 10px;
        @include responsive("smDown") {
          display: none;
        }
      }
      .RightIcon {
        position: absolute;
        cursor: pointer;
        top: calc(50% - 60px);
        right: -49px;
        z-index: 10;
        padding-left: 10px;
        padding-right: 10px;
        @include responsive("smDown") {
          display: none;
        }
      }
      :global {
        .slick-initialized {
          button {
          }
        }
        .slick-arrow {
          display: none !important;
        }
        .slick-track {
          display: flex;
        }
        .slick-list {
          margin: 0 -10px;
          overflow: hidden;
        }
        .slick-slide {
          margin: 0 10px;
        }
        .slick-dots {
          display: none !important;
          justify-content: center;

          margin: 0;
          padding: 1rem 0;

          list-style-type: none;

          li {
            margin: 0 0.25rem;
          }
        }
      }

      .game {
        .gameWrapper {
          background-color: $yellow-2;
          aspect-ratio: 16 / 9;
          padding: 4px;
          border: 5px solid $black-0;
          margin-bottom: 15px;
          border-radius: 8px;

          .imageWrapper {
            background-color: $white-0;
            width: 100%;
            height: 100%;
            border: 5px solid $black-0;
            border-radius: 6px;
            position: relative;
            overflow: hidden;
            .leftCurtain {
              position: absolute;
              left: -3px;
              width: 40%;
              height: 101%;
              top: -1px;
            }
            .rightCurtain {
              position: absolute;
              right: -3px;
              width: 40%;
              height: 101%;
              top: -1px;
            }
          }
        }
        .text {
          margin-bottom: 39px;
          p {
            font-weight: 400;
            font-size: 36px;
            line-height: 44px;
            color: $yellow-2;
            text-shadow: -1px 0 $black-0, 0 1px $black-0, 1px 0 $black-0,
              0 -1px $black-0;
          }
        }
        .cardBottom {
          .button {
            width: 90%;
            border-radius: 17.569px;
            span {
              padding: 0;
              border-radius: 17.569px;
            }
            .buttonText {
              -webkit-text-stroke-width: 1px;
            }
          }
        }
      }
    }
  }
}
