@import "src/assets/scss/colors";

.wrapper {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sliderActions {
      display: flex;
      align-items: center;
      gap: 12px;
      .iconWrapper {
        height: 40px;
        cursor: pointer;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $purple-1;
        &.disableFirst {
          pointer-events: none;
          svg {
            opacity: 0.5;
          }
        }
        &.disableLast {
          pointer-events: none;
          svg {
            opacity: 0.5;
          }
        }
      }
    }
  }
  .sliderWrapper {
    .slider {
      display: grid;
      :global {
        .slick-track {
          .slick-slide {
            padding-right: 8px;
          }
        }
      }
    }
  }
}
.mod {
  max-width: 1167px;
  padding: 0 40px;
  .modal {
    border-radius: 8px;
    background: $black-7;
    padding: 18px 24px;

    width: 100%;
    .header {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .content {
      .title {
      }
      .text {
      }
    }
  }
}
.mediaBlock {
  color: $white-0;
}
