@import "src/assets/scss/colors";

.wrapper {
  margin-bottom: 24px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sliderActions {
      display: flex;
      align-items: center;
      gap: 12px;
      .iconWrapper {
        height: 40px;
        cursor: pointer;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $purple-1;
        &.disableFirst {
          pointer-events: none;
          svg {
            opacity: 0.5;
          }
        }
        &.disableLast {
          pointer-events: none;
          svg {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .sliderWrapper {
    .slider {
      display: grid;
      :global {
      }
    }
    .mediaBlock {
      color: $white-0;
      padding: 0;
      .media {
        .video {
          max-height: 222px;
        }
        img {
          width: 100%;
          max-height: 222px;
        }
        :global {
          .image-placeholder {
            width: 100%;
            max-height: 222px;
          }
        }
      }
    }
  }
}

.modal {
  .contentWrapper {
    .mediaBlock {
      color: $white-0;
      .media {
        .video {
          max-height: calc(100vh - 300px);
        }
        img {
          max-height: calc(100vh - 300px);
          width: 100%;
        }
      }
    }
  }
}
