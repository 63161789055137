@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  min-height: 100vh;
  width: 100%;
  position: relative;
  .wrapperLayer {
    background: transparent;
    position: relative;
    padding: 180px 0px 100px 0px;
    @include responsive("smDown") {
      padding: 130px 0px 150px 0px;
    }
    &.signInWrapper {
      padding: 100px 0px 32px 0px;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(
          81deg,
          #090015 0.1%,
          rgba(14, 0, 31, 0.86) 19.79%,
          rgba(27, 0, 61, 0) 93.93%
        ),
        linear-gradient(180deg, #0a021d 0.21%, rgba(14, 0, 47, 0) 32.08%);
      z-index: 0;
    }
  }
  .logoHeader {
    margin-bottom: 53px;
    > img {
      margin-right: 34px;
      height: 178px;
      max-width: 338px;
      width: 100%;
    }
    @include responsive("smDown") {
      display: none;
    }
  }
  .container {
    background-color: $blue-4;
    border-radius: 20px;
    padding: 40px 0px;
    max-width: 884px;
    margin: 0px auto;
    @include responsive("smDown") {
      margin: 0px 20px;
    }
    &.paymentContainer{
      max-width: 1088px;
      padding: 0px;
      @include responsive("mdDown") {
        background: transparent;
      }
      @include responsive("smDown") {
        margin: 0px 8px;
      }
    }
    &.signInContainer {
      max-width: 620px;
      overflow-y: auto;
      height: calc(100vh - 140px);
      @include responsive("smDown") {
        height: calc(100vh - 225px);
      }
    }
    &.signInContainer::-webkit-scrollbar {
      width: 0;
      background-color: $gray-21;
    }
  }
}
