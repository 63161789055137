@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  text-align: left;
  p {
    color: $white-0;
  }
  .personalInfo {
    border-radius: 4px;
    background: $black-5;
    margin-bottom: 24px;
    padding: 22px 25px;
    p {
      word-break: break-all;
      padding-right: 16px;
    }
    .title {
      margin-bottom: 26px;
    }
    .photo {
      margin-bottom: 24px;
      .img {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .names {
      display: grid;
      grid-template-columns: 300px 1fr;
      margin-bottom: 24px;
      @include responsive("smDown") {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
    .numbers {
      display: grid;
      grid-template-columns: 300px 1fr;
      margin-bottom: 24px;
      @include responsive("smDown") {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
    .email {
      display: grid;
      grid-template-columns: 300px 1fr;
      margin-bottom: 24px;
      @include responsive("smDown") {
        grid-template-columns: 1fr;
        gap: 0;
      }
    }
  }
  .username {
    border-radius: 4px;
    background: $black-5;
    padding: 22px 25px;
    .title {
      margin-bottom: 24px;
    }
  }
}
.deleteAccount {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  > span {
    text-decoration: underline;
    color: $white-0;
    font-weight: bold;
    cursor: pointer;
    font-size: 18px;
  }
}

.modal {
  max-width: 750px;
  .modalBody {
    max-width: 710px;
    margin: 0 auto;
  }
  .modalHeader {
    text-align: center;
    margin-bottom: 40px;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    .submit {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
    .cancelWrapper {
      span {
        text-decoration: none !important;
      }
    }
  }
  :global {
    .modal-content {
      border-radius: 4px;
      background: $black-4;
      color: $white-0;
      > div {
        padding: 30px 32px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
      }
    }
    .modal-header {
      padding: 0;
      border: 0;
      .modal-title {
        display: flex;
        gap: 16px;
        > span {
          margin: 0;
          display: flex;
          gap: 16px;
          align-items: flex-end;
          font-size: 30px;
          & + span {
            font-size: 16px;
            font-weight: 400;
            position: relative;
            top: -5px;
            color: $gray-16;
          }
        }
        & + span {
          color: $white-0;
          svg {
            path {
              fill: $white-0;
            }
          }
        }
      }
    }
  }
}
