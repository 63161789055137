@import "src/assets/scss/colors";

.backButton {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  margin-bottom: 26px;
  &:hover {
    background: transparent;
  }
  &:focus {
    background: transparent;
  }
  &:active {
    background: transparent !important;
  }
  .imageWrapper {
    background: linear-gradient(94.12deg, #7c44cd 46.64%, #fe00dc 154.68%);
    border: 1px solid $white-0;
    border-radius: 8px;
    padding: 5px 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
}

.modalWrapper {
  &.one {
    max-width: 840px;
  }
  &.two {
    max-width: 920px;
  }
  &.three {
    max-width: 840px;
  }
  &.four {
    max-width: 920px;
  }

  .modalHeaderContent {
    display: flex;
    align-items: center;
    gap: 24px;
    img {
      width: 48px;
    }
  }
  :global {
    .modal-content {
      border-radius: 8px;
      border: 1px solid $blue-7;
      background: $blue-4;
      color: $white-0;
      // min-height: calc(100vh - 300px);
      // max-height: calc(100vh - 80px);
      > div {
        padding: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
      }
    }
    .modal-header {
      padding: 0 0 0 20px;
      border: 0;
      align-items: flex-start;
      .modal-title {
        display: flex;
        gap: 16px;
        > span {
          margin: 0;
          display: flex;
          gap: 16px;
          align-items: flex-end;
          font-size: 30px;
          & + span {
            font-size: 16px;
            font-weight: 400;
            position: relative;
            top: -5px;
            color: $gray-16;
          }
        }
        & + span {
          color: $white-0;
          svg {
            path {
              fill: $white-0;
            }
          }
        }
      }
    }
  }
  .modalBody {
    padding: 0 20px;
    max-height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}
