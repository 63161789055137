@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.card {
  width: 300px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  background-color: $white-0;
  height: 354px;
  flex-shrink: 0;
  .header {
    border-radius: 6px 6px 0px 0px;
    width: 100%;
    padding: 8px 16px;
    height: 48px;
    background: $white-12;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .titleContainer {
      display: flex;
      align-items: center;
      gap: 8px;
      img {
        width: 32px;
      }
    }
  }
  .body {
    height: 273px;
    padding: 16px;
    .info {
      min-height: 178px;
      .historyContent {
        margin-top: 15px;
        margin-right: 20px;
        margin-left: 20px;
        :global {
          .leftIcon {
            left: -20px;
          }
          .rightIcon {
            right: -20px;
          }
        }

        .rightIcon {
          right: 0px;
        }
      }
    }
    .openButton {
      margin-top: 20px;
      border-radius: 8px;
      width: 100%;
      background: linear-gradient(115deg, #121429 1.53%, #3835a0 138.07%);
      &.openButtonSlider {
        margin-top: 5px;
      }
      span {
        color: $white-0;
        text-align: center;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
  .footer {
    text-align: right;
    padding: 0px 16px;
  }
}
