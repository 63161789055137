@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.downloadApp {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  justify-content: center;
  @include responsive("smDown") {
    flex-direction: column;
    align-items: center;
    .tgsIconWrapper {
      order: 0;
    }
  }
  img {
    margin-bottom: 0;
  }
  .download {
    width: 171px;
    height: 43px;
    display: flex;
    gap: 9px;
    border: 1px solid $white-0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .icon {
      height: 25px;
    }
  }
  &:not(.hideTGSIcon) {
    @include responsive("smDown") {
      > div {
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(2) {
          order: 0;
        }
        &:nth-child(3) {
          order: 2;
        }
      }
    }
  }
}
.tgsButton {
  @include responsive("smDown") {
    min-width: 96px;
  }
}