@import "src/assets/scss/colors";

.modalWrapper {
  max-width: 993px;
  .modalHeaderContent {
    display: flex;
    align-items: center;
    gap: 24px;
    img {
      width: 48px;
      height: 48px;
    }
  }
  :global {
    .modal-content {
      border-radius: 8px;
      border: 1px solid $blue-7;
      background: $blue-4;
      color: $white-0;
      > div {
        padding: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
      }
    }
    .modal-header {
      padding: 0 0 0 20px;
      border: 0;
      align-items: flex-start;
      .modal-title {
        display: flex;
        gap: 16px;
        > span {
          margin: 0;
          display: flex;
          gap: 16px;
          align-items: flex-end;
          font-size: 30px;
          & + span {
            font-size: 16px;
            font-weight: 400;
            position: relative;
            top: -5px;
            color: $gray-16;
          }
        }
        & + span {
          color: $white-0;
          svg {
            path {
              fill: $white-0;
            }
          }
        }
      }
    }
  }
  .modalBody {
    padding: 0 20px;
    max-height: calc(100vh - 60px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.detailsBlock {
  margin: 40px 0;
  > div {
    max-height: 422px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.modalBodyInner {
  display: flex;
  flex-direction: column;
  .content {
    display: grid;
    grid-template-columns: 9fr 5fr;
    gap: 24px;
    .details {
      .infoWrapper {
        padding: 24px 16px;
        background: $white-0;
        border-radius: 8px;
        .text {
          margin-bottom: 16px;
          line-height: 19px;
          font-weight: 400;
        }
        .info {
          gap: 12px;
          display: flex;
          justify-content: center;
          .example {
            text-align: center;
            img {
              max-width: 100%;
            }
            > p {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
    .buyActions {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 43px;
      > div {
        display: flex;
      }
    }
  }
}
.mobileTag {
  padding: 4px 8px;
  border-radius: 16px;
  background: white;
  font-size: 18px;
  font-weight: 600;
  color: #5742C0;
  line-height: 22px;
}
.availableButton {
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 16px;
  background: transparent !important;
  box-shadow: none !important;
  outline: none !important;
  border: 2px solid white !important;
  border-radius: 8px;
  opacity: 1 !important;
  width: 268px;
  > img {
    width: 64px;
    height: 64px;
  }
  > span {
    font-size: 18px;
    font-weight: 700;
    color: white;
    line-height: 22px;
    text-align: center;
  }
}
.images {
  > img {
    margin-right: 24px;
  }
}