@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  width: 32px;
  min-width: 32px;
  height: 32px;
  background: $red-1;
  border: 2px solid $white-0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  > svg {
    width: 22px;
    height: 22px;
    path {
      fill: $white-0;
    }
  }
}