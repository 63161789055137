@import "src/assets/scss/colors";

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  .form {
    display: flex;
    flex-wrap: wrap;
    gap: 32px 24px;
    width: 100%;
    flex: 1;
    textarea {
      min-height: 112px;
      font-size: 16px !important;
      &::placeholder {
        font-size: 16px !important;
        color: #E0E0E0 !important;
      }
    }
    input {
      font-size: 16px !important;
      &::placeholder {
        font-size: 16px !important;
        color: #E0E0E0 !important;
      }
    }
    :global {
      .rselect__placeholder {
        font-size: 16px !important;
        color: #E0E0E0 !important;
      }
    }
    &.success {
      flex-direction: column;
    }
  }
  .left, .right {
    min-width: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }
  .right {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .footer {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    gap: 56px;
    justify-content: flex-end;
    align-items: center;
    > span {
      font-size: 24px;
      font-weight: 500;
    }
  }
  &.isEditMode {
    .footer {
      gap: 16px;
    }
  }
}
.uploadStatus {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-right: auto;
  flex: 1;
  > span {
    font-size: 16px;
    font-style: italic;
  }
  :global {
    .spinner-border {
      width: 28px;
      height: 28px;
    }
  }
  &.isError {
    > span {
      font-style: normal;
      font-weight: 600;
      > span {
        color: $blue-9;
      }
    }
  }
}
.uploadAlert {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-right: auto;
  > span > span {
    color: $blue-9;
  }
}
.uploadSuccess {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  > p {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }
  > div {
    margin-bottom: 24px;
  }
  > span {
    display: inline-block;
    max-width: 655px;
    text-align: center;
  }
}
.deleteBtn {
  margin-right: auto;
  cursor: pointer;
}
.submitBtn, .editModeSaveBtn {
  padding: 8px 32px;
  border-radius: 8px;
  height: 44px;
  > span {
    font-size: 24px;
    font-weight: 700;
  }
}
.editModeSaveBtn {
  margin-left: auto;
  > span {
    font-weight: 500;
  }
}