@import "src/assets/scss/colors";

.StripeElement {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 14px;
  font-size: 1em;
  border: 1px solid $white-4;
  color: $white-0;
  outline: 0;
  border-radius: 4px;
  background: transparent;
}
.inputFieldStripe {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 14px;
  font-size: 1em;
  border: 1px solid $white-4;
  color: $white-0;
  outline: 0;
  border-radius: 4px;
  background: transparent;
}
.stripeError {
  display: block;
  width: 100%;
  margin-bottom: 8px;
  padding: 12px 14px;
  font-size: 1em;
  color: $white-0;
  outline: 0;
  border-radius: 4px;
  background: transparent;
  border: 1px solid $warning-btn-color;
}
.StripeElement--invalid {
  border: 1px solid $warning-btn-color;
}
