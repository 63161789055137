@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
.modalWrapper {
  max-width: 1169px;
  .modalBody {
    max-width: 100%;
    border-radius: 8px;
    border: 1px solid $blue-7;
    background: $blue-4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 70px;
      &.noMargin {
        margin-bottom: 0px !important;
      }
      .icon {
        max-width: 676px;
        max-height: 356px;
        margin: 0px 80px 35px 0px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .title {
        color: $white-0;
        font-family: "Inter";
        font-size: 34px;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 35px;
        &.completedTitle {
          max-width: 379px;
          text-align: center;
        }
      }
      .description {
        max-width: 485px;
        color: $white-0;
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        line-height: normal;
      }
    }
    .footer {
      max-width: 100%;
      margin: 70px 0px 11px 0px;
      .title {
        color: $white-0;
        text-align: center;
        font-family: "Inter";
        font-size: 22px;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 35px;
      }
      .subscriptionOption {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 14px;
        .optionWrapper {
          padding: 19px;
          .option {
            color: $white-0;
            text-align: center;
            font-family: "Inter";
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .details {
            color: $white-0;
            text-align: center;
            font-family: "Inter";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .divider {
          width: 0.5px;
          height: 93px;
          background: $white-0;
        }
      }
    }
    .submitBtn {
      margin-top: 84px !important;
      margin: 0 auto;
      max-width: 460px;
      .upgradeButton {
        margin: 0 auto;
        padding: 7px 0px !important;
      }
    }
  }
}
