@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.freeTrialModal > div {
  position: inherit !important;
}

.freeTrialModal {
  max-width: 1169px;

  :global {
    .modal-content {
      background: $blue-4;
      color: $white-0;
    }
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 0px;

    .logoWrapper {
      margin: 0px 50px 21px 0px;

      img {
        max-width: 100%;
      }
    }

    .welcomeText {
      color: #fff;
      font-family: "Poppins-Regular";
      font-size: 34px;
      font-weight: 600;
      line-height: normal;
      margin: 21px 0px;
    }

    .description {
      padding: 43px 0px;
    }

    .content {
      max-width: 667px;
      color: $white-0;
      font-family: "Poppins-Regular";
      font-size: 22px;
      > span {
        font-family: "Poppins-Bold";
      }
    }

    .footer {
      max-width: 637px;
    }
  }
}

.buttonContainer {
  display: flex;
  gap: 33px;
  margin-bottom: 50px;
  &.successButtonContainer {
    padding-top: 35px;
  }

  .button {
    margin: 0 auto;
    padding: 5px 0px !important;
    width: 457px;
    max-width: 100%;
    &.successButton {
      padding: 15px 0px !important;
      width: 353px;
    }
  }
}

.paymentSuccessText {
  margin: 38px 0 80px 0;
}