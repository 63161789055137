@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  background-color: $blue-4;
  padding: 24px;
  .header {
    text-align: left;
    width: 100%;
    .title {
      color: $white-0;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }
    .followersCount {
      color: $white-0;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }
  .followersList {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
    margin-top: 30px;
    @include responsive("lgDown") {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @include responsive("mdDown") {
      grid-template-columns: 1fr 1fr;
    }
    @include responsive("smDown") {
      grid-template-columns: 1fr;
    }
    &.spinner {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
