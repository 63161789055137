/* Import Google font */
@import "src/assets/scss/colors";
@import "src/assets/scss/variables";
@import "src/assets/scss/breakpoints";

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px 20px;
  &.disabled {
    .inner {
      position: relative;
      opacity: 0.6;
      &:before {
        content: ' ';
        display: block;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 20;
      }
    }
  }
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px $clue-game-box-spacing $clue-game-box-spacing $clue-game-box-spacing;
  border-radius: 8px;
  background: $white-0;
  width: calc(#{$clue-game-width} + #{$clue-game-box-spacing} + #{$clue-game-box-spacing} + #{$clue-game-border} + #{$clue-game-border});
  &.isModal {
    position: fixed;
    z-index: 10;
    top: 34px;
    bottom: 34px;
    left: 0;
    right: 0;
    margin: auto;
    width: calc(#{$clue-game-width} + #{$clue-game-box-spacing} + #{$clue-game-box-spacing} + #{$clue-game-border} + #{$clue-game-border} + 60px + 60px);
    border: 1px solid $blue-7;
    background: $blue-4;
    overflow-y: auto;
    overflow-x: hidden;
    .gameWrapper {
      width: calc(#{$clue-game-width} + #{$clue-game-border} + #{$clue-game-border} + 60px + 60px);
      height: calc(#{$clue-game-height} + #{$clue-game-border} + #{$clue-game-border} + 20px + 20px);
      background: $white-0;
      padding: 20px 60px;
      border: 0;
      border-radius: 8px;
    }
 }
}
.gameWrapper {
  width: calc(#{$clue-game-width} + #{$clue-game-border} + #{$clue-game-border});
  height: calc(#{$clue-game-height} + #{$clue-game-border} + #{$clue-game-border});
  position: relative;
  > div:not(.arrow) {
    width: calc(#{$clue-game-width} + #{$clue-game-border} + #{$clue-game-border});
    height: calc(#{$clue-game-height} + #{$clue-game-border} + #{$clue-game-border});
    border: 5px solid $black-11;
  }
  .arrow {
    position: absolute;
    right: -18px;
    bottom: -18px;
    background: #EFEFFF;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
  }
}