@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  .addPaymentWrapper {
    .paymentFormWrapper {
      text-align: center;
    }
  }

  .paymentDetails {
    .paymentWrapper {
      max-height: 259px;
      margin-bottom: 16px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-track {
        background: $white-3;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: $gray-14;
        border-radius: 8px;
      }
      .existingCard {
        cursor: pointer;
        margin-bottom: 16px;
        p {
          color: $black-0;
        }
        border-radius: 4px;
        border-left: 7px solid $yellow-2;
        background: $white-3;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.selected {
          border-right: 3px solid $yellow-2;
          border-top: 3px solid $yellow-2;
          border-bottom: 3px solid $yellow-2;
        }
      }
    }

    .addNew {
      cursor: pointer;
      margin-bottom: 70px;
      padding: 24px;
      border-radius: 4px;
      border: 2px dashed $white-3;
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: center;
    }
  }
  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include responsive("smDown") {
      flex-direction: column-reverse;
      gap: 8px;
    }
    &.right {
      justify-content: flex-end;
    }
    .submit {
      width: 100%;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
      }
    }
    .confirm {
      border-radius: 10px;
      span {
        border-radius: 10px;
        padding: 9px 60px;
        p {
          line-height: normal;
        }
      }
    }
  }
}
