@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.wrapper {
  background-color: $blue-4;
  padding: 16px 8px 0 8px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .imgWrapper {
      display: flex;
      gap: 17px;
      align-items: center;
      &.expandIcon {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        img {
          transform: rotate(180deg);
        }
      }

      img {
        cursor: pointer;
      }
      .totalRegions {
        color: $white-0;
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 15px;
      }
    }
    .selectAll {
      color: $white-0;
      user-select: none;
      cursor: pointer;
      margin-bottom: 0px;
      margin-right: 18px;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .loaderWrapper {
    height: calc(100vh - 196px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    max-height: calc(100vh - 196px);
    overflow-y: scroll;
    padding-right: 8px;
    @include beautyScrollbar();

    .selectedRegion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        color: $white-0;
        text-align: left;
      }
      .selectAll {
        color: $white-0;
        cursor: pointer;
        user-select: none;
        margin-bottom: 0px;
        margin-right: 18px;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .joinAllWrapper {
      color: $white-0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .joinAllText {
        margin-bottom: 0px !important;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .remainingRegion {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      p {
        color: $white-0;
        text-align: left;
      }
      .selectAll {
        color: $white-0;
        cursor: pointer;
        user-select: none;
        margin-bottom: 0px;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .divider {
      background: $white-0;
      border-radius: 20px;
      min-height: 3px;
      width: 100%;
      margin-top: 30px;
    }
    .nextButtonWrapper {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(
        180.36deg,
        rgba(255, 255, 255, 0) 13.58%,
        #ffffff 19.37%
      );
      padding: 36px 16px 36px 16px;
      .next {
        padding-top: 9px;
        padding-bottom: 9px;
        width: 100%;
        background: $purple-1 !important;
        border-radius: 8px;
        font-family: "Inter";
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
    .selectButtonWrapper {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      background: linear-gradient(
        179.36deg,
        rgba(255, 255, 255, 0) 5.58%,
        #ffffff 9.37%
      );
      padding: 36px 16px 10px 16px;
      .next {
        padding-top: 9px;
        padding-bottom: 9px;
        width: 100%;
        background: $purple-1 !important;
        border-radius: 8px;
        font-family: "Inter";
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
