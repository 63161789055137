@import "src/assets/scss/colors";

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: $white-0;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px dashed #5D629D;
  text-align: center;
  cursor: pointer;
}
.wrapper {
  input {
    display: none !important;
  }
}