@import "src/assets/scss/colors";

.leftIconStyling {
  display: flex;
  align-items: center;
}
.rightIconStyling {
  display: flex;
  align-items: center;
}

.dropDownStyling {
  .dropdownToggle {
    display: flex;
    align-items: center;
    gap: 4px;
    background: $white-0;
    color: $black-2;
    width: 100%;
    border: 1px solid $white-4;
    box-shadow: 0px 50px 100px rgba(50, 50, 93, 0.1),
      0px 15px 35px rgba(50, 50, 93, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.1);
  }

  .dropdownMenu {
    width: 100%;
    border: 1px solid $white-4;
    box-shadow: 0px 50px 100px rgba(50, 50, 93, 0.1),
      0px 15px 35px rgba(50, 50, 93, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    .dropdownItem {
      .icon {
        font-size: 16px;
        margin-right: 20px;
      }
      &:hover {
        background-color: $white-2;
      }

      &:active {
        color: $black-0;
        background-color: $white-3;
      }
      text-align: center;
    }
  }
  &.noPadding {
    .dropdownToggle {
      padding: 0;
      border: 0;
    }
  }
  &.overlay_color_gray {
    .dropdownMenu {
      background: $gray-13;
      border-color: $gray-13;
      .dropdownItem {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        text-align: left;
        justify-content: flex-start !important;
      }
    }
  }
}

.secondary-outline {
  .dropdownToggle {
    background: transparent;
    color: $white-0;
    width: 100%;
    border: 1px solid $white-0;
    box-shadow: 0px 50px 100px rgba(50, 50, 93, 0.1),
      0px 15px 35px rgba(50, 50, 93, 0.15), 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 0px;
  }

  .dropdownMenu {
    width: 100%;
    background: transparent;
    border-radius: 7px;
    .dropdownItem {
      border: none;
      color: $white-0;

      .icon {
        font-size: 16px;
        margin-right: 20px;
      }
      &:hover {
        background-color: transparent;
        font-weight: 700;
      }

      &:active {
        color: $black-0;
        background-color: transparent;
      }
      text-align: center;
    }
  }
}
