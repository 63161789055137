@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: $blue-4;
  border-radius: 8px;
  &.noRatio {
    .media {
      aspect-ratio: 0;
    }
  }
}
.media {
  width: 100%;
  aspect-ratio: 1 / 1;
  img {
    border: 3px solid $purple-3;
    border-radius: 8px;
    aspect-ratio: 1 / 1;
    object-fit: contain;
    max-width: 100%;
  }
  :global {
    .image-placeholder {
      position: relative;
      font-size: 24px;
      font-weight: 600;
      line-height: normal;
      &.not-source {
        border: 3px solid $purple-3;
        border-radius: 8px;
        aspect-ratio: 1 / 1;
        object-fit: contain;
        max-width: 100%;
        padding: 30px 15px 30px 30px;
      }
      > span {
        @include imageText();
      }
      &:not(.not-source) {
        > span {
          height: auto;
          position: absolute;
          top: 30px;
          left: 30px;
          bottom: 30px;
          right: 15px;
        }
        > img {
          object-fit: cover;
        }
      }
    }
  }
}
.info {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 400;
  > div {
    &:nth-child(1) {
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        gap: 24px;
        align-items: center;
        &:nth-child(2) {
          font-size: 16px;
          font-weight: 400;
        }
        > span {
          display: flex;
          gap: 4px;
          align-items: center;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
      text-align: left;
      > span {
        @include ellipsisLongText();
        width: 100%;
        font-weight: 400;
        font-size: 18px;
        &:nth-child(1) {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }
  }
}