@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.bellButton {
  padding: 0;
  border: 0;
  background: none !important;
  position: relative;
  :global {
    .badge {
      position: absolute;
      top: -8px;
      left: 16px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;
      padding: 0;
      height: 24px;
      padding: 4px;
      background: $red-2 !important;
    }
  }
}
.menu {
  border-radius: 8px;
  background: $blue-4;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.45);
  color: $white-0;
  padding: 16px;
  width: 350px;
  max-height: 486px;
  overflow-y: auto;
  overflow-x: hidden;
  @include beautyScrollbar();
  .menuHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
      &:nth-child(1) {
        font-size: 18px;
        font-weight: 700;
      }
      &:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        cursor: pointer;
      }
    }
  }
}
.item {
  background: transparent !important;
  border: 0 !important;
  border-bottom: 1px solid #747474 !important;
  color: $white-0 !important;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  .itemHeader {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    > span {
      &:last-child {
        opacity: 0.5;
        margin-left: auto;
      }
    }
  }
}
.itemDes {
  width: 100%;
  padding-right: 60px;
  white-space: normal;
  @include tooLongText(2);
}
.itemTitle {
  white-space: normal;
}
.itemIcon {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  .red {
    width: 8px;
    height: 8px;
    background: $red-2;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    right: 0;
  }
}
.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: default !important;
}