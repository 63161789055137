@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  padding: 0 16px;

  .signInOption {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 180px;
    margin: 0 auto;
    gap: 24px;

    .optionWrapper {
      cursor: pointer;
      display: flex;
      width: 45px;
      height: 45px;
      padding: 11px 10px 10px 11px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border: 1px solid $gray-7;
      background: $purple-12;
    }
  }

  .divider {
    margin: 32px 0px;
    text-align: center;

    .wordWithLine {
      display: inline-block;
      position: relative;
      padding: 0px 14px;
      color: $white-0;
      font-family: "Inter";
      font-size: 18px;
      line-height: normal;

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 195px;
        height: 1px;
        background-color: $gray-24;
        top: 50%;
        @include responsive("smDown") {
          width: 100px;
        }
      }

      &::before {
        right: 100%;
      }

      &::after {
        left: 100%;
      }
    }
  }

  .mainWrapper {
    margin: auto;
    padding: 1px;
    max-width: 538px;
    color: $white-0;
    overflow: auto;

    .signupInputs {
      max-width: 444px;
      margin: 0 auto;

      .formGroup {
        text-align: left !important;
        width: 100%;
        @include responsive("smDown") {
          margin-bottom: 12px !important;
        }

        .inputWrapper {
          border: none !important;
          outline: none;

          .eyeIcon {
            background-color: $white-0 !important;
          }
        }

        .input {
          background-color: $white-0;
          color: $black-0 !important;
          border-radius: 8px;
          border: 1px solid $white-0;

          &:focus,
          &:active,
          &:focus-visible {
            box-shadow: none;
            color: $black-0;
            outline: none;
            background-color: $white-0;
          }

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active {
            -webkit-transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: $black-0;
          }

          &::placeholder {
            color: $gray-26 !important;
          }
        }
      }

      .label {
        color: $white-0;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .submit {
        padding-top: 8px;
        @include responsive("smDown") {
          display: none;
        }
      }

      .errorMessage {
        color: red;
        font-size: 12px;
        margin-bottom: 20px;
      }

      .forgotPassword {
        margin-top: 32px;
        a {
          font-size: 18px;
          color: $white-0;

          &:hover {
            text-decoration-line: none;
          }
        }
      }
    }

    .mobileNext {
      display: none;
      @include responsive("smDown") {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $blue-4;
        box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
        padding: 12px 24px 34px 24px;
        z-index: 10;
        button {
          position: relative;
        }
      }
    }

    .mobileSignin {
      display: none;
      @include responsive("smDown") {
        display: block;
        margin-top: 34px;
      }
    }

    .footer {
      @include responsive("smDown") {
        margin-top: 0;
      }

      .footerLink {
        margin: 32px 0px;
        font-size: 18px;
        font-family: "Poppins-Regular";

        a {
          font-size: 18px;
          color: $white-0;

          &:hover {
            text-decoration-line: none;
          }
        }
      }

      .noAccount {
        color: $white-0;
        text-align: center;
        font-family: "Poppins-Regular";

        > span {
          cursor: pointer;
          margin-left: 5px;
          font-family: "Poppins-Bold";
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}
