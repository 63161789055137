@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.navWrapper {
  height: 67px;
  width: 100%;
  z-index: 1;
  padding: 0 16px;
  background: linear-gradient(90deg, #8053ea -26.03%, #121429 105.46%);

  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header {
      color: $white-0;
      display: flex;
      align-items: center;
      gap: 24px;
      .headerLogo {
        width: 214px;
        height: 67px;
        border-right: 1px solid $gray-8;
        border-bottom: 1px solid $gray-8;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        img {
          height: 48px;
          width: 57px;
        }
      }
      .title {
        text-align: left;
        margin-bottom: 0;
      }
      .description {
        text-align: left;
        margin-bottom: 0;
      }
    }
  }
}
.actions {
  padding-right: 16px;
}
