@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  display: flex;
  align-items: center;
  gap: 24px;
  .buttonWrapper {
    margin-bottom: 14px;
    text-align: left;
    .back {
      background: linear-gradient(94.12deg, #7c44cd 46.64%, #fe00dc 154.68%);
      border: 1px solid $white-0;
      border-radius: 8px;
      padding: 5px 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .breadcrumb {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    gap: 4px;
    p {
      text-transform: capitalize;
      color: $white-0;
    }
  }
  &.noMargin {
    .breadcrumb, .buttonWrapper {
      margin-bottom: 0;
    }
  }
  &.gap_size_xs {
    gap: 8px;
  }
}
