@import "src/assets/scss/colors";

.channelInfo {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background: $blue-4;
  padding: 24px;
  flex-wrap: wrap;
  position: relative;
  .details {
    display: flex;
    align-items: center;
    gap: 24px;
    .userImage {
      img {
        width: 128px;
        height: 128px;
        border-radius: 50%;
      }
    }
    .content {
      text-align: left;
      .name {
        color: $white-0;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 12px;
      }
      .detail {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          color: $white-0;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          &:nth-child(1) {
            font-weight: 500;
          }
        }
      }
      .description {
        overflow: hidden;
        color: $white-0;
        text-overflow: ellipsis;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        textarea {
          // resize: none;
          display: flex;
          width: 535px;
          padding: 8px;
          align-items: flex-start;
          align-content: flex-start;
          gap: 8px;
          flex-wrap: wrap;
          border-radius: 8px;
          background: rgba(224, 224, 224, 0.4);
          resize: none;
          height: 65px;
          color: white;
          &:focus-visible {
            outline: none;
          }
        }
        .content {
          display: flex;
          align-items: flex-start;
          max-width: 550px;
          .ellipse {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        /* Customize the scrollbar for WebKit browsers */
        textarea::-webkit-scrollbar {
          width: 8px; /* Adjust the width as needed */
        }

        textarea::-webkit-scrollbar-thumb {
          display: none;
        }

        /* Hide the scrollbar for Firefox */
        textarea {
          scrollbar-width: none;
          /* Set any other desired styles for the textarea here */
        }

        /* Customize the scrollbar for Firefox */
        textarea::-moz-scrollbar {
          display: none;
        }

        /* Hide the scrollbar for Microsoft browsers (IE, Edge) */
        textarea {
          -ms-overflow-style: none;
          /* Set any other desired styles for the textarea here */
        }

        /* Customize the scrollbar for Microsoft browsers */
        textarea::-ms-scrollbar {
          display: none;
        }
      }
    }
  }
  .action {
    .buttonWrapper {
      .editActions {
        display: flex;
        align-items: center;
        gap: 16px;
        button {
          padding: 4px 8px;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          color: $white-0;
          line-height: 24px;
        }
        .cancel {
          text-decoration: none;
          border: 0;
        }
      }
      .edit {
        cursor: pointer;
        display: flex;
        align-items: center;
        p {
          white-space: nowrap;
          margin-left: 4px;
        }
      }
      .actionButton {
        border-radius: 8px;
        background: $white-8;
        &.following {
          background: rgba(224, 224, 224, 0.4);
          color: $white-0;
        }
      }
    }
  }
}
.expandIcon {
  cursor: pointer;
}
.tabs {
  min-width: 100%;
  max-width: 100%;
  margin-top: 24px;
  ul {
    list-style: none;
    display: flex;
    padding: 0 0 8px 8px;
    margin: 0;
    border-bottom: 1px solid $gray-9;
    li {
      min-width: 105px;
      text-align: center;
      position: relative;
      > span {
        font-size: 18px;
        font-weight: 400;
        cursor: pointer;
        > svg {
          margin-right: 2px;
        }
      }
      &:after {
        content: " ";
        height: 4px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -13px;
        border-radius: 20px;
        background: $purple-1;
        display: none;
      }
      &:hover,
      &.activeTab {
        &:after {
          display: block;
        }
        > span {
          font-weight: 700;
        }
      }
    }
  }
}
