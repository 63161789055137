@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.closeModalWrapper {
  max-width: 750px;
  .modalBody {
    max-width: 710px;
    margin: 0 auto;
  }
  .modalHeader {
    text-align: center;
    margin-bottom: 40px;
  }
  .modalBody {
    text-align: left;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    .submit {
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      }
    }
    .cancelWrapper {
      span {
        text-decoration: none !important;
      }
    }
  }
  :global {
    .modal-content {
      border-radius: 4px;
      background: $black-4;
      color: $white-0;
      // min-height: calc(100vh - 300px);
      // max-height: calc(100vh - 80px);
      > div {
        padding: 30px 32px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
      }
    }
    .modal-header {
      padding: 0;
      border: 0;
      .modal-title {
        display: flex;
        gap: 16px;
        > span {
          margin: 0;
          display: flex;
          gap: 16px;
          align-items: flex-end;
          font-size: 30px;
          & + span {
            font-size: 16px;
            font-weight: 400;
            position: relative;
            top: -5px;
            color: $gray-16;
          }
        }
        & + span {
          color: $white-0;
          svg {
            path {
              fill: $white-0;
            }
          }
        }
      }
    }
  }
}

.wrapper {
  .subscriptionCards {
    color: $white-0;
    margin-bottom: 24px;
    text-align: center;
    .cardsWrapper {
      text-align: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      max-width: 710px;
      margin: 0 auto;
      .selectedCard {
        background: radial-gradient(
            ellipse farthest-corner at right bottom,
            #9f7928 0%,
            #fdb931 8%,
            #9f7928 30%,
            #8a6e2f 40%,
            transparent 80%
          ),
          radial-gradient(
            ellipse farthest-corner at left top,
            #ffffff 0%,
            #ffffac 8%,
            #d1b464 25%,
            #5d4a1f 62.5%,
            #5d4a1f 100%
          );
        box-shadow: 0px 2px 30px black;
      }
      @include responsive("smDown") {
        grid-template-columns: 1fr;
      }
    }
  }
  .unsubscribe {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include responsive("smDown") {
      flex-direction: column-reverse;
      gap: 8px;
    }
    &.right {
      justify-content: flex-end;
    }
  }
  .confirm {
    border-radius: 10px;
    span {
      border-radius: 10px;
      padding: 9px 60px;
      p {
        line-height: normal;
      }
    }
  }
}
