@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  width: 100%;
  background: transparent;
  .cardBodyStyles {
    padding: 0 !important;
  }
}
.content {
  // margin-bottom: 12px;
  background: $white-0;
  border-radius: 8px;
  padding: 6px;
  width: 270px;
  position: relative;
  padding-top: 32px;
  .imageWrapper {
    margin-bottom: 6px;
    text-align: center;
    > img {
      max-width: 145px;
    }
  }
  .badgeWrapper {
    // margin-bottom: 6px;
    // &.found {
    //   span {
    //     color: $gray-11;
    //     font-family: "Inter";
    //     font-weight: 500;
    //     font-size: 13px;
    //     line-height: 16px;
    //     background: $white-0 !important;
    //     border-radius: 40px;
    //     width: 100%;
    //   }
    // }
    &.joined {
      .nameBadge {
        background: #5AA332 !important;
      }
    }
    // span {
    //   font-family: "Inter";
    //   font-weight: 500;
    //   font-size: 13px;
    //   line-height: 16px;
    //   background: $blue-5;
    //   border-radius: 40px;
    //   width: 100%;
    // }
    .joinRegion {
      width: 100%;
      border-radius: 12px;
      font-family: "Inter";
      font-weight: 600;
      font-size: 14px;
      line-height: normal;
      text-transform: capitalize;
      padding: 7px 20px;
      height: 34px;
    }
    .viewClues {
      padding-top: 4px;
      padding-bottom: 4px;
      width: 100%;
      background: $white-0 !important;
      box-shadow: 0px 4px 8px 0px #01000233;
      border-radius: 10px;
      font-family: "Inter";
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: $blue-5;
      display: flex;
      justify-content: center;
      border: none;
      height: 34px;
      &:disabled {
        background: #D1D1D180 !important;
        color: #13132D80 !important;
        opacity: 1 !important;
        box-shadow: none !important;
      }
    }
  }
  .states {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;
    gap: 2px;
    margin-bottom: 8px;
    margin-top: 12px;
    padding: 0 4px;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: $black-0;
    }
    &.joinedStates {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }
  .joinedRegionSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    span {
      background: $blue-5;
      border-radius: 43.2328px;
      padding: 5px 17px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
    }
    .previewClues {
      width: 100%;
      // border: 1px solid red;
      color: #000;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      text-align: center;
      display: flex;
      text-decoration: none;
      justify-content: center;
      height: 34px;
    }
  }
}
.tooltipContentWrapper {
  border: 8px solid #493389;
  border-radius: 16px;
  &.contentJoined {
    border-color: $green-0;
  }
}
.lampFound {
  background: $white-0;
  border-radius: 8px;
  padding: 6px;
  width: 255px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    color: $black-0;
    font-family: "Inter";
    font-weight: 600;
    font-size: 11px;
  }
  .lamps {
    display: flex;
    flex-direction: row-reverse;
  }
}
.nameBadge {
  position: absolute;
  right: 8px;
  top: 8px;
  text-transform: capitalize;
}
.seasonBlock {
  margin-bottom: 8px;
}