@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.wrapper {
  display: block;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  .description {
    display: inline;
  }
  // &.expanded {
  //   display: block;
  //   .description {
  //     max-width: 100%;
  //     overflow: initial;
  //     display: inline;
  //     white-space: initial;
  //   }
  // }
}
.seeMore {
  border-radius: 4px;
  background: linear-gradient(90deg, rgba(110, 117, 191, 0.17) 0%, rgba(18, 20, 41, 0.29) 100%);
  backdrop-filter: blur(5px);
  padding: 4px 8px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  top: -1px;
}