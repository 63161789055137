@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modal {
  max-width: 550px;
  :global {
    .modal-content {
      border-radius: 14px;
      background: $black-4;
      color: $white-0;
      > div {
        padding: 48px 30px 48px 40px;
      }
    }
  }
  .body {
    padding-bottom: 24px;
  }

  .btn {
    width: fit-content;
    > span {
      font-size: 32px;
    }
  }
}
