@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.wrapper {
  width: 230px;
  height: 100%;
  background-color: $blue-4;
  transition: width 0.3s ease;
  &.collapse {
    width: 75px;
    transition: width 0.3s ease;

    .navItems {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
    .footerItems {
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }
  .sidebarWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 67px);
    border-right: 1px solid $gray-8;
    overflow-y: auto;
    @include beautyScrollbar();
    .navItems {
      user-select: none;
      padding-top: 9px;
      padding-left: 16px;
      padding-right: 8px;
      .back {
        color: $white-0;
        text-align: right;
        padding-right: 8px;
        cursor: pointer;
        &.rotate {
          transform: rotate(180deg);
          padding-right: 10px;
        }
      }
      .navItem {
        cursor: pointer;
        margin-bottom: 4px;
        color: $white-0;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 8px;
        &.hasSub {
          flex-wrap: wrap;
        }
      }
      .active {
        background-color: $purple-0;
        border-radius: 8px;
      }
      .navSubs {
        .navItem {
          padding-left: 36px;
          min-height: 48px;
        }
      }
    }
    .footerWrapper {
      .footerItems {
        padding-left: 16px;
        padding-right: 8px;
        .navItem {
          cursor: pointer;
          user-select: none;
          margin-bottom: 4px;
          color: $white-0;
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 8px;
          text-decoration: none;
        }
      }
      .footerLogo {
        padding-top: 16px;
        text-align: center;
        margin-bottom: 24px;
        &.addPadding {
          padding-left: 26px;
          text-align: left;
        }
        img {
          max-width: 61px;
        }
      }
    }
  }
}
.navSubs {
  min-width: 100%;
  display: flex;
  flex-direction: column;
}
