@import "src/assets/scss/colors";

.paymentLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px;
  height: 500px;
  p {
    max-width: 280px;
    text-align: center;
  }
}

.success {
  display: flex;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    margin-top: 32px;
    margin-bottom: 95px;
  }
  .button {
    text-align: center;
    text-align: center;
    width: 100%;
    .seeResults {
      width: 100%;
      max-width: 274px;
      border: none;
      padding: 16px 24px;
      background: linear-gradient(115deg, #8053ea 1.53%, #3835a0 138.07%);
      border-radius: 8px;
      margin-bottom: 70px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.wrapper {
  p {
    color: $white-0;
  }
  .title {
  }
  .info {
    margin-bottom: 40px;
  }
  .paymentDetailsWrapper {
    max-width: 538px;
    margin: 0 auto;
    padding-bottom: 20px;
    .newPaymentButton {
      text-align: center;
      border: none;
      padding: 4px 35px;
      background: linear-gradient(115deg, #8053ea 1.53%, #3835a0 138.07%);
      border-radius: 8px;
      span {
        display: flex;
        justify-content: center;
      }
    }
    .selectClassName {
      :global {
        .rselect__placeholder {
          color: $input-placeholder-color;
          font-family: "Inter";
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
        }
        .rselect__single-value {
          color: $black-0 !important;
        }
        .rselect__input-container {
          color: $black-0 !important;
          .rselect__input {
            color: $black-0;
          }
        }
        .rselect__control {
          border: 1px solid $gray-7;
          border-radius: 4px;
          font-size: 16px;
          padding: 5px 1px;
          color: $black-0;
          background: $white-3;
        }
      }
    }
    .paymentInputGroup {
      background: $white-3;
      input {
        background: $white-3;
        color: $black-0;
      }
    }
    .newPaymentWrapper {
      margin-top: 20px;
      padding: 0;
      :global {
        .StripeElement {
          display: block;
          width: 100%;
          margin-bottom: 8px;
          padding: 12px 14px;
          font-size: 1em;
          border: 1px solid $white-4;
          color: $black-0 !important;
          outline: 0;
          border-radius: 4px;
          border-radius: 4px;
          background: $white-3;
        }
        .inputFieldStripe {
          display: block;
          width: 100%;
          margin-bottom: 8px;
          padding: 12px 14px;
          font-size: 1em;
          border: 1px solid $white-4;
          color: $black-0 !important;
          outline: 0;
          border-radius: 4px;
          border-radius: 4px;
          background: $white-3;
        }
        .stripeError {
          display: block;
          width: 100%;
          margin-bottom: 8px;
          padding: 12px 14px;
          font-size: 1em;
          color: $black-0 !important;
          outline: 0;
          border-radius: 4px;
          border-radius: 4px;
          background: $white-3;
          border: 1px solid $warning-btn-color;
        }
        .StripeElement--invalid {
          border: 1px solid $warning-btn-color;
        }
      }
    }
  }
  .paymentDetailsWrapperClass {
    max-width: 638px;
    margin: 0 auto;
    .paymentDetails {
      .paymentWrapper {
        max-height: 180px;
        margin-bottom: 16px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
          background: $white-3;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: $gray-14;
          border-radius: 8px;
        }
        .existingCard {
          cursor: pointer;
          margin-bottom: 16px;
          p {
            color: $black-0;
          }
          border-radius: 4px;
          border-left: 7px solid $purple-1;
          border-right: none;
          border-bottom: none;
          border-top: none;
          background: $white-3;
          padding: 12px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &.active {
            border-right: 2px solid $purple-1;
            border-bottom: 2px solid $purple-1;
            border-top: 2px solid $purple-1;
          }
          svg {
            height: 18px;
            width: 18px;
            circle {
              fill: $purple-1;
            }
          }
        }
      }

      .addNew {
        cursor: pointer;
        margin-bottom: 32px;
        padding: 24px;
        border-radius: 4px;
        border: 2px dashed $white-3;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
      }
    }
    .terms {
      margin-bottom: 24px;
      :global {
        .custom-checkbox {
          align-items: center;
        }
      }
    }
    .button {
      text-align: center;
      max-width: 274px;
      margin: 0 auto;
      .submit {
        width: 100%;
        border: none;
        padding: 16px 24px;
        background: linear-gradient(115deg, #8053ea 1.53%, #3835a0 138.07%);
        border-radius: 8px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
