@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  max-width: 430px;
  margin: 0 auto;
  @include responsive("smDown") {
    padding: 0 20px;
  }
  .signupInputs {
    .formGroup {
      text-align: left;
      width: 100%;

      .label {
        color: $white-0;
        font-family: "Inter";
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }

      .inputWrapper {
        outline: none !important;
        border: none !important;

        > span {
          background-color: $white-0 !important;
        }
      }

      .input {
        background-color: $white-0;
        color: $black-0 !important;
        border-radius: 3px;

        &:focus,
        &:active,
        &:focus-visible {
          box-shadow: none;
          color: $black-0;
          outline: none;
          background-color: $white-0;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: $black-0;
        }

        &::placeholder {
          color: $gray-26 !important;
        }
      }

      .passwordNotMatched {
        margin-top: 10px;
        color: $warning-btn-color;

        svg {
          width: 5px;
          margin-right: 8px;
        }
      }

      .passwordMatched {
        margin-top: 10px;
        color: $white-0;

        svg {
          height: 15px;
          width: 20px;
          color: $green-1;
          margin-right: 8px;
        }
      }

      .passwordValidations {
        margin-top: 15px;
        display: grid;
        grid-template-columns: 2fr 1.5fr 2fr;
        grid-row-gap: 8px;
        grid-column-gap: 15px;

        .invalid {
          color: $orange-0 !important;
        }

        .message {
          align-items: center;
          display: flex;
          gap: 4px;
          color: $white-0;
          font-family: "Inter";

          > p {
            font-size: 12px;
          }

          .iconWrapper {
            width: 16px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          .checkIcon {
            path {
              fill: $green-1;
            }
          }

          .period {
            width: 5px;
            color: $orange-0 !important;
          }
        }
      }
    }

    .buttonContainer {
      margin-top: 43px;

      &.error {
        margin-top: 3px !important;
      }
    }
  }

  .recaptcha {
    text-align: left;
    padding: 14px 0px 60px 0px;
    color: $white-0;
  }

  .tAndC {
    margin-top: 28px;
    color: $white-0;
    font-family: "Inter";
    font-size: 24px;
    line-height: normal;

    > span {
      margin-left: 5px;
      cursor: pointer;
      font-weight: 700;
    }
  }
}
