.status {
  min-width: 15px;
  max-width: 15px;
  min-height: 15px;
  max-height: 15px;
  border-radius: 50%;
  background: transparent;
  position: relative;
  top: 1px;
  &.Live {
    background: #6CBD3F;
  }
  &.Activates {
    background: #E5C85F;
  }
}
.wrapper {
  display: flex;
  gap: 12px;
  align-items: center;
  font-weight: 400;
  b {
    font-weight: 600;
  }
}
.text {
  // min-width: 280px;
  text-align: left;
}