@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
.arvo {
  font-family: "Arvo-Regular";
  margin-right: 12px;
}

.wrapper {
  margin-top: -100px;
  padding-top: 100px;
  padding-bottom: 45px;
  @include responsive("mdDown") {
    margin-top: 60px;
  }
  .container {
    max-width: 1600px;
    padding-left: 32px;
    padding-right: 32px;
    margin-left: auto;
    margin: 0 auto;
    @include responsive("mdDown") {
      padding-left: 16px;
      padding-right: 16px;
    }
    .content {
      @include responsive("smDown1") {
        p {
          text-align: center !important;
        }
      }
      p {
        text-align: left;
        font-size: 45px;
        line-height: 52px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
      .title {
        color: $white-0;
        img {
          background-color: $black-0;
          border-radius: 16px;
        }
      }
      .italicText {
        color: $yellow-3;
        max-width: 1135px;
        line-height: 56px;
      }
      .haveFunText {
        color: $white-0;
      }
      button {
        @include responsive("smDown1") {
          margin-top: 64px;
        }
        @include responsive("smDown") {
          width: 100%;
          span {
            font-size: 28px;
            line-height: 33px;
          }
        }
      }
    }
    button {
      span {
        font-size: 45px;
        line-height: 54px;
        padding: 3px 10px;
      }
    }
    .carpetImage {
      max-width: 100%;
    }
  }
}
