@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.modalWrapper {
  :global {
    .modal-content {
      border-radius: 8px;
      background: $blue-4;
    }
  }
  &:global(.modal-dialog) {
    max-width: 650px;
  }
  &.step_2 {
    &:global(.modal-dialog) {
      max-width: 510px;
    }
    .modalBody {
      padding: 24px;
    }
  }
}
.modalBody {
  color: $white-0;
  padding: 26px 38px 38px 38px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 18px;
    li {
      display: flex;
      :global {
        .custom-checkbox {
          display: flex;
          align-items: center;
          gap: 14px;
          > input {
            min-width: 20px;
            height: 20px;
          }
          .custom-control-label {
            padding: 0 !important;
            > span {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 15px;
              font-weight: 500;
              line-height: normal;
              > svg {
                width: 13px;
                height: 13px;
              }
            }
          }
        }
      }
    }
  }
  .input {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    textarea {
      min-height: 100px;
    }
    label {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 12px;
    }
  }
  > span {
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
  }
}
.tooltip {
  :global {
    .arrow.tooltip-arrow {
      display: none !important;
    }
    .tooltip-inner {
      border-radius: 8px;
      border: 1px solid $white-0;
      background: linear-gradient(142deg, rgba(52, 52, 52, 1) 0%, rgba(0, 0, 0, 1) 100%);
      padding: 12px;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }
    .tooltip {
      --bs-tooltip-max-width: 305px;
    }
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 22px;
  align-items: center;
  margin-top: 28px;
}
.header {
  border-bottom: 1px solid $gray-9;
  padding-bottom: 19px;
  margin-bottom: 19px;
  h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
}
.thankWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  > svg {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    circle {
      fill: $white-0;
    }
    path {
      stroke: $purple-1;
    }
  }
  > h3 {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $gray-9;
    padding-bottom: 14px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }
  > p {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    text-align: center;
    width: 370px;
  }
}