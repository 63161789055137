@import "src/assets/scss/colors";

.paymentLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px;
  p {
    max-width: 280px;
    text-align: center;
  }
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .button {
    text-align: center;
    .viewHint {
      border: none;
      padding: 4px 35px;
      background: $purple-1;
      border-radius: 8px;
      span {
        display: flex;
        align-items: center;
      }
    }
  }
}
.modalWrapper {
  max-width: 702px;
  :global {
    .modal-content {
      border-radius: 8px;
      border: 1px solid $blue-7;
      background: $blue-4;
      color: $white-0;
      // min-height: calc(100vh - 300px);
      // max-height: calc(100vh - 80px);
      > div {
        padding: 30px 32px;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex: 1;
      }
    }
    .modal-header {
      padding: 0;
      border: 0;
      .modal-title {
        display: flex;
        gap: 16px;
        > span {
          margin: 0;
          display: flex;
          gap: 16px;
          align-items: flex-end;
          font-size: 30px;
          & + span {
            font-size: 16px;
            font-weight: 400;
            position: relative;
            top: -5px;
            color: $gray-16;
          }
        }
        & + span {
          color: $white-0;
          svg {
            path {
              fill: $white-0;
            }
          }
        }
      }
    }
  }
  .modalBody {
    p {
      color: $white-0;
    }
    .title {
    }
    .info {
    }
    .paymentDetails {
      .paymentWrapper {
        max-height: 259px;
        margin-bottom: 16px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
          background: $white-3;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: $gray-14;
          border-radius: 8px;
        }
        .existingCard {
          cursor: pointer;
          margin-bottom: 16px;
          p {
            color: $black-0;
          }
          border-radius: 4px;
          border-left: 7px solid $purple-1;
          border-right: 2px solid $purple-1;
          border-bottom: 2px solid $purple-1;
          border-top: 2px solid $purple-1;
          background: $white-3;
          padding: 12px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            height: 18px;
            width: 18px;
            circle {
              fill: $purple-1;
            }
          }
        }
      }

      .addNew {
        cursor: pointer;
        margin-bottom: 70px;
        padding: 24px;
        border-radius: 4px;
        border: 2px dashed $white-3;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
      }
    }
    .terms {
      :global {
        .custom-checkbox {
          align-items: center;
        }
      }
    }
    .button {
      text-align: center;
      .submit {
        border: none;
        padding: 4px 35px;
        background: $purple-1;
        border-radius: 8px;
        span {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
