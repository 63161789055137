@import "src/assets/scss/colors";

.modalWrapper {
  padding: 32px 24px;
}
.modalContainer {
  &.hasHeader {
    :global {
      .modal-header {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        .modal-close-btn {
          cursor: pointer;
          path {
            fill: $white-0;
          }
        }
      }
      .modal-body {
        padding: 16px 0;
      }
    }
  }
  &.color_primary {
    :global {
      .modal-content {
        background: $blue-4;
        color: $white-0;
      }
    }
  }
  &.color_black {
    :global {
      .modal-content {
        background: $black-4;
        color: $white-0;
      }
    }
  }
}
