@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.videoWrapper {
  position: relative;
  background: $blue-4;
  border-radius: 8px 8px 0 0;
  @include mediaBackground();
  &.fixedVideo {
    height: calc(100vh - 315px);
    .video {
      padding: 8px;
      position: fixed;
      right: 24px;
      bottom: 24px;
      min-width: 505px;
      max-width: 505px;
      z-index: 10;
      min-height: 309px;
      max-height: 309px;
      background: $blue-4;
      border-radius: 4px;
      @include mediaBackground();
      video {
        min-height: 293px;
        max-height: 293px;
        border: 0;
        border-radius: 4px;
      }
      & + div {
        position: fixed;
        right: 24px;
        bottom: 33px;
        min-width: 505px;
        max-width: 505px;
        left: auto;
        z-index: 11;
        padding: 0 12px;
        > div {
          &:nth-child(1) {
            order: 2;
          }
        }
      }
    }
  }
  .closeFixed {
    position: fixed;
    bottom: 294px;
    right: 40px;
    z-index: 11;
    width: 24px;
    height: 24px;
    display: flex;
    cursor: pointer;
    svg {
      path {
        fill: $white-0;
      }
    }
  }
  &.isImage {
    padding: 32px;
    height: calc(100vh - 315px);
    img {
      height: 100%;
      border: 3px solid $purple-3;
      border-radius: 8px;
      max-width: 100%;
    }
    :global {
      .image-placeholder {
        height: 100%;
        border: 3px solid $purple-3;
        border-radius: 8px;
        font-size: 48px;
        font-weight: 600;
        line-height: 150%;
        > span {
          @include imageText();
        }
        &.not-source {
          width: calc(100% - 260px);
          margin: auto;
          padding: 30px 15px 30px 30px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        &:not(.not-source) {
          position: relative;
          display: inline-block;
          > span {
            height: auto;
            position: absolute;
            top: 30px;
            left: 30px;
            bottom: 30px;
            right: 15px;
          }
          > img {
            border: 0;
          }
        }
      }
    }
    @include responsive("smDown") {
      padding: 12px;
    }
    @include responsive("xsDown") {
      padding: 1px;
      height: 350px;
      img {
        border: 0;
      }
    }
  }
  &:global(.fullscreen) {
    .video {
      height: 100% !important;
    }
  }
}
.video {
  position: relative;
  max-width: 100%;
  display: flex;
  height: calc(100vh - 315px) !important;
  min-height: 640px;
  padding: 32px;
  video {
    border: 3px solid $purple-3;
    border-radius: 8px;
    padding: 1px 0;
    // object-fit: fill;
  }
  @include responsive("smDown") {
    padding: 12px;
  }
  @include responsive("xsDown") {
    padding: 1px;
    height: 350px !important;
    min-height: 350px;
    video {
      border: 0;
    }
  }
}
.info {
  border-radius: 0 0 8px 8px;
  background: $blue-4;
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 16px 24px 24px 24px;
  font-weight: 400;
  font-size: 16px;
  > div:not(.bottom) {
    display: flex;
    align-items: center;
  }
  .top {
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
    > div {
      display: flex;
      gap: 8px;
    }
  }
  .userProfile {
    display: flex;
      gap: 8px;
    cursor: pointer;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    > span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      > span {
        &:nth-child(2) {
          font-size: 12px;
        }
      }
    }
  }
  .middle {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    > span {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .bottom {
    font-size: 20px;
  }
  @include responsive("smDown") {
    padding: 12px 12px 18px 12px;
    gap: 8px;
    .middle {
      > span {
        font-weight: 500;
      }
    }
    .bottom {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
.socialButtons {
  display: flex;
  gap: 8px;
}
.tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.viewMobile {
  display: flex;
  gap: 12px;
  align-items: center;
  > span {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  svg {
    height: 24px;
    width: 24px;
  }
}
.userProfileWrapperMobile {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start !important;
}
