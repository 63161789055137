@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  align-items: center;
  padding: 0 16px;
  .header {
    max-width: 575px;
    margin: 0 auto;
  }
  .emailSentMainWrapper {
    margin: auto;
    padding: 1px;
    max-width: 538px;
    color: $white-0;
    overflow: auto;
    .checkIcon {
      max-width: 181px;
      margin-bottom: 43px;
      @include responsive("smDown") {
        max-width: 83px;
        margin-bottom: 24px;
      }
    }
    .content {
      color: $white-0;
      max-width: 617px;
      margin: 0 auto 16px;
      @include responsive("smDown") {
        margin-bottom: 70px;
      }
      .welcomeText {
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
        @include responsive("smDown") {
          font-size: 24px;
          line-height: normal;
        }
      }
      .infoText {
        @include responsive("smDown") {
          font-size: 16px;
          line-height: normal;
          max-width: 254px;
          margin: 0 auto;
        }
      }
    }
    .footer {
      margin-top: 270px;
      @include responsive("smDown") {
        margin-top: 0;
      }
      .footerLink {
        display: flex;
        gap: 7px;
        font-size: 12px;
        justify-content: center;
        a {
          font-family: Inter;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: $white-0;
          &:hover {
            text-decoration-line: none;
          }
        }
      }
    }
  }
  .mainWrapper {
    margin: auto;
    padding: 1px;
    max-width: 538px;
    color: $white-0;
    overflow: auto;
    .signupInputs {
      .formGroup {
        text-align: left !important;
        @include responsive("smDown") {
          margin-bottom: 12px !important;
        }
      }
      .label {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        font-family: "Inter";
      }
      .submit {
        @include responsive("smDown") {
          display: none;
        }
        .button {
          width: 80%;
          margin: auto;
          margin-top: 40px;
          span {
            font-size: 32px;
            line-height: 39px;
          }
        }
      }

      .errorMessage {
        color: red;
        font-size: 12px;
        margin-bottom: 20px;
      }
    }

    .mobileNext {
      display: none;
      @include responsive("smDown") {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $black-4;
        box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.25);
        padding: 12px 24px 34px 24px;
        z-index: 10;
        button {
          position: relative;
          span {
            font-size: 18px;
            line-height: normal;
          }
        }
      }
    }

    .mobileSignin {
      display: none;
      @include responsive("smDown") {
        display: block;
        margin-top: 34px;
      }
    }

    .footer {
      margin-top: 270px;
      @include responsive("smDown") {
        margin-top: 0;
      }
      .footerLink {
        display: flex;
        gap: 7px;
        font-size: 12px;
        justify-content: center;
        a {
          font-family: Inter;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: $white-0;
          &:hover {
            text-decoration-line: none;
          }
        }
      }
    }
  }
}
