.wrapper {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 32px;
  > span {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}