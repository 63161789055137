@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  margin: 0 auto;
  background-color: black;
  padding: 20px 0px 45px 0px;
  max-width: 1276px;
  @include responsive("smDown") {
    padding: 20px 16px 45px 16px;
  }
  .title {
    margin-bottom: 32px;
    p {
      color: $white-0;
      text-align: left;
      font-weight: 400;
      font-size: 30px;
      line-height: 38px;
    }
  }
  .gameCard {
    max-width: 949px;
    margin: 0 auto;
    border-radius: 8px;
    border: 10px solid $yellow-5;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $blue-4;
      padding: 10px 24px;
      .title {
        text-align: left;
        color: $white-0;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
      }
      .type {
        text-align: left;
        margin-bottom: 0;
        color: $white-0;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
      }
    }
    .content {
      padding: 24px;

      .card {
        background: rgba(18, 20, 41, 0.88);
        border-radius: 8px;
        width: 100%;
        height: 100%;
        padding: 46px 22px 0 22px;
        position: relative;
        .badge {
          border: 1.5px solid $white-10;
          border-radius: 8px;
          font-family: "Inter";
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          padding: 8px 16px;
          background-color: $yellow-5;
          position: absolute;
          right: -10px;
          top: -9px;
        }
        .details {
          color: $white-0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: 36px;
          @include responsive("smDown") {
            gap: 24px;
            flex-direction: column;
          }
          .detail {
            display: flex;
            align-items: center;
            gap: 15px;
            .imageWrapper {
              width: 53px;
            }
            div {
              p {
                text-align: left;
              }
            }
          }
          .detail:nth-child(1) {
            margin-right: 58px;
          }
          .detail:nth-child(2) {
            margin-right: 74px;
          }
        }
        .lamp {
          margin-bottom: 48px;
          img {
            max-width: 100%;
            width: 416px;
          }
        }
        .action {
          padding-bottom: 27px;
          .button {
            border-radius: 8.49587px;
            span {
              border: none;
              font-family: "Impact";
              font-style: normal;
              font-weight: 400;
              font-size: 32px;
              line-height: 39px;
              padding: 2px 50px;
              // text-shadow: -1px 0 $black-0, 0 1px $black-0, 1px 0 $black-0,
              //   0 -1px $black-0;
              -webkit-text-stroke-width: 1px;
              @include responsive("smDown") {
                padding: 2px 0px;
              }
            }
          }
          &.actionForAdmin {
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            gap: 50px;
            flex-wrap: wrap;
            @include responsive("md") {
              flex-direction: column;
              gap: 10px;
              color: #fff;
              border-radius: 10px;
            }
            @include responsive("smDown") {
              flex-direction: column;
              gap: 10px;
              color: #fff;
              border-radius: 10px;
            }
            .button {
              span {
                padding: 2px 34px;
              }
              @include responsive("md") {
                width: max-content;
                border-radius: 11.582px;
                span {
                  border-radius: 10px;
                  padding: 9px 30px;
                  border: 1.316px solid #141414;
                }
              }
              @include responsive("smDown") {
                width: max-content;
                border-radius: 11.582px;
                span {
                  border-radius: 10px;
                  padding: 9px 30px;
                  border: 1.316px solid #141414;
                }
              }
            }
          }
        }
      }
    }
  }
}
