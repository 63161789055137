@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.footerWrapper {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  justify-content: space-between;
  padding: 48px 64px 65px;
  margin: 0 auto;
  @include responsive("smDown") {
    @include footerMobile();
  }
  .leftSide {
    display: flex;
    flex-direction: column;
    gap: 18px;
    .tgsButton {
      width: 100px;
    }
    @include responsive("smDown") {
      align-items: center;
      > img {
        display: block !important;
      }
    }
  }
  .copyRight {
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: left;
    color: $white-0;
    margin-bottom: 2px;
  }
  .button {
    color: $white-0;
    max-width: 514px;
    @include responsive("smDown") {
      width: 266px;
      padding-bottom: 30px;
      border-bottom: 1px solid #3E3E3E;
    }
    span {
      font-family: "Impact";
      font-style: normal;
      font-weight: 400;
      font-size: 45px;
      line-height: 54px;
      padding: 3px 25px;
      @include responsive("smDown") {
        font-size: 28px;
        line-height: 33px;
      }
    }
    p {
      @include responsive("smDown") {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;
        padding: 0 30px;
      }
    }
    button {
      @include responsive("smDown") {
        width: auto;
      }
    }
  }
  .footerLinks {
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-weight: 500;
    @include responsive("smDown") {
     display: none !important;
    }
    > img {
      display: none;
    }
    a {
      text-align: right;
      cursor: pointer;
      color: $white-0;
      @include responsive("smDown") {
        text-align: left;
      }
      &:hover {
        color: $white-0;
        text-decoration: underline;
      }
    }
  }
  .links {
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    font-family: "Inter";
    a {
      cursor: pointer;
      color: $white-0;
      text-decoration: underline;
      &:hover {
        color: $white-0;
        text-decoration: none;
      }
    }
    span {
      height: 3px;
      width: 3px;
      border-radius: 50%;
      background: $white-0;
    }
  }
}
