@import "src/assets/scss/colors";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  :global {
    .pagination {
      margin: 0;
      color: $white-0;
      .page-item {
        border-left: 0;
        border-right: 0;
        > a {
          background: transparent;
          color: $white-0;
          border-color: rgba(179, 179, 179, 1);
          border-left: 0;
          border-right: 0;
          border-width: 0.5px;
          font-size: 12px;
          font-weight: 400;
          padding: 6px 12px;
          box-shadow: none !important;
        }
        &:not(.disabled):hover, &.active {
          > a {
            background: $purple-1;
          }
        }
        &.page-previous {
          > a {
            border-left: 0.5px solid rgba(179, 179, 179, 1);
            border-radius: 4px 0 0 4px;
          }
        }
        &.page-next {
          > a {
            border-right: 0.5px solid rgba(179, 179, 179, 1);
            border-radius: 0 4px 4px 0;
          }
        }
        &.page-previous, &.page-next {
          display: flex;
          > a {
            padding-left: 8px;
            padding-right: 8px;
            display: flex;
            align-items: center;
            gap: 6px;
            > span {
              display: inline-block;
              line-height: 18px;
            }
          }
        }
        &.disabled {
          cursor: not-allowed;
          > a > * {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
.totalDisplay {
  font-size: 14px;
  font-weight: 400;
}