@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";
@import "src/assets/scss/mixins";

.footerWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 45px;
  max-width: 1440px;
  margin: 0 auto;
  @include responsive("smDown") {
    @include footerMobile();
    .button {
      display: block !important;
      > button {
        display: none;
      }
    }
  }
  .upperContainer {
    margin-bottom: 80px;

    .button {
      @include responsive("smDown") {
        display: none;
      }
      .footerText {
        color: $white-0;
        font-family: "Poppins-Bold";
        font-size: 40px;
        line-height: normal;
        margin-bottom: 54px;
      }
      .downloadBtn {
        color: $white-0;
        border-radius: 4px;
        border: 1px solid #d28bec;
        background: linear-gradient(257deg, #600083 1.08%, #10001a 105.53%);
      }
      img {
        height: 96px;
        width: 96px;
      }
    }
  }
  .lowerContainer {
    display: flex;
    justify-content: space-between !important;
    @include responsive("smDown") {
      align-items: center;
      flex-direction: column;
      gap: 10px;
    }
    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 18px;
      @include responsive("smDown") {
        align-items: center;
      }

      .copyRight {
        display: flex;
        align-items: center;
        gap: 4px;
        text-align: left;
        color: $white-0;
        margin-bottom: 2px;
        font-family: "Poppins-Regular";
      }
      .links {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        font-family: "Poppins-Regular";
        a {
          cursor: pointer;
          color: $white-0;
          text-decoration: none;
          &:hover {
            color: $white-0;
            text-decoration: none;
          }
        }
        span {
          height: 3px;
          width: 3px;
          border-radius: 50%;
          background: $white-0;
        }
      }
    }
    .rightSection {
      .footerLinks {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        font-family: "Poppins-Medium";
        @include responsive("smDown") {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 8px;
        }

        a {
          text-align: right;
          cursor: pointer;
          color: $white-0;
          text-decoration: none;
          @include responsive("smDown") {
            text-align: left;
          }
          &:hover {
            color: $white-0;
            text-decoration: none;
          }
        }
      }
    }
  }
}
