.content {
  position: relative;
  margin-right: 40px;
  margin-top: 24px;
  margin-left: 40px;

  .leftIcon {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 16px);
    left: -40px;
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
  .imageWrapper {
    padding: 16px;
    img {
      width: 100%;
      aspect-ratio: 3/2;
      border-radius: 6px;
    }
  }
  .rightIcon {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 16px);
    right: -40px;
    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}
