@import "src/assets/scss/colors";

.thumbnailIcon {
  display: flex;
  width: 43px;
  height: 43px;
  background: $blue-4;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  > svg {
    width: 24px;
    height: 24px;
  }
}
.uploadThumbnail {
  width: 103px;
  height: 103px;
}
.list {
  display: flex;
  gap: 8px;
}
.thumbnailItem {
  width: 103px;
  height: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(50, 55, 113, 0.50);
  border-radius: 4px;
  border: 1px solid #25273E;
  opacity: 0.7;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &:hover, &.active {
    border-color: #5D629D;
    opacity: 1;
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}