@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.container {
  .content {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 9fr 5fr;
    gap: 24px;
    .leftSide {
      .imageContainer {
        position: relative;
        img {
          margin-top: 15px;
          width: 100%;
          max-width: 100%;
          height: 415px;
        }
        .info {
          display: flex;
          text-align: center;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          .packDetail {
            background-color: #1e1d1b;
            margin-top: 10px;
            background-image: linear-gradient(315deg, #1e1d1b 0%, #a78e5c 54%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            line-height: normal;
            font-size: 60px;
            @include responsive("smDown") {
              font-size: 40px;
            }
          }
        }
      }
    }
    .rightSide {
      .actions {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        margin-top: 15px;
        .button {
          padding: 16px 24px;
          border-radius: 8px;
          border: none;
          background: linear-gradient(115deg, #8053ea 1.53%, #3835a0 138.07%);
        }
        .availableOn {
          font-style: italic;
          padding-left: 35px;
        }
        .alreadyPurchased {
          font-style: italic;
          padding-left: 5px;
        }
      }
    }
  }
}
.detailsBlock {
  margin: 40px 0;
}

.detailsBlockInner {
  background: white;
  border-radius: 8px;
  padding: 22px 32px;
  color: #121429;
  text-align: left;
}