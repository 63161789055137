@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.hintSectionWrapper {
  margin-top: -1px;
  display: grid;
  gap: 16px;
  grid-template-columns: 30px 1fr;
  align-items: center;

  .hintSection {
    padding: 8px;
    width: 100%;
    border-radius: 2px 0px 0px 2px;
    border: 1px solid $gray-7;
    background: rgba(230, 221, 250, 0.48);
    display: flex;
    align-items: baseline;
    text-align: left;
    gap: 4px;

    .text {
      display: inline-flex;
      line-height: 15px;
      align-items: inherit;
    }

    .hint {
      color: $blue-4;
      font-family: Inter;
      font-size: 14px;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      :global {
        .show-more-less-clickable {
          color: $purple-1;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-decoration: none;
        }
      }
    }
  }
}

.inputWrapper {
  margin-bottom: 16px;

  .purchaseWrapper {
    display: grid;
    gap: 16px;
    grid-template-columns: 30px 1fr;
    align-items: center;

    .purchase {
      width: fit-content;
      border: 1px solid $purple-1 !important;
      color: $purple-1 !important;
      background-color: transparent !important;
      padding: 7px 20px;
      display: flex;
      align-items: center;
    }
  }

  .formGroup {
    display: grid;
    gap: 16px;
    margin-bottom: 0 !important;
    grid-template-columns: 30px 1fr;
    align-items: center;

    .retrySpinIcon {
      animation: spin 1s linear;
    }

    .activeCircle {
      circle {
        stroke: $blue-4;
      }
    }

    .inputGroupStyles {
      border: 1px solid $gray-7;
      outline: none;
      align-items: baseline !important;
      box-shadow: none;

      &.inputGroupActive {
        border: 1px solid $blue-4 !important;
      }

      &:focus {
        border: 1px solid $gray-7;
        outline: none;
      }
    }

    .inputStyle {
      color: $blue-4;
      min-height: 46px;
      height: auto;
      resize: none;
      padding: 8px 16px;
      background: transparent !important;

      &::placeholder {
        color: $gray-20;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        font-family: "Inter";
        vertical-align: middle;
      }
    }

    .actionStyling {
      background: transparent !important;
      padding: 8px 16px;
    }
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;
  gap: 12px;

  .clear {
    cursor: pointer;
    color: $black-0;

    &.disable {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .correctText {
    color: $green-0;
  }

  .divider {
    color: $black-0;
  }

  .buttonWrapper {
    border-left: 1px solid $black-0;
    padding-left: 12px;

    &.disable {
      opacity: 0.5;
      pointer-events: none;
      border-left: 1px solid $gray-0;
    }

    .button {
      border: none;
      background: linear-gradient(
                      91.81deg,
                      #7c44cd 10.66%,
                      #b328d4 62.92%,
                      #f5b500 104.68%
      );
      border-radius: 8px;
      padding: 5px 20px;

      span {
        display: flex;
        align-items: center;
      }
    }
  }
}


@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
