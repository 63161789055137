@import "src/assets/scss/colors";
@import "src/assets/scss/breakpoints";

.wrapper {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include responsive("smDown") {
    padding-top: 0;
  }
  .tgMobileImg {
    display: none;
    @include responsive("smDown") {
      display: block;
      margin-bottom: 74px;
      img {
        max-width: 277px;
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
  .checkIcon {
    max-width: 181px;
    margin-bottom: 43px;
    @include responsive("smDown") {
      max-width: 83px;
      margin-bottom: 24px;
    }
  }
  .content {
    color: $white-0;
    text-align: center;
    max-width: 617px;
    margin: 0 auto 0px;

    .welcomeText {
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;
      @include responsive("smDown") {
        font-size: 24px;
        line-height: normal;
      }
    }
    .infoText {
      @include responsive("smDown") {
        font-size: 16px;
        line-height: normal;
        max-width: 254px;
        margin: 16px auto;
      }
    }
  }
  .changeSubscriptionText {
    text-align: center;
    width: 80%;
    @include responsive("smDown") {
      font-size: 16px;
      line-height: normal;
      max-width: 254px;
      margin: 0 auto;
    }

    .validTillText {
      margin-bottom: 0px;
    }
  }
  .buttonWrapper {
    @include responsive("smDown") {
      width: 100%;
      background: $black-4;
      z-index: 10;
      text-align: center;
      button {
        position: relative;

        span {
          font-size: 18px;
          line-height: normal;
        }
      }
    }

    .button {
      margin-top: 17px;
      width: 100%;

      span {
        font-weight: 400;
        font-size: 32px;
        line-height: 39px;

        @include responsive("smDown") {
          font-size: 27px;
        }

        @include responsive("xsDown") {
          font-size: 22px;
        }
      }
    }
  }
  .accessOnMobile {
    // display: none;
    @include responsive("smDown") {
      .access {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  .footer {
    margin-top: 50px;
    margin-bottom: 50px;
    color: $white-0;
    @include responsive("smDown") {
      display: none;
    }
    .footerText {
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 32px;
    }
  }
}
