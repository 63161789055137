@import "src/assets/scss/colors";

.actionButton {
  &.following {
    > span {
      &:nth-child(1) {
        align-items: center;
        justify-content: center;
        > svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .title {
      color: $white-0;
    }
    .followingIconColor {
      svg {
        path {
          stroke: $white-0;
        }
      }
    }
  }
}
